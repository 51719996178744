import styled from 'styled-components';
import AdenTeAcompaña from '../../../../../assets/media/aden/aden-te-acompaña-white.png';
import FontAwesomeIcon from '../../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../../components/common/CommonButton';

const ModalFinal = (props) => {
    const { onClose, prevStep } = props;

    return (
        <ModalContainer>
            <CloseButton onClick={onClose}>
                <FontAwesomeIcon
                    icon="fa-light fa-xmark"
                    size={24}
                    color="#fff"
                />
            </CloseButton>
            <Mockup src={AdenTeAcompaña} alt="AdenTeAcompaña" />
            <Footer>
                <CommonButton
                    FontAwesomeIconName="fa-light fa-arrow-left"
                    variant="text"
                    onClick={prevStep}
                />
            </Footer>
        </ModalContainer>
    );
};

export default ModalFinal;

const ModalContainer = styled.div`
    position: relative;
    height: calc(80vh - 4rem);
    width: calc(120vh - 4rem);
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    border-radius: 50px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;

    &:focus-visible {
        outline: none;
    }
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 4rem;
    right: 4rem;
`;

const Mockup = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the element exactly */
    width: 90%;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    button {
        border-radius: 100px;
        color: #fff;
        background-color: transparent;
        padding: 12px;

        &:hover {
            outline: 1px solid #616161;
            color: #616161;
            background-color: #e5e5e5;
        }
    }
`;
