import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useTheme from '../../../hooks/useTheme';
import { validateAnnouncementDates } from '../../../helpers/newsletters.helper';
import AnnouncementCardNotification from './AnnouncementCardNotification';
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';
import ReloadIcon from '@mui/icons-material/Cached';
import Popover from '@mui/material/Popover';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';

const AnnouncementsNotification = (props) => {
    const { announcements, refreshAnnouncements, loading, error } = props;
    const { activeRol } = useSelector((state) => state.auth);

    // STATES
    const [anchorEl, setAnchorEl] = useState(null);
    const { primary } = useTheme();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // FUNCTIONS
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoTo = () => {
        window.location.href = '/anuncios';
    };

    const countNewAnnouncements = () => {
        let count = 0;
        if (announcements) {
            announcements?.forEach((newsletter) => {
                const isNew = validateAnnouncementDates(
                    newsletter?.card?.publishedDate,
                    newsletter?.card?.expirationDate,
                    activeRol
                );
                if (isNew) return count++;
            });
        }
        return count;
    };

    return (
        <Container>
            <IconButton
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                size="large"
            >
                <Icon
                    icon="foundation:megaphone"
                    width="1em"
                    height="1em"
                    style={{ color: '#bfbfbf' }}
                />
                {!loading && !error && (announcements?.length !== 0) &&
                    countNewAnnouncements() !== 0 && (
                        <Count color={primary}>{countNewAnnouncements()}</Count>
                    )}
            </IconButton>
            <PopoverFake
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <HeaderNotifications color={primary}>
                    <Title>Anuncios</Title>
                    <Buttons>
                        <ButtonReload onClick={refreshAnnouncements}>
                            <RefreshIcon color="white" />
                        </ButtonReload>
                        <Link onClick={handleClose} to="/anuncios">
                            <OpenNotifications />
                        </Link>
                    </Buttons>
                </HeaderNotifications>
                <BodyNotification>
                    {announcements === undefined ||
                    announcements?.length === 0 ||
                    loading ||
                    error ? (
                        /* Arreglar esto */
                        <NotFound>
                            <Icon
                                icon="heroicons-outline:refresh"
                                width="1.2em"
                                height="1.2em"
                            />
                            <h3>No encontramos anuncios</h3>
                            <button onClick={refreshAnnouncements}>
                                Actualizar
                            </button>
                        </NotFound>
                    ) : (
                        <>
                            {activeRol &&
                                announcements
                                    ?.sort(
                                        (a, b) =>
                                            new Date(b?.publishedDate) -
                                            new Date(a?.publishedDate)
                                    )
                                    .map((announcement, i) => {
                                        return (
                                            <AnnouncementCardNotification
                                                subjectName={
                                                    announcement?.subjectName
                                                }
                                                announcement={
                                                    announcement?.card
                                                }
                                                index={i}
                                                handleGoTo={handleGoTo}
                                                rol={activeRol}
                                                key={i}
                                            />
                                        );
                                    })}
                        </>
                    )}
                </BodyNotification>
            </PopoverFake>
        </Container>
    );
};

export default AnnouncementsNotification;

const PopoverFake = styled(Popover)`
    .MuiPaper-root {
        border-radius: 20px;
        background-color: #f9f9f9;
        @media (max-width: 768px) {
            width: 100vw;
        }
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0;
    button {
        svg {
            height: 2rem;
            width: 2rem;
            padding: 0;
        }
    }
`;

const Count = styled.h1`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1rem;
    height: 1rem;
    font-size: 0.7rem;
    color: white;
    background-color: ${(p) => p.color};
    position: absolute;
    border-radius: 500px;
    top: 0.3rem;
    right: 0.3rem;
`;

const HeaderNotifications = styled.div`
    height: 2.8rem;
    width: 20rem;
    background-color: ${(p) => p.color};
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: calc(100% - 24px);
    }
`;

const Title = styled.h6`
    color: white;
    font-size: 1.2rem;
`;

const OpenNotifications = styled(OpenInNewSharpIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
`;

const RefreshIcon = styled(ReloadIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
`;

const BodyNotification = styled.div`
    width: 100%;
    height: 25rem;
    overflow-y: auto;
    padding: 0.3rem 0.6rem;
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
`;

const NotFound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    svg {
        width: 32px;
        height: 32px;
        color: #a8a8a8;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #616161;
    }

    button {
        cursor: pointer;
        height: 40px;
        padding: 0 1rem;
        background-color: #b31d15;
        color: white;
        border-radius: 8px;

        :hover {
            background-color: #cd2118;
        }
    }
`;

const ButtonReload = styled.button`
    border: none;
    outline: none;
    padding: 0;
    background: none;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
