import { useEffect } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    interestsActions,
    motivationsActions,
} from '../../../../redux/actions';

// Components
import SimpleLoading from '../../../../components/common/SimpleLoading';
import ItemPicker from './interestsAndMotivations/ItemPicker';

const InterestsAndMotivations = () => {
    // const { user } = useContext(ProfileContext);

    const dispatch = useDispatch();
    const motivations = useSelector((state) => state.motivations.motivations);
    const interests = useSelector((state) => state.interests.interests);

    useEffect(() => {
        if (motivations === null) {
            dispatch(motivationsActions.getMotivationsRequest());
        }
        if (interests === null) {
            dispatch(interestsActions.getInterestsRequest());
        }
        // eslint-disable-next-line
    }, []);

    // RETURN
    if (!motivations || !interests) {
        return <SimpleLoading padding="8rem" />;
    }

    return (
        <Container>
            <h3>Intereses</h3>
            <ItemPicker
                items={interests}
                property={{ formatted: 'interests', notFormatted: 'interests' }}
            />
            <h3>Motivaciones</h3>
            <ItemPicker
                items={motivations}
                property={{
                    formatted: 'motivations',
                    notFormatted: 'motivations',
                }}
            />
        </Container>
    );
};

export default InterestsAndMotivations;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
        color: #000;
        font-size: 16px;
        font-weight: 700;
    }
`;
