import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import OptionWrapper from '../../ui/organisms/events/OptionWrapper';
import FontAwesomeIcon from '../../common/FontAwesomeIcon';
import { Text } from '../../common/Texts';

// Material UI
import { Button } from '@mui/material';

const AdenEvents = () => {
    // REDUX
    const history = useHistory();

    // STATE
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    return (
        <Container data-tut="reactour__proximos_eventos">
            <NextWrapper>
                <TitleWrapper>
                    <Text color="#b31d15" fontWeight="700" fontSize="16px">
                        Próximos eventos
                    </Text>
                    <AllEventsButton
                        color="primary"
                        onClick={() => history.push('/eventos')}
                    >
                        <FontAwesomeIcon
                            icon="fa-regular fa-calendar-day"
                            color="#B31D15"
                        />
                        <p>Ver todos</p>
                    </AllEventsButton>
                </TitleWrapper>
                <MenuWrapper>
                    <ButtonFake
                        onClick={() => changeTab(null, 0)}
                        active={currentTab === 0 ? true : false}
                        disabled={loading}
                    >
                        Online
                    </ButtonFake>
                    <ButtonFake
                        onClick={() => changeTab(null, 1)}
                        active={currentTab === 1 ? true : false}
                        disabled={loading}
                    >
                        Presenciales
                    </ButtonFake>
                </MenuWrapper>
                <OptionWrapper
                    currentTab={currentTab}
                    setLoading={setLoading}
                />
            </NextWrapper>
        </Container>
    );
};

export default AdenEvents;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: none;
    gap: 25px;
    height: 100%;
    width: 100%;

    @media (max-width: 1136px) {
        width: 300px;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        height: 765px;
        margin-top: 2.5rem;
    }
`;

const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;

    @media (max-width: 1260px) {
        padding: 0 1rem;

        p {
            font-size: 15px;
        }
    }
`;

const NextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    height: 100%;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow:
        0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
`;

const MenuWrapper = styled.div`
    display: grid;
    height: 50px;
    grid-template-columns: repeat(2, 1fr);
`;

const ButtonFake = styled(Button)`
    width: 100%;
    border-radius: 20px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    box-shadow:
        0px 4px 4px -2px #18274b14,
        0px 2px 4px -2px #18274b1f !important;
    background-color: #ffffff;
    color: #b31d15;
    cursor: pointer;
    transition: all 0.3s ease;
    ${(p) =>
        !p.active &&
        `
            color: #cbcbcb;
            box-shadow: none !important;
        `}

    :hover {
        background-color: #f1f1f1;
        box-shadow:
            0px 6px 12px -6px rgba(24, 39, 75, 0.12),
            0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    }
`;

const AllEventsButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    padding: 0 1rem;
    border-radius: 50px;
    background-color: #ffffff;

    &:hover {
        background-color: #f1f1f1;
    }

    p {
        color: #b31d15;
        margin-top: 4px;
    }

    svg {
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
    }

    @media (max-width: 1260px) {
        height: 30px;
        font-size: 12px;
        padding: 0 0.6rem;

        svg {
            width: 15px;
            height: 15px;
        }
    }
`;
