import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import MicrocontentCardSkeleton from '../../../components/common/MicrocontentCardSkeleton';
import EventCard from './EventCard';
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';
import SharedResource from '../../../components/common/SharedResource';
import SimpleLoading from '../../../components/common/SimpleLoading';
import CommonButton from '../../../components/common/CommonButton';
import TabButton from '../../../components/common/TabButton';
import EventsGrid from './EventsGrid';
import EmptyGrid from './EmptyGrid';

// Material UI
import { InputAdornment, TextField } from '@mui/material';

// Redux
import { getDynamicEvents } from '../../../redux/api/events/events';
import { useSelector } from 'react-redux';
/* import { eventsAdapter } from '../adapters/events.adapter'; */
import LazyImg from '../../../modules/acropolisCommon/components/LazyImg';

// Assets
import CalendarImg from '../../../assets/media/aden/calendar.png';

const OPTIONS = [
    { id: 0, label: 'Próximos' },
    { id: 1, label: 'Finalizados' },
];

const OnlineEvents = (props) => {
    const { type } = props;
    // REDUX
    const { partner_id } = useSelector((state) => state.auth.user);

    // STATE
    const [currentTab, setCurrentTab] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedLink, setSharedLink] = useState(null);
    const [sharedItem, setSharedItem] = useState(null);
    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [valuesFake, setValuesFake] = useState(events);

    const [nextEvents, setNextEvents] = useState(null);
    const [pastEvents, setPastEvents] = useState(null);

    const emptyItems = new Array(12).fill(null);

    // EFFECTS
    useEffect(() => {
        if (modalIsOpen === false) {
            setTimeout(() => {
                return <SimpleLoading />;
            }, 300);
        }
    }, [modalIsOpen]);

    useEffect(() => {
        if (nextEvents === null) {
            getDynamicEvent(type, 'all', 'next', partner_id);
            return;
        }
        if (pastEvents === null && !loading) {
            getDynamicEvent(type, 'all', 'past', partner_id);
            return;
        }
        if (currentTab === 0) {
            setEvents(nextEvents);
        }
        if (currentTab === 1) {
            setEvents(pastEvents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, nextEvents, pastEvents]);

    useEffect(() => {
        setValuesFake(events);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    // FUNCTIONS
    const getDynamicEvent = async (modality, limit, timelapse, partnerId) => {
        setLoading(true);

        const response = await getDynamicEvents(
            modality,
            limit,
            timelapse,
            partnerId
        );

        if (response.error) {
            setEvents([]);
            setLoading(false);
        } else {
            // Adaptar los datos
            /* const responseAdapted = eventsAdapter(response); */
            if (timelapse === 'next') {
                setNextEvents(response);
            } else {
                setPastEvents(response.reverse());
            }
            setLoading(false);
        }
    };

    function handleOpenModal(sharedLink, id, nombre, lp_type) {
        setSharedLink(sharedLink);
        setSharedItem({ lp_type, type: 'evento', id, nombre });
        setModalIsOpen(true);
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    function handleChange(e) {
        setSearch(e.target.value);
        if (currentTab === 0) {
            setEvents(nextEvents);
        } else {
            setEvents(pastEvents);
        }
        setValuesFake(
            events.filter((propsValue) => {
                let verify = propsValue.publication_name
                    ? propsValue.publication_name
                    : '';
                let verifyName = propsValue.name ? propsValue.name : '';
                let verifyTags = propsValue.tags ? propsValue.tags.join() : '';
                let verifyAuthor = propsValue.acropolis_author
                    ? propsValue.acropolis_author
                    : '';
                return (
                    verify
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    verifyTags
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    verifyName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    verifyAuthor
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                );
            })
        );
    }

    return (
        <Container>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedLink={sharedLink}
                sharedItem={sharedItem}
            />
            <Filters>
                <SearchAndOrder>
                    <SearchInput
                        value={search}
                        onChange={handleChange}
                        size="small"
                        label=""
                        placeholder="Buscar"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <FontAwesomeIcon
                                        icon="fa-light fa-magnifying-glass"
                                        size={24}
                                        color={'#616161'}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </SearchAndOrder>
                {/*  <CommonButton
                    variant="filled"
                    label="Filtros (2)"
                    onClick={() => console.log('Filtros')}
                /> */}
            </Filters>
            <div>
                {OPTIONS.map((option) => (
                    <TabButton
                        active={currentTab === option.id}
                        label={option.label}
                        onClick={() => setCurrentTab(option.id)}
                    />
                ))}
            </div>
            {(nextEvents === null && currentTab === 0) ||
            (pastEvents === null && currentTab === 1) ? (
                <GridMain>
                    <EmptyGrid
                        propsValues={emptyItems}
                        Card={MicrocontentCardSkeleton}
                    />
                </GridMain>
            ) : (
                <MainWrapper>
                    {events?.length === 0 ? (
                        <NotFoundWrapper>
                            <LazyImg
                                src={CalendarImg}
                                width={320}
                                height={230}
                                backgroundColor={'#fff'}
                            />
                            {currentTab === 0 && (
                                <h1>!Ups! Aún no hay eventos disponibles.</h1>
                            )}
                            {currentTab === 1 && (
                                <h1>!Ups! No encontramos eventos pasados.</h1>
                            )}
                        </NotFoundWrapper>
                    ) : (
                        <EventsGrid
                            propsValues={valuesFake}
                            type="event"
                            Card={EventCard}
                            handleOpenModal={handleOpenModal}
                        />
                    )}
                </MainWrapper>
            )}
        </Container>
    );
};

export default OnlineEvents;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
        0px 14px 64px -4px #18274b1f,
        0px 8px 22px -6px #18274b1f;
    padding: 24px;
`;

const Filters = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    .MuiSelect-select.MuiSelect-select {
        padding: 0 5rem 0.5rem 0;
        vertical-align: top;
    }
`;

const SearchAndOrder = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SearchInput = styled(TextField)`
    .MuiInputBase-root {
        min-width: 400px;
        max-height: 42px;
        margin: 0px;
        border-radius: 100px !important;
        gap: 10px;
        outline-color: '#a8a8a8';
        padding: 10px 10px 10px 10px;
        font-size: 16px;
    }
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const NotFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    text-align: center;
    padding: 0 2rem;
    background-color: #fafafa;
    gap: 40px;
    row-gap: 1rem;

    h1 {
        font-size: 1rem;
        color: #222;
        font-weight: 700;
    }
`;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
