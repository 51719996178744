import * as encode from 'nodejs-base64-encode';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';

// Components
import {
    LinearProgress,
    LinearProgressWrapper,
    Percentage,
    ProgressWrapper,
} from '../../../../styled-components/subjects/subjectsCards';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import UsersModal from '../../organisms/my-progress/UsersModal';
import LazyImg from '../../../../components/common/LazyImg';
import DialogBlockDoc from './DialogBlockDoc';
import ConfirmDialog from './ConfirmDialog';

// Material UI
import Stars from '@mui/icons-material/Stars';
import Button from '@mui/material/Button';

// Redux
import { coursesActions } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const CampusSubjectCard = (props) => {
    const { value, index, titulationState, slider } = props;

    const fecha_inicio = value.curso_fecha_inicio;
    const fecha_vencimiento = value.curso_fecha_fin;
    const userStatus = useSelector((state) => state.userStatus);
    const { push } = useHistory();

    const isBlock = !!userStatus?.blockedStatus?.blocked;

    // REDUX
    const dispatch = useDispatch();

    const baseURL = window.location.origin;
    const hashCourseId = encode.encode(value?.repo_course_id + '', 'base64');

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [user, setuser] = useState(null);
    const { canvas_login } = useSelector((state) => state.auth);

    function handleCloseModal() {
        setIsOpen(false);
    }

    function handleEnrollClick(curso_id, es_nivelatoria, repo_course_id) {
        let body = { curso_id, es_nivelatoria };
        body = !!repo_course_id ? { ...body, repo_course_id } : { ...body };

        dispatch(coursesActions.enrollCourseRequest(body));
    }

    const handleOpenConfirm = () => {
        !titulationState ? setOpen(true) : setOpenBlock(true);
    };

    const handleConfirm = (value, selectedCourse) => {
        setOpen(false);
        if (value) {
            handleEnrollClick(
                selectedCourse.curso_id,
                selectedCourse.es_nivelatoria,
                selectedCourse.repo_course_id
            );
        }
    };

    const handleOpenCourse = () => {
        if (value.repo_enrollment_id && value.repo_course_id) {
            // SE ABRE EN ACROPOLIS CON PUSH
            push(`../asignatura/${hashCourseId}?origin=plan`);
        }
    };

    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    // RETURN
    return (
        <>
            <CourseCardContainer slider={slider}>
                <ImgWrapper>
                    <LazyImg
                        src={`/assets/transient/courses/${index}.jpg`}
                        width="100%"
                        height="100%"
                        borderRadius="20px 20px 0 0"
                        alt="course"
                    />
                    <Estado estado={value.estado}>
                        {value.estado === 'Confirmado'
                            ? 'En curso'
                            : value.estado}
                    </Estado>
                    {value.es_nivelatoria && (
                        <StatusNivelation>
                            <FontAwesomeIcon
                                icon="fa-regular fa-chart-simple"
                                size={16}
                            />
                            Nivelatorio opcional
                        </StatusNivelation>
                    )}
                </ImgWrapper>
                <Body>
                    <ProgressWrapper>
                        <LinearProgressWrapper>
                            <LinearProgress porcentaje={value.progreso} />
                        </LinearProgressWrapper>
                        <Percentage>{value.progreso}%</Percentage>
                    </ProgressWrapper>
                    <Title>{value.curso}</Title>
                    {value.estado !== 'Preinscripto' && (
                        <DateGroup>
                            <>
                                {!!fecha_inicio && (
                                    <ItemWrapper>
                                        <FontAwesomeIcon
                                            icon="fa-light fa-calendar-check"
                                            size={20}
                                        />
                                        <DateWrapper>
                                            <TextSpan>Inicio</TextSpan>
                                            <DateSpan>
                                                <Moment format="DD/MM/YYYY">
                                                    {fecha_inicio}
                                                </Moment>
                                            </DateSpan>
                                        </DateWrapper>
                                    </ItemWrapper>
                                )}

                                {!!value.curso_fecha_fin && (
                                    <ItemWrapper>
                                        <FontAwesomeIcon
                                            icon="fa-light fa-calendar-xmark"
                                            size={20}
                                        />
                                        <DateWrapper>
                                            <TextSpan>Vencimiento</TextSpan>
                                            <DateSpan>
                                                <Moment format="DD/MM/YYYY">
                                                    {fecha_vencimiento}
                                                </Moment>
                                            </DateSpan>
                                        </DateWrapper>
                                    </ItemWrapper>
                                )}
                            </>
                        </DateGroup>
                    )}
                </Body>
                <Footer>
                    {value.estado === 'Confirmado' ? (
                        <BottomFooterWrapper>
                            {value.repo_enrollment_id &&
                            value.repo_course_id ? (
                                <FakeButton
                                    state={value.estado}
                                    variant="contained"
                                    onClick={handleOpenCourse}
                                    rel="noopener noreferrer"
                                    target={
                                        !!value.repo_enrollment_id
                                            ? '_self'
                                            : '_blank'
                                    }
                                    disabled={isBlock}
                                >
                                    {!!value.repo_enrollment_id
                                        ? 'Cursar'
                                        : 'Ir a cursar'}
                                </FakeButton>
                            ) : (
                                <FakeButton
                                    state={value.estado}
                                    variant="contained"
                                    href="https://aden.instructure.com/login/openid_connect"
                                    rel="noopener noreferrer"
                                    target={
                                        !!value.repo_enrollment_id
                                            ? '_self'
                                            : '_blank'
                                    }
                                    disabled={isBlock}
                                >
                                    {!!value.repo_enrollment_id
                                        ? 'Cursar'
                                        : 'Ir a cursar'}
                                </FakeButton>
                            )}
                        </BottomFooterWrapper>
                    ) : value.estado === 'Preinscripto' ? (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <NoteSpan>¡Confirma para iniciar!</NoteSpan>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                disabled={isBlock}
                                onClick={handleOpenConfirm}
                            >
                                Iniciar curso
                            </FakeButton>

                            {!titulationState ? (
                                <ConfirmDialog
                                    selectedCourse={value}
                                    open={open}
                                    onClose={handleConfirm}
                                />
                            ) : (
                                <DialogBlockDoc
                                    openBlock={openBlock}
                                    handleCloseBlock={handleCloseBlock}
                                />
                            )}
                        </BottomFooterWrapper>
                    ) : value.estado === 'Egresado' ? (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <Stars />
                                <NoteText>
                                    {value.nota % 1 === 0
                                        ? value.nota
                                        : value.nota.toFixed(2)}
                                </NoteText>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                href={
                                    !!value.repo_enrollment_id &&
                                    !!value.repo_course_id
                                        ? `${baseURL}/asignatura/${hashCourseId}?origin=plan`
                                        : canvas_login
                                          ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                                          : 'https://aden.instructure.com/login/openid_connect'
                                    // : "https://aden.instructure.com"
                                }
                                rel="noopener noreferrer"
                                target={
                                    !!value.repo_enrollment_id
                                        ? '_self'
                                        : '_blank'
                                }
                                disabled={isBlock}
                            >
                                Ver curso
                            </FakeButton>
                        </BottomFooterWrapper>
                    ) : (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <NoteSpan>Tu nota:</NoteSpan>
                                <NoteText>{value.nota}</NoteText>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                href={
                                    !!value.repo_enrollment_id &&
                                    !!value.repo_course_id
                                        ? `${baseURL}/asignatura/${hashCourseId}?origin=plan`
                                        : canvas_login
                                          ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                                          : 'https://aden.instructure.com/login/openid_connect'
                                }
                                rel="noopener noreferrer"
                                target={
                                    !!value.repo_enrollment_id
                                        ? '_self'
                                        : '_blank'
                                }
                                disabled={isBlock}
                            >
                                {!!value.repo_enrollment_id
                                    ? 'Cursar'
                                    : 'Ir a cursar'}
                            </FakeButton>
                        </BottomFooterWrapper>
                    )}
                </Footer>
            </CourseCardContainer>
            <UsersModal
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                title={user}
                id={value.curso_id}
            />
        </>
    );
};

export default CampusSubjectCard;

const CourseCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    width: ${({ slider }) => (slider ? '100%' : '320px')};
    height: 340px;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: var(--shadow-strong);
    border-radius: 20px;
    border: 1px solid #f2f2f2;

    @media screen and (max-width: 768px) {
        width: ${({ slider }) => (slider ? '100%' : '300px')};
    }

    @media screen and (max-width: 320px) {
        width: ${({ slider }) => (slider ? '100%' : '250px')};
    }
`;

const Body = styled.div`
    height: 129px;
    background-color: white;
    position: relative;
    :before {
        content: '';
        width: 50px;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        box-shadow: -30px 0 0px 0 white;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    height: 50%;
`;

const ImgWrapper = styled.div`
    width: 100%;
    height: 136px;
    position: relative;
`;

const Estado = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: ${(props) =>
        props.estado === 'Confirmado'
            ? '#5e80db'
            : props.estado === 'Preinscripto'
              ? '#C29F43'
              : props.estado === 'Egresado'
                ? '#1E8065'
                : ''};
    border: 1px solid
        ${(props) =>
            props.estado === 'Confirmado'
                ? '#5e80db'
                : props.estado === 'Preinscripto'
                  ? '#C29F43'
                  : props.estado === 'Egresado'
                    ? '#1E8065'
                    : ''};
    border-radius: 100px;
    padding: 8px 1rem;
    width: fit-content;
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0 auto;
    background-color: #fff;
`;

const StatusNivelation = styled.div`
    font-size: 12px;
    font-weight: 600;
    background-color: #e5e5e5;
    color: #616161;
    border: 1px solid #616161;
    border-radius: 100px;
    padding: 8px 1rem;
    width: fit-content;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    column-gap: 0.5rem;
    color: #616161;
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-top: 1rem;
    color: #616161;
`;

const DateGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    color: #616161;

    svg {
        font-size: 0.9rem;
    }
`;

const TextSpan = styled.span`
    font-size: 13px;
    font-weight: 500;
`;

const DateSpan = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Title = styled.h1`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1rem 1.5rem;
    margin-top: auto;
    border-top: 1px solid #e8e8e8;
`;

const BottomFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const NoteWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #a3a3a3;
`;

const NoteSpan = styled.span`
    font-size: 0.75rem;
`;

const NoteText = styled.span`
    font-size: 1.2rem;
    color: #222222;
    font-weight: bold;
`;

const FakeButton = styled(Button)`
    background-color: #cd2118;
    color: #ffffff;

    :hover {
        background-color: #b31d15;
    }
`;
