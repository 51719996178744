import { useState } from 'react';

const useSubjects = (data, statusStudentBlock) => {
    const [enrollmentDates, setEnrollmentDates] = useState({});

    const handleEnrollmentDates = (data) => {
        const { start_date, expected_end_date, end_date } = data || {};

        let startDate = !start_date
            ? false
            : new Date(start_date + 'T00:00:00');

        let expectedDate = !expected_end_date
            ? false
            : new Date(expected_end_date + 'T00:00:00');
        if (expectedDate !== false) {
            expectedDate.setHours(23);
            expectedDate.setMinutes(59);
            expectedDate.setSeconds(59);
        }

        let expiringDate = !end_date ? false : new Date(end_date + 'T00:00:00');
        if (expiringDate !== false) {
            expiringDate.setHours(23);
            expiringDate.setMinutes(59);
            expiringDate.setSeconds(59);
        }

        setEnrollmentDates({
            expiring: expiringDate,
            expected: expectedDate,
            start: startDate,
        });
    };

    const progress = !data?.elective_subject
        ? data?.progress > 100
            ? 100
            : Math.trunc(data?.progress)
        : null;

    const validateStates = () => {
        const isExpired = !enrollmentDates.expiring
            ? false
            : enrollmentDates.expiring <= new Date(Date.now());
        const toExpire = !enrollmentDates.expected
            ? false
            : enrollmentDates.expected <= new Date(Date.now());
        const state = data?.state;

        //Sin matricular no existen fechas a contemplar y no existe progreso
        if (state === false || state === 'pendiente') return 'Sin matricular';
        //Estado egresado por sobre el resto de las condiciones, no contempla progreso ni actividades por rehacer
        if (state === 'egresado') return 'Egresado';
        
        //Asignatura de inicio Finalizada: progreso 100, nota final 0, sin actividades pendientes
        if (
            progress === 100 &&
            !(data?.rework_activities || data?.pending_correction) &&
            (state === 'confirmado' || state === 'baja') &&
            data?.nota_final === 0
        )
            return 'Finalizado';
        if (state === 'baja') return 'Bloqueado';
        if (state === 'en_pausa' || state === 'preinscripto') return 'En pausa';
        if (isExpired) return 'Vencido';
        if (toExpire) return 'Próximo a vencer';
        if (state === 'confirmado' && progress > 0) return 'En curso';
        if (state === 'confirmado' && progress === 0) return 'Por iniciar';

        return;
    };

    const subjectCondition = validateStates(data, progress, enrollmentDates);

    const imgGrey =
        subjectCondition === 'Bloqueado' ||
        subjectCondition === 'Vencido' ||
        subjectCondition === 'En pausa';

    const PopulateNotificationChip = (
        level,
        rework_activities,
        pending_correction
    ) => {
        const result = {};

        if (level.length !== 0) {
            result.level = true;
        }
        if (rework_activities !== false) {
            result.rework_activities = true;
        }
        if (pending_correction !== false) {
            result.pending_correction = true;
        }

        return Object.keys(result).filter((key) => result[key]);
    };

    return {
        handleEnrollmentDates,
        progress,
        subjectCondition,
        imgGrey,
        PopulateNotificationChip,
        enrollmentDates,
    };
};

export default useSubjects;
