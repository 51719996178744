export const subprogramPlusAdapter = (subprogramPlus) => {
    return {
        amountSubprograms: subprogramPlus.amount_subprograms,
        nameMaster: subprogramPlus.name_master,
        progress: subprogramPlus.progress,
        subprograms: subprogramPlus.subprograms.map((subprogram) =>
            subprogramAdapter(subprogram)
        ),
    };
};

export const subprogramAdapter = (subprogram) => {
    return {
        id: subprogram.id,
        name: subprogram.name,
        enrolled: subprogram.enrolled,
        progress: subprogram.progress,
        initialsStudyPlan: subprogram.initials_study_plan,
        correlativeSubprograms: subprogram.correlative_subprograms.map(
            (correlative) => ({
                id: correlative.id,
                name: correlative.name,
                completed: correlative.completed,
            })
        ),
    };
};
