export const studentAdapter = (student) => {

    return {
        biography: student.biography,
        city: student.city,
        company: student.company,
        country: {
            id: student.country[0],
            name: student.country[1],
        },
        currentJob: {
            id: student.current_job[0],
            name: student.current_job[1],
        },
        dataIDP: student.data_idp ? {
            activitiesPresented: student.data_idp.activities_presented,
            courseSpeed: student.data_idp.course_speed,
            displayResources: student.data_idp.display_resources,
            IDPValue: student.data_idp.idp_value,
            inactiveDays: student.data_idp.inactive_days,
            performance: student.data_idp.performance,
            top10: student.data_idp.top_10,
        } : false,
        faId: student.fa_id,
        facebook: student.facebook,
        photo: student.foto,
        instagram: student.instagram,
        isStudent: student.is_student,
        job: {
            id: student.job[0],
            name: student.job[1],
        },
        linkedin: student.linkedin,
        mobile: student.mobile,
        name: student.name,
        nationality: student.nationality,
        partnerId: student.partner_id,
        personalEmail: student.personal_email,
        phone: student.phone,
        profession: {
            id: student.profession[0],
            name: student.profession[1],
        },
        profileIsPublic: student.profile_is_public,
        progress: student.progress,
        sisId: student.sis_id,
        skype: student.skype,
        twitter: student.twitter,
        whatsapp: student.whatsapp,
    };
};
