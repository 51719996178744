export const userProfileAdapter = (user) => {
    return {
        name: user.name,
        photo: user.foto,
        biography: user.biography,
        fusionAuthID: user.fa_id,
        city: user.city,
        phoneNumber: user.phone,
        workPhoneNumber: user.work_phone,
        email: user.personal_email,
        profileIsPublic: user.profile_is_public,
        direction:
            user.street +
            ' CP ' +
            user.zip +
            ' ' +
            user.city +
            ', ' +
            user.country.name,
        birthdayDate: user.birthday,
        profession: user.profession.name,
        professionObject: user.profession,
        currentJob: user.current_job.name,
        currentJobObject: user.current_job,
        company: user.company,
        linkedIn: user.linkedin,
        facebook: user.facebook,
        instagram: user.instagram,
        x: user.twitter,
        academicTraining: user.academic_training,
        workExperience: user.work_experience,
        motivations: user.motivaciones,
        interests: user.intereses,
        documentType: user.document_type,
        documentNumber: user.document_number,
        country: user.country.name,
        countryObject: user.country,
        zip: user.zip,
        street: user.street,
        gender: user.gender,
        partnerId: user.partner_id,
        repoId: user.repo_id,
        sisId: user.sis_id,
    };
};
