import styled from "styled-components";

const TagFilter = (props) => {

    const { tag, setTag } = props;

    const handleTag = (id) => {
        setTag(id);
    };

    return (
        <Container>
            <TagChip onClick={() => handleTag("todos")} active={tag === "todos"}>
                TODOS
            </TagChip>
            <TagChip onClick={() => handleTag("bajo")} active={tag === "bajo"}>
                IDP BAJO
            </TagChip>
            <TagChip onClick={() => handleTag("medio-bajo")} active={tag === "medio-bajo"}>
                IDP MEDIO - BAJO
            </TagChip>
            <TagChip onClick={() => handleTag("medio-alto")} active={tag === "medio-alto"}>
                IDP MEDIO - ALTO
            </TagChip>
            <TagChip onClick={() => handleTag("alto")} active={tag === "alto"}>
                IDP ALTO
            </TagChip>
        </Container>
    );
};

export default TagFilter;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    justify-content: flex-start;
`;

const TagChip = styled.div`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 14px;
    border-radius: 5px;
    background-color: ${({ active }) => (active ? "#fff" : "transparent")};
    color: ${({ active }) => (active ? "#b31d15" : "#949494")};
    font-weight: bold;
    cursor: pointer;

    ${(p) =>
        p.active
            ? `box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15), 0px 5px 10px 0px rgba(0, 0, 0, 0.06);`
            : null}
`;
