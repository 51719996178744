import { Box, Modal } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import CardStudentIdp from './CardStudentIdp';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    overflow: 'hidden',
};

const ModalWeekIdp = (props) => {
    const { handleWeekModal, openWeekModal, studentsIDP } = props;

    const COLUMNS = [
        'Alumno',
        'Estado IDP',
        'Actividad en plataforma',
        'Visualización del material',
        'Cumplimiento de entregas',
        'Velocidad del cursado',
        'Puntos obtenidos',
    ];

    return (
        <Modal
            open={openWeekModal}
            onClose={handleWeekModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Header>
                    <TextWrapper>
                        <h3>Índice de participación semanal</h3>
                        <p>
                            Asignatura: Digital Transformation, Digital Strategy
                        </p>
                    </TextWrapper>
                    <CloseButton onClick={handleWeekModal}>
                        <CloseIcon />
                    </CloseButton>
                </Header>
                <Body>
                    <Thead>
                        {COLUMNS.map((column) => (
                            <Column key={column}>{column}</Column>
                        ))}
                    </Thead>
                    <Tbody>
                        {/* DESPUÉS MAPEAR ESTO */}
                        {
                            studentsIDP.map((student) => (
                                <CardStudentIdp key={student.id} student={student} />
                            ))
                        }
                    </Tbody>
                </Body>
            </Box>
        </Modal>
    );
};

export default ModalWeekIdp;

const Header = styled.div`
    width: 100%;
    background-color: #b31d15;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #fff;
    text-align: center;
`;
const CloseButton = styled.div`
    position: absolute;
    right: 4rem;
    cursor: pointer;

    svg {
        color: #fff;
        font-size: 1.4rem;
    }
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Thead = styled.div`
    display: grid;
    grid-template-columns: 18% 18% 14% 12.5% 12.5% 12.5% 12.5%;

    div:last-child {
        font-weight: bold;
    }
`;

const Column = styled.div`
    max-width: 100%;
    color: #616161;
    text-align: center;
    font-size: 1rem;
`;

const Tbody = styled.div`
    height: 70%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    padding-bottom: 1rem;
`;
