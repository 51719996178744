import { Modal } from '@mui/material';
import styled from 'styled-components';
import CommonButton from '../../../../components/common/CommonButton';
import LazyImg from '../../../../modules/acropolisCommon/components/LazyImg';
import HighFiveImage from '../../../../assets/media/aden/high-five.png';

const EventSuccessModal = (props) => {
    const { handleModal, openModal } = props;

    return (
        <ModalFake
            onClose={() => {
                handleModal();
            }}
            open={openModal}
        >
            <Container>
                <LazyImg src={HighFiveImage} width="362" height="322" />
                <h2>
                    ¡Felicitaciones! Tu inscripción ha <br /> sido realizada con
                    éxito.
                </h2>
                <CommonButton
                    label="Entendido"
                    onClick={handleModal}
                    variant="filled"
                />
            </Container>
        </ModalFake>
    );
};

export default EventSuccessModal;

const ModalFake = styled(Modal)`
    /* position: relative; */
    background-color: transparent;
`;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
    border-radius: 20px;
    width: 460px;
    height: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: hidden;

    h2 {
        color: #b31d15;
        font-size: 20px;
        text-align: center;
    }

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: unset;
        border-radius: 0;
        top: 0;
        left: 0;
        padding: 0rem;
        right: 0;
        bottom: 0;
        transform: none;
    }
`;
