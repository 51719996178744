import React, {
    useEffect,
    useRef,
    useReducer,
    useContext,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import VimeoPlayer from '../../../../modules/acropolisCommon/components/VimeoPlayer';
import YouTube from 'react-youtube';
import { Icon } from '@iconify/react';

// Components
import SimpleLoading from '../../../../modules/acropolisCommon/components/SimpleLoading';
import Presentation from './presentation/Presentation';
import NavegationBottom from './NavegationBottom';
import MeetingComponent from './MeetingComponent';
import FooterResource from './FooterResource';
import RiseScorm from './resources/RiseScorm';
import MultipleChoice from './MultipleChoice';

// Hooks
import { useVideo } from '../../hooks/useVideo';

// Redux
import {
    getDataEvaluationService,
    viewResource,
} from '../../../../redux/api/courses';
import CourseContext from '../../context/CourseSubjectContext';

// Helpers
import {
    concatUrl,
    formatUrlFrame,
} from '../../../../helpers/pdfGoogleFormated.helper';
import { userAction } from '../../../../helpers/userActions.helper';

// Assets
import { ReactComponent as NotFoundSvg } from '../../../../assets/media/svg/404-Error-pana.svg';
import AdenBot from '../../../../assets/media/aden/AdenBot.png';
import ModalIA from './IA/ModalIA';
import SharedResource from '../../../../components/common/SharedResource';

const MainCourse = (props) => {
    const {
        currentMedia: {
            id,
            nombre_publicacion,
            formato,
            link_video,
            link_storage,
            id_recurso,
            id_evaluacion,
            orden,
            url_padlet,
            subtipo,
            direct_link_vimeo_720,
            direct_link_vimeo_1080,
            meeting_vencido,
            fecha_meeting,
            link_meeting,
            link_calendar,
            link_interactivo,
            descripcion_html,
            youtube_id,
            visto = false,
            vimeo_transcript,
            enable_chat_ai,
            permitir_compartir = false,
        },
        nombre,
        notesContent,
        currentPosition,
        menuItems,
        nextItem,
        backItem,
        blockResourceChange,
        blockResocurce,
        objId,
        handleCheckResource,
        handleNext,
        nextResource,
        setChangeStatus,
        viewDelivery,
        handleCurrentSecond,
        handleIsChangeMinute,
        isChangeMinute,
        currentMinute = 0,
        showAside,
        setShowAside,
        setTabClose,
        statusStudentBlock,
        reasonBlock,
        promptAI,
    } = props;

    const { valuesNotes } = notesContent;

    const CASES_TO_EXCLUDE = ['NetSelfie', 'NetChat'];

    const { subjectId } = useContext(CourseContext);

    const { activeRol } = useSelector((state) => state.auth);

    // STATE
    const [state, dispatchState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            isOpen: false,
            tutorIsOpen: false,
            evaluation: null,
            presentation: null,
            displayCard: true,
            isViewVideo: false,
        }
    );

    const [openModalIA, setOpenModalIA] = useState(false);
    const [sharedModalIsOpen, setSharedModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    // Reproductor de video
    const playerRef = useRef(null);
    const watchedHalf = useRef(false);

    // Video
    const { nextVideo, setNextVideo, setStopVideo } = useVideo();

    // Tema

    // REDUX
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatchState({
            evaluation: null,
            presentation: null,
            isViewVideo: false,
        });

        setNextVideo(false);
        setStopVideo(false);
        // Actividad
        userAction({
            object_id: id + '',
            object: nombre_publicacion,
            name: nombre_publicacion,
            activity: 'Visualizar recurso',
            medio: 'Ver recurso',
        });

        if (formato === 'audiovisual') blockDownloadVideo();
        //eslint-disable-next-line
    }, [nombre_publicacion, id, orden]);

    useEffect(() => {
        if (state?.evaluation === null && formato === 'evaluacion') {
            getDataEvaluation({
                id: id_evaluacion,
                user: user.repo_id,
                obj: objId,
                reso: id_recurso,
                assignmentId: subjectId,
            });
        }
        //eslint-disable-next-line
    }, [formato, id_evaluacion, state?.evaluation]);

    useEffect(() => {
        if (state?.presentation === null && formato === 'presentacion') {
            getDataPresentation({
                id: id_evaluacion,
                user: user.repo_id,
                obj: objId,
                reso: id_recurso,
                assignmentId: subjectId,
            });
        }
        //eslint-disable-next-line
    }, [formato, id_evaluacion, state?.presentation]);

    useEffect(() => {
        if (
            state?.isViewVideo ||
            formato === 'material_plano' ||
            subtipo === 'Youtube'
        ) {
            formato !== 'presentacion' &&
                formato !== 'evaluacion' &&
                submitViewResource();
        }
        //eslint-disable-next-line
    }, [state?.isViewVideo, formato]);

    // YOUTUBE
    const opts = {
        height: '630px',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    // FUNCTIONS

    // PARA YOUTUBE
    const onReady = (event) => {
        playerRef.current = event.target;
    };

    const onStateChange = () => {
        const player = playerRef.current;
        const duration = player.getDuration();
        const currentTime = player.getCurrentTime();

        if (!watchedHalf.current && currentTime >= duration / 2) {
            watchedHalf.current = true;
        }
    };

    // OTRAS FUNCIONES
    function handleOpenTutorModal() {
        dispatchState({ tutorIsOpen: true });
    }

    const getDataEvaluation = async (data) => {
        const response = await getDataEvaluationService(data);
        if (!response) {
            dispatchState({ evaluation: false });
        } else {
            dispatchState({ evaluation: { ...response, tiempo: false } });
        }
    };

    const getDataPresentation = async (data) => {
        const response = await getDataEvaluationService(data);
        if (!response) {
            dispatchState({ presentation: false });
        } else {
            dispatchState({ presentation: { ...response, tiempo: false } });
        }
    };

    const handleNewTry = () => {
        dispatchState({ evaluation: null });

        dispatchState({ presentation: null });
    };

    const blockDownloadVideo = () => {
        const player = document.getElementById('video-react');
        // player.bind('contextmenu', () => false);
        if (player !== null) player.oncontextmenu = () => false;
    };

    const onFinish = () => {
        console.log('finish');
    };

    const videoResolution = [
        {
            src: direct_link_vimeo_720,
            type: 'video/mp4',
            label: '720p',
            res: 720,
        },
        {
            src: direct_link_vimeo_1080,
            type: 'video/mp4',
            label: '1080p',
            res: 1080,
        },
    ];

    // Renderizado de material
    const renderTypeResources = () => {
        if (formato === 'evaluacion') {
            return (
                <EvaluationWrapper>
                    {state?.evaluation === null ? (
                        <SimpleLoading />
                    ) : state?.evaluation === false ? (
                        <MessageErrorRequest>
                            <NotFoundSvg width="50%" />
                            No se pudo cargar la evaluación
                        </MessageErrorRequest>
                    ) : (
                        <>
                            <MultipleChoice
                                submitViewResource={submitViewResource}
                                handleNewTry={handleNewTry}
                                statusStudentBlock={statusStudentBlock}
                                reasonBlock={reasonBlock}
                                resourceId={id_recurso}
                                objId={objId}
                                idEvaluacion={id_evaluacion}
                                blockResocurce={blockResocurce}
                                blockResourceChange={blockResourceChange}
                                evaluationData={state?.evaluation}
                                publicationName={nombre_publicacion}
                            />
                        </>
                    )}
                </EvaluationWrapper>
            );
        } else if (formato === 'audiovisual' && subtipo === 'Meeting') {
            return (
                <MeetingComponent
                    calendar={link_calendar}
                    title={nombre_publicacion}
                    linkMeeting={link_meeting}
                    dateMeet={fecha_meeting}
                    defeatedMeet={meeting_vencido}
                />
            );
        } else if (formato === 'audiovisual' && subtipo === 'h5p') {
            return (
                <IframeWrapper>
                    <Iframe
                        onLoad={handleOnLoadMaterial}
                        src={link_interactivo}
                        frameBorder="0"
                        allowFullScreen
                    />
                </IframeWrapper>
            );
        } else if (formato === 'audiovisual') {
            if (
                (videoResolution[0].src !== '' ||
                    videoResolution[1].src !== '') &&
                subtipo !== 'Youtube'
            ) {
                return (
                    <>
                        <VideoWrapper>
                            <HeaderRender bg="#f1f1f1">
                                {nombre_publicacion}
                                {activeRol === 'ondemand' && (
                                    <ShowAsideButton
                                        onClick={() => {
                                            setShowAside(!showAside);
                                            setTabClose(4);
                                        }}
                                    >
                                        {showAside ? (
                                            <Icon
                                                icon="fluent:arrow-export-20-filled"
                                                width="36px"
                                                height="36px"
                                                style={{
                                                    color: 'var(--primary-one)',
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                icon="fluent:arrow-export-20-filled"
                                                width="36px"
                                                height="36px"
                                                style={{
                                                    color: 'var(--primary-one)',
                                                    transform: 'rotate(180deg)',
                                                }}
                                            />
                                        )}
                                    </ShowAsideButton>
                                )}
                            </HeaderRender>
                            <VimeoPlayer
                                videoLink={link_video}
                                valuesNotes={valuesNotes}
                                nextResource={nextResource}
                                onProgress={handleFinishVideo}
                                handleCurrentSecond={handleCurrentSecond}
                                onFinish={onFinish}
                                handleNext={handleNext}
                                nextVideo={nextVideo}
                                CASES_TO_EXCLUDE={CASES_TO_EXCLUDE}
                                dispatchState={dispatchState}
                                isChangeMinute={isChangeMinute}
                                currentMinute={currentMinute}
                                handleIsChangeMinute={handleIsChangeMinute}
                            />
                        </VideoWrapper>
                        <InfoContainer>
                            <Icon
                                icon="octicon:info-24"
                                width="24px"
                                height="24px"
                            />
                            <label>
                                Ten en cuenta que la plataforma registrará este
                                video como visto,{' '}
                                <b>
                                    una vez que lo hayas visualizado por
                                    completo.
                                </b>
                            </label>
                        </InfoContainer>
                    </>
                );
            } else if (subtipo === 'Youtube') {
                return (
                    <YouTube
                        videoId={youtube_id}
                        onReady={onReady}
                        opts={opts}
                        onStateChange={onStateChange}
                    />
                );
            } else {
                return (
                    <MessageErrorRequest>
                        <NotFoundSvg width="50%" />
                        No se puede visualizar el video
                    </MessageErrorRequest>
                );
            }
        } else if (
            formato === 'material_plano' &&
            subtipo === 'Gamification-tools' &&
            link_storage.toLowerCase().includes('repo_id_aden')
        ) {
            return (
                <IframeWrapper>
                    {link_storage !== '' ? (
                        <Iframe
                            onLoad={handleOnLoadMaterial}
                            src={link_storage.replace(
                                'repo_id_aden',
                                `repo_id=${user.repo_id}`
                            )}
                            frameBorder="0"
                            allowFullScreen
                        />
                    ) : (
                        <MessageErrorRequest>
                            <NotFoundSvg width="300px" />
                            No se puede visualizar el material
                        </MessageErrorRequest>
                    )}
                </IframeWrapper>
            );
        } else if (formato === 'material_plano') {
            if (subtipo === 'Rise - Scorm con retorno') {
                return (
                    <RiseScorm
                        title={nombre_publicacion}
                        onLoad={handleOnLoadMaterial}
                        url={
                            formatUrlFrame(link_storage) +
                            `${concatUrl(link_storage)}id=${
                                user.repo_id
                            }&object=${objId}&resource=${id}`
                        }
                    />
                );
            } else if (subtipo === 'Rise - Scorm sin retorno') {
                return (
                    <RiseScorm
                        title={nombre_publicacion}
                        onLoad={handleOnLoadMaterial}
                        url={
                            link_storage +
                            `${concatUrl(link_storage)}id=${
                                user.repo_id
                            }&object=${objId}&resource=${id}`
                        }
                    />
                );
            }
            return (
                <IframeWrapper>
                    {link_storage !== '' ? (
                        <Iframe
                            onLoad={handleOnLoadMaterial}
                            src={
                                subtipo !== 'h5p'
                                    ? formatUrlFrame(link_storage) +
                                      `${concatUrl(link_storage)}id=${
                                          user.repo_id
                                      }&object=${objId}&resource=${id}`
                                    : link_storage
                            }
                            frameBorder="0"
                            allowFullScreen
                        />
                    ) : descripcion_html !== '' ? (
                        <HtmlContainer
                            onLoad={handleOnLoadMaterial}
                            dangerouslySetInnerHTML={{
                                __html: descripcion_html,
                            }}
                        />
                    ) : (
                        <MessageErrorRequest>
                            <NotFoundSvg width="300px" />
                            No se puede visualizar el material
                        </MessageErrorRequest>
                    )}
                </IframeWrapper>
            );
        } else if (formato === 'presentacion') {
            return (
                <EvaluationWrapper>
                    {state?.presentation === null ? (
                        <SimpleLoading />
                    ) : state?.presentation === false ? (
                        <MessageErrorRequest>
                            <NotFoundSvg width="50%" />
                            No se pudo cargar la evaluación
                        </MessageErrorRequest>
                    ) : (
                        <Presentation
                            submitViewResource={submitViewResource}
                            handleNewTry={handleNewTry}
                            statusStudentBlock={statusStudentBlock}
                            reasonBlock={reasonBlock}
                            presentation={state?.presentation}
                            resourceId={id_recurso}
                            nombre_publicacion={nombre_publicacion}
                            objId={objId}
                            setChangeStatus={setChangeStatus}
                            viewDelivery={viewDelivery}
                        />
                    )}
                </EvaluationWrapper>
            );
        } else {
            return <h4>Tipo de recurso desconocido</h4>;
        }
    };

    // Manejar avance del alumno
    const handleOnLoadMaterial = () => {
        submitViewResource();
    };

    const handleFinishVideo = (currentSecond, totalTime) => {
        let progress = 0;
        if (totalTime > 0) {
            progress = Math.round((currentSecond * 100) / totalTime);
            // Marcar actividad como vista
            if (progress > 50) {
                dispatchState({ isViewVideo: true });
            }
            // Levantar cartel de proximo recurso
            if (Math.round(totalTime) - 10 - Math.round(currentSecond) <= 0) {
                setNextVideo(true);
            }
        }
    };

    // Parar modal de video

    const submitViewResource = async () => {
        const request = await viewResource({
            user: user.repo_id,
            resource: id || id_recurso,
            obj: objId,
        });
        if (request.error) return;
        handleCheckResource(nombre_publicacion);
    };

    //Modal para compartir video
    const shareableResource = permitir_compartir;

    function handleOpenSharedModal() {
        setSharedModalIsOpen(true);
        setSharedItem({
            lp_type: 'microcontent',
            type: 'recurso',
            id,
            nombre_publicacion,
        });
    }

    function handleCloseSharedModal() {
        setSharedModalIsOpen(false);
    }

    /** RETURN ------------------------------------------------ */
    return (
        <>
            <MainCourseContainer>
                <ModalIA
                    open={openModalIA}
                    handleClose={() => setOpenModalIA(false)}
                    vimeoTranscript={vimeo_transcript}
                    visto={visto}
                    publicationName={nombre_publicacion}
                    resourceId={id}
                    partnerId={user?.repo_id}
                    getNotes={notesContent.getNotes}
                    promptAI={promptAI}
                    OAName={nombre}
                />
                <ContentWrapper format={formato}>
                    {/* Renderiza tipos de recursos */}
                    <NavegationWrapper>
                        {renderTypeResources()}
                        {formato === 'material_plano' && (
                            <InfoContainer formato={formato}>
                                <Icon
                                    icon="octicon:info-24"
                                    width="24px"
                                    height="24px"
                                />
                                {subtipo === 'Página Web' && (
                                    <label>
                                        Ten en cuenta que la plataforma
                                        registrará esta herramienta de
                                        gamificación como completa,{' '}
                                        <b>una vez que la hayas completado.</b>
                                    </label>
                                )}
                                {subtipo === 'PDF' && (
                                    <label>
                                        Ten en cuenta que la plataforma
                                        registrará esta lectura como vista,{' '}
                                        <b>una vez que la hayas completado.</b>
                                    </label>
                                )}
                            </InfoContainer>
                        )}
                        {formato === 'audiovisual' &&
                            subtipo === 'Vimeo' &&
                            vimeo_transcript &&
                            enable_chat_ai && (
                                <ButtonIA
                                    disabled={!visto}
                                    onClick={() => setOpenModalIA(true)}
                                >
                                    <ToastIA>
                                        Esta herramienta de IA se habilitará{' '}
                                        <br />
                                        cuando finalices el video
                                    </ToastIA>
                                    <img src={AdenBot} alt="AdenBot" />
                                </ButtonIA>
                            )}
                        <NavegationBottom
                            format={formato}
                            currentPosition={currentPosition}
                            nextItem={nextItem}
                            backItem={backItem}
                            visto={visto}
                            menuItems={menuItems}
                            handleOpenTutorModal={handleOpenTutorModal}
                            idCurrentMedia={
                                formato === 'evaluacion' ||
                                formato === 'presentacion'
                                    ? id_recurso
                                    : id
                            }
                            blockResocurce={blockResocurce}
                            shareableResource={shareableResource}
                            handleOpenSharedModal={handleOpenSharedModal}
                            handleCloseSharedModal={handleCloseSharedModal}
                            resourceName={nombre_publicacion}
                        />
                    </NavegationWrapper>
                    {activeRol === 'ondemand' ? (
                        url_padlet && <FooterResource padlet={url_padlet} />
                    ) : (
                        <FooterResource padlet={url_padlet} />
                    )}
                </ContentWrapper>
            </MainCourseContainer>
            <SharedResource
                modalIsOpen={sharedModalIsOpen}
                handleCloseModal={handleCloseSharedModal}
                sharedItem={sharedItem}
            />
        </>
    );
};

export default MainCourse;

const MainCourseContainer = styled.div`
    height: 100%;

    @media (width < 1150px) {
        max-width: 95vw;
    }
`;

const ContentWrapper = styled.div`
    padding: 2rem 2.5rem 0 2.5rem;
    margin-bottom: 4rem;
    display: grid;
    gap: 15px;

    @media (width < 1150px) {
        max-width: 95vw;
    }

    @media (max-width: 768px) {
        padding: 1.5rem;
    }
`;

const NavegationWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 6px;
    gap: 1rem;

    @media (width < 1400px) {
        max-width: 100%;
    }
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 0;
    margin-bottom: 1rem;
`;

const EvaluationWrapper = styled.div`
    max-width: 65vw;
    // Arreglar para el responsive
    position: relative;
    margin-bottom: 15px;

    @media (width < 1400px) {
        max-width: 60vw;
    }

    @media (width < 1150px) {
        max-width: 100%;
    }
`;

const IframeWrapper = styled.div`
    max-width: 60vw;
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25;
    height: 0;
`;

const HtmlContainer = styled.div`
    /* @import url("https://cdns-varios.pages.dev/styles/common-04107f0c72.css");
    @import url("https://cdns-varios.pages.dev/styles/new_user_tutorials-2950c871dd.css");
    @import url("https://cdns-varios.pages.dev/styles/adenuniversity-and-bootstrap-ns-2022.css"); */
    /* width: fit-content; */
    /* scale: 0.99; */
    /* height: fit-content !important; */
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const MessageErrorRequest = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #b31d15;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;
`;

const HeaderRender = styled.div`
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 40px;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => p.bg};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: ${(p) => (p.textColor ? p.textColor : '#000')};
`;

const ShowAsideButton = styled.div`
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 10px;
    border-radius: 40px;
    background-color: var(--secondary-two);
    :hover {
        background-color: #e5e5e5;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    margin-top: ${(p) => (p.formato === 'material_plano' ? '1rem' : '0')};

    svg {
        color: #c29f43;
    }
`;

const ToastIA = styled.span`
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 50%; /* Centra el triángulo horizontalmente */
    transform: translateX(-50%); /* Ajuste para centrar */
    bottom: 4rem;
    padding: 8px;
    background-color: #222;
    border-radius: 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    color: #fff;
    text-align: start;
    z-index: 10;
    white-space: nowrap;

    ::after {
        content: '';
        position: absolute;
        left: 50%; /* Centra el triángulo horizontalmente */
        transform: translateX(-50%); /* Ajuste para centrar */
        bottom: -0.7rem;
        border-width: 6px;
        border-style: solid;
        border-color: #222 transparent transparent transparent; /* Color superior, luego lados transparentes */
    }
`;

const ButtonIA = styled.button`
    position: absolute;
    cursor: pointer;
    right: 0;
    bottom: 6rem;
    width: 57px;
    height: 57px;
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    border-radius: 50%;

    img {
        margin-top: -4px;
        margin-left: -8px;
        transition: all 0.3s ease-in-out; /* Ensure transition is applied here */
        scale: 0.7;
    }

    span {
        display: none;
    }

    :hover {
        img {
            scale: 1;
        }
    }

    :disabled {
        background: #e5e5e5;
        cursor: not-allowed;

        img {
            filter: grayscale(1);
        }

        :hover {
            img {
                scale: 0.7;
            }
            span {
                display: block;
            }
        }
    }
`;
