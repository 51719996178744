import React from 'react'
import styled from 'styled-components';
import useVimeo from '../../../acropolisCommon/hooks/useVimeo';

const RenderVideo = (props) => {

    const { resource } = props

    const { vimeoVideoRef, video, loading, failed } = useVimeo(resource.link_video);

    return (
        <div>
            {!failed ? <VimeoPLayer
                id={"{vimeo_player}"}
                ref={vimeoVideoRef}
                data-vimeo-url={video}
                isLoading={loading}
            />
                :
                <Iframe src={resource.video_url} />}
        </div>
    )
}

export default RenderVideo

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    min-height: 400px;
    height: 100%;
`

const VimeoPLayer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;