import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import styled from 'styled-components';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   button: {
//     marginTop: theme?.spacing(1),
//     marginRight: theme?.spacing(1),
//   },
//   actionsContainer: {
//     marginBottom: theme?.spacing(2),
//   },
//   resetContainer: {
//     padding: theme?.spacing(3),
//   },
// }));

const CertificateTracking = (props) => {
    const { statusLog } = props;
    // const classes = useStyles();
    const [currentStep, setCurrentStep] = useState(0);

    const steps = statusLog;

    useEffect(() => {
        // OBTENER MOVIMIENTO ACTUAL
        // eslint-disable-next-line array-callback-return
        steps.map((step, index) => {
            setCurrentStep(index);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusLog]);

    return (
        <Wrapper>
            <StepperFake activeStep={currentStep} orientation="vertical">
                {steps.map((label, index) => {
                    return (
                        <StepFake
                            index={index}
                            status={index}
                            lastStep={currentStep}
                            key={label.step}
                        >
                            <StepLabel>{label.estado_nombre}</StepLabel>
                            <DateFake status={index} lastStep={currentStep}>
                                {label.fecha_cambio_estado}
                            </DateFake>
                            <StepContent></StepContent>
                        </StepFake>
                    );
                })}
            </StepperFake>
        </Wrapper>
    );
};

export default CertificateTracking;

const Wrapper = styled.div`
    margin-top: 16px;
    max-height: 280px;
    overflow-y: auto;
    min-height: 190px;
    padding: 5px;
    width: calc(100% - 10px);
`;

const StepperFake = styled(Stepper)`
    margin-bottom: 16px;
`;

const StepFake = styled(Step)`
    .MuiStepLabel-label {
        color: #222222;
        font-weight: bold;
        font-size: 16.5px;
    }
    padding: ${(p) => (p.status === p.lastStep ? '8px 4px' : '0')};
    background-color: transparent;
    box-shadow: ${(p) =>
        p.status === p.lastStep
            ? 'rgba(100, 100, 111, 0.2) 0px 4px 19px 0px'
            : 'none'};
    border-radius: ${(p) => (p.status === p.lastStep ? '10px' : '0')};

    /* margin-left: ${(p) => (p.status === p.lastStep ? '-10px' : '0')}; */

    .MuiStepLabel-iconContainer {
        svg {
            color: ${(p) => (p.status === p.lastStep ? '#3bd39c' : '#3bd39c')};
            margin-left: 1rem;
            border-radius: 50%;
        }
    }
`;

const DateFake = styled.span`
    color: ${(p) => (p.status === p.lastStep ? '#3bd39c' : '#E9E9E9')};
    margin-left: 24px;
    font-size: 12.8px;
    font-weight: bold;
`;
