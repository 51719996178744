import React, { useEffect, useState, useReducer, useContext } from 'react';
import styled from 'styled-components';
import ProgramInfoCard from '../../ui/molecules/studyPlan/ProgramInfoCard';
import { Card } from '@mui/material';
import GridStudyPlan from '../../common/GridStudyPlan';
import GridPaginationBox from '../../common/GridPaginationBox';

const ProgramsCourses = (props) => {
    const { id, subjects, handleEnrollment } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        { programProgress: null }
    );

    // STATES
    const [isFlag, setIsFlag] = useState(false);
    const [dataAssigment, setDataAssigment] = useState(subjects);
    const [isChange, setIsChange] = useState(false);

    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(dataAssigment?.length / 16);

    useEffect(() => {
        setDataAssigment(subjects);
        setTimeout(() => {
            setIsChange(false);
        }, 300);
    }, [subjects, isFlag]);

    useEffect(() => {
        !state.programProgress && programProgressCalc();
    }, [state.programProgress]);

    const programProgressCalc = () => {
        const coursesProgress = subjects?.reduce(
            (acc, curr) => acc + curr?.progress?.progress,
            0
        );
        const totalProgress = Math.trunc(
            (coursesProgress * 100) / (subjects?.length * 100)
        );
        setTimeout(() => {
            updateState({ programProgress: totalProgress });
        }, 1000);
    };

    return (
        <Container>
            <ProgramInfoCard
                id={id}
                subjectsData={subjects}
                programProgress={
                    !state?.programProgress ? 0 : state?.programProgress
                }
                dataAssigment={dataAssigment}
            />

            <CardFake>
                <CardsWrapper>
                    <GridWrapper>
                        <GridStudyPlan
                            // Barajar la posibilidad de no paginar, ya que no creo que ningún programa tenga más de 16 asignaturas
                            course={dataAssigment?.slice(
                                page === 1 ? 0 : (page - 1) * 16,
                                page * 16
                            )}
                            setDataAssigment={setDataAssigment}
                            dataAssigment={dataAssigment}
                            setIsChange={setIsChange}
                            isChange={isChange}
                            setIsFlag={setIsFlag}
                            isFlag={isFlag}
                            handleEnrollment={handleEnrollment}
                        />
                    </GridWrapper>
                </CardsWrapper>
            </CardFake>
            <GridPaginationBox
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </Container>
    );
};

export default ProgramsCourses;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const CardFake = styled(Card)`
    position: relative;
    background-color: transparent;
    box-shadow: none;
`;

const CardsWrapper = styled.div`
    /* padding: 2rem; */

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const GridWrapper = styled.div`
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
