import styled from 'styled-components';

const CardStudentIdp = (props) => {

    const { student } = props;

    function formatIDPString(input) {
        let words = input.toLowerCase().split(/[- ]/);

        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

        return words.join(input.includes('-') ? ' - ' : ' ');
    }

    return (
        <Card>
            <Name>{student?.name}</Name>
            <ChipContainer>
                <Chip
                    lightColor={student?.performance === "alto" ? "#F7FDFB" : "#F9F5EC"}
                    strongColor={student?.performance === "alto" ? "#1E8065" : "#C29F43"}
                >
                    IDP {formatIDPString(student?.performance)}
                </Chip>
            </ChipContainer>
            <Percentage>{Math.round(100 * student?.inactive_days)}%</Percentage>
            <Percentage>{Math.round(100 * student?.display_resources)}%</Percentage>
            <Percentage>{Math.round(100 * student?.activities_presented)}%</Percentage>
            <Percentage>{Math.round(100 * student?.course_speed)}%</Percentage>
            <Total>{student?.idp_value}</Total>
        </Card>
    );
};

export default CardStudentIdp;

const Card = styled.div`
    height: 66px;
    padding: 1rem;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: 18% 18% 14% 12.5% 12.5% 12.5% 12.5%;
    border-radius: 8px;
    background-color: #f1f1f1;
    border: solid 1px #b31d15;
`;

const Name = styled.div`
    font-weight: 600;
    text-align: start;
    text-transform: capitalize;
    font-size: 14px;
`;

const ChipContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Chip = styled.div`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 8px;
    outline: solid 1px ${({strongColor}) => strongColor};
    background-color: ${({lightColor}) => lightColor};
    font-weight: 700;
    color: ${({strongColor}) => strongColor};
    font-size: 11px;
    max-width: 100%;
`;

const Percentage = styled.div`
    font-size: 15px;
    text-align: center;
`;
const Total = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-align: center;
`;
