import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import MoreFilterButton from './student/components/MoreFilterButton';
import ModalMoreFilter from './student/components/ModalMoreFilter';
import ModalWeekIdp from './student/components/ModalWeekIdp';
import SimpleLoading from '../../../common/SimpleLoading';
import ErrorResponse from '../../../common/ErrorResponse';
import TagFilter from './student/components/TagFilter';
import UsersCard from './student/UsersCard';

// Material UI
import { Button, InputAdornment, TextField } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { SearchRounded } from '@mui/icons-material';

// Hooks
import useStudentsCourse from '../../../../hooks/teacher/useStudentsCourse';

// Redux
import { getIDPTopTen } from '../../../../redux/api/subjects';

const Students = (props) => {
    const { id } = props;

    // STATES
    const [filterField, setFilterField] = useState('');
    const [openMoreFilter, setOpenMoreFilter] = useState(false);
    const [filterSelected, setFilterSelected] = useState("confirmado");
    const [openWeekModal, setOpenWeekModal] = useState(false);
    const [studentsIDP, setStudentsIDP] = useState(null);

    const buttonRef = useRef(null);

    // HOOKS
    const {
        /* allStudents, */
        students,
        viewLoading,
        /* noMore, */
        setPayload,
        /* handleStudents, */
        handleMoreStudents,
        tag,
        setTag
    } = useStudentsCourse(id);

    useEffect(() => {
        getWeekIdp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWeekIdp = async () => {
        // API CALL
        try {
            const response = await getIDPTopTen(id);
            if (response.error) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setStudentsIDP(response);
        } catch (e) {
            setStudentsIDP(null);
        }
    };

    const timeoutRef = useRef(null);

    // FUNCTIONS
    const handleWeekModal = () => {
        setOpenWeekModal(!openWeekModal);
    };
    const handleChange = (e) => {
        const { value } = e.target;
        setFilterField(value);
        // Clear the previous timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout
        timeoutRef.current = setTimeout(() => {
            setPayload(prevPayload => ({
                ...prevPayload,
                query: {
                    ...prevPayload.query,
                    filters: {
                        ...prevPayload.query.filters,
                        name: value
                    }
                }
            }));
            /* handleStudents(
                allStudents.filter((student) =>
                    student.name
                        .toLowerCase()
                        .includes(value.toLowerCase())
                )
            ); */
        }, 500); // 2 seconds delay
    };

    const handleFilterSelected = (filter) => {
        setFilterSelected(filter);
    };

    const handleConfirmFilter = () => {
        setPayload(prevPayload => ({
            ...prevPayload,
            query: {
                ...prevPayload.query,
                filters: {
                    ...prevPayload.query.filters,
                    estado_matriculacion: filterSelected
                }
            }
        }));
        setOpenMoreFilter(!openMoreFilter);
    };

    const handleMoreFilter = () => {
        setOpenMoreFilter(!openMoreFilter);
    };

    // RETURN
    /* if (!students) {
        return <SimpleLoading />;
    } */
    return (
        <>
            {openWeekModal && (
                <ModalWeekIdp
                    studentsIDP={studentsIDP}
                    openWeekModal={openWeekModal}
                    handleWeekModal={handleWeekModal}
                />
            )}
            <Container>
                <Header>
                    <TextField
                        value={filterField}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label="Buscar estudiante..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <MoreFilterButton
                        ref={buttonRef}
                        handleMoreFilter={handleMoreFilter}
                    />
                    {buttonRef.current && openMoreFilter && (
                        <ModalMoreFilter
                            buttonRef={buttonRef}
                            filterSelected={filterSelected}
                            handleFilterSelected={handleFilterSelected}
                            handleConfirmFilter={handleConfirmFilter}
                        />
                    )}
                </Header>
                <Filters>
                    <TagFilter tag={tag} setTag={setTag} />
                    <ButtonContainer>
                        <CustomButton disabled={!studentsIDP} onClick={handleWeekModal}>
                            <BarChartIcon />
                            Ver índice de participación semanal
                        </CustomButton>
                    </ButtonContainer>
                </Filters>
                {(!students)
                    ? <SimpleLoading />
                    : (students?.length === 0)
                        ? <ErrorResponse message="No hay estudiantes en esta asignatura" />
                        : <UsersWrapper>
                            {students?.map((user) => {
                                return <UsersCard key={user?.id} user={user} />;
                            })}
                        </UsersWrapper>
                }
            </Container>
            {students?.length !== 0 && (
                <DivButton>
                    {!!viewLoading ? (
                        <SimpleLoading />
                    ) : /* !noMore ? ( */
                        <Button
                            onClick={handleMoreStudents}
                            color="primary"
                            variant="outlined"
                        >
                            Ver más
                        </Button>
                    /* ) : (
                        <NoMore>Llegaste al final</NoMore>
                    ) */}
                </DivButton>
            )}
        </>
    );
};

export default Students;

const Container = styled.div`
    width: calc(100% - 32px);
    min-height: 70vh;
    margin: 0 auto;
    padding: 0 16px;
`;

const Header = styled.div`
    display: flex;
    padding: 16px;
    gap: 1rem;
    position: relative;
`;

const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    gap: 1rem;
`;

const UsersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 1rem;
    align-items: start;
`;

const DivButton = styled.div`
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

/* const NoMore = styled.p`
    color: #b31d15;
`; */

const ButtonContainer = styled.div`
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
`;
const CustomButton = styled.button`
    padding: 8px 24px;
    background-color: #b31d15;
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: start;
    border-radius: 10px;
    gap: 0.5rem;
    font-weight: bold;
    width: fit-content;
    font-size: 0.9rem;

    svg {
        font-size: 1.5rem;
    }

    :disabled {
        background-color: #ccc;
        cursor: not-allowed
    }
`;
