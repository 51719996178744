import { createContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as base64 from 'nodejs-base64-encode';
import {
    getAssignmentRelated,
    getProgram,
    getRelatedPrograms,
    getStudyPlanFake,
    postAssignmentPrograms,
} from '../../../redux/api/studyPlan';
import useLayout from '../../../hooks/useLayout';

export const MyProgressContext = createContext(null);

export const MyProgressProvider = ({ children }) => {
    const { id } = useParams();
    const history = useHistory();

    const programId = base64.decode(id, 'base64');

    const { setMenu } = useLayout();

    const repoId = useSelector((state) => state.auth.user.repo_id);

    const [programRelated, setProgramRelated] = useState([]);
    const [data, setData] = useState(null);
    const [programData, setProgramData] = useState(null);
    const [isRelated, setIsRelated] = useState(null);

    const isDiplomat = programData?.name?.includes('Diplomado');

    // EFFECT
    useEffect(() => {
        if (programData === null) {
            getProgramByIds();
        }
    }, []);

    useEffect(() => {
        if (programRelated?.length > 0) {
            getDataAssignmentRelated(programRelated[0].id);
        }
    }, [programRelated]);

    useEffect(() => {
        if (programData !== null) {
            setIsRelated(programData.has_related_programs);
        }
    }, [programData]);

    useEffect(() => {
        programData && setMenu(programData?.name);
    }, [programData]);

    useEffect(() => {
        if (isRelated !== null) {
            getProgramRelated();
        }
    }, [isRelated]);

    useEffect(() => {
        if (data === null) {
            getDataStudyPlan(programId);
        }
    }, [data]);

    // trae la info del programa actual
    const getProgramByIds = async () => {
        const response = await getProgram(repoId, programId);
        if (response.error) {
            history.push('../not-found');
            return;
        }
        setProgramData(response);
    };

    // trae los programas relacionados
    const getProgramRelated = async () => {
        const response = await getRelatedPrograms(programData.id, repoId);
        if (!response.error) {
            setProgramRelated(response);
        }
    };

    // trae las asignaturas
    const getDataStudyPlan = async (id) => {
        const response = await getStudyPlanFake(id, repoId);
        if (response.error) {
            history.push('../not-found');
            return;
        }

        const cleanedData = response.assignments.filter(
            (subject) => subject.elective_subject !== false
        );

        const modifiedResponse = { ...response, assignments: cleanedData };

        setData(modifiedResponse);
    };

    async function handleEnrollment(repoId, subjectId) {
        const body = {
            type: 'elective_subject',
            assignment: subjectId,
            program_id: programId,
        };

        const response = await postAssignmentPrograms(repoId, body);
        if (response?.error) {
            return response?.error;
        }
        setData(null);
        return response;
    }

    // trae las asignaturas de planes de estudios relacionados
    const getDataAssignmentRelated = async (id) => {
        const response = await getAssignmentRelated(id, repoId);
        if (response.error) {
            history.push('../not-found');
        }
        setData(response);
    };

    return (
        <MyProgressContext.Provider
            value={{
                subjects: data?.assignments,
                programState: data?.state_program,
                data,
                programData,
                handleEnrollment,
                programRelated,
                programId,
                getDataAssignmentRelated,
                getDataStudyPlan,
                isDiplomat,
                repoId,
            }}
        >
            {children}
        </MyProgressContext.Provider>
    );
};
