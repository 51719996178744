import { Card } from '@mui/material';
import styled from 'styled-components';

export const SubjectCardContainer = styled(Card)`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px !important;
    border-radius: 20px;
    background-color: #ffffff;
    transition: 0.3s all ease;
    cursor: ${(p) => (p.notAvailable ? 'default' : 'pointer')};
    cursor: ${(p) =>
        p.isDemo === 'DEMO'
            ? p.demoUnlocker
                ? 'pointer'
                : 'default'
            : 'pointer'};
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    :hover {
        box-shadow: ${(p) =>
            p.isDemo === 'DEMO'
                ? p.demoUnlocker
                    ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    : 'none'
                : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};
        :before {
            opacity: ${(p) =>
                p.isDemo === 'DEMO' ? (p.demoUnlocker ? '1' : 'none') : '1'};
        }
    }
`;

export const BodySubjectsCard = styled.div`
    display: flex;
    background-color: white;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0 1rem 1rem 1rem;
    padding-top: 1rem;
    z-index: 5;
    max-width: 100%;
    height: 250px !important;
    overflow: hidden;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: 100px !important;
    width: 100%;
    background-color: #f1f1f1;
    padding: 0.3rem;
    border-radius: 0px 0px 20px 20px;
`;

export const Plan = styled.div`
    display: flex;
    height: 250px;
    align-items: center;
    h2 {
        font-size: 1rem;
        font-weight: 900;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0.3px;
        color: ${(p) => (p.notAvailable ? '#a8a8a8' : '#222222')};
        @media screen and (max-width: 768px) {
            font-size: 1.2rem;
        }
    }
`;

export const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;

export const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

export const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: ${(props) =>
        props.disabledState ? '#A8A8A8' : '#3bd39c'};
`;

export const Percentage = styled.span`
    font-size: 0.9rem;
    color: ${(props) => (props.disabledState ? '#A8A8A8' : '#222222')};
    flex-wrap: wrap;
`;

export const DisablerCover = styled.div`
    position: absolute;
    height: 100%;
    min-width: 100%;
    z-index: 6;
    background-color: #eaeaea;
    opacity: 0.45;
`;

export const CourseStateChip = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0.4rem 0.7rem;
    z-index: 7;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06px;
    border-radius: 8px;
`;

//ELECTIVAS
export const ElectiveWrapper = styled.div`
    height: 100%;
    width: calc(100 - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`;

export const ElectiveIndications = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const ElectiveIcon = styled.img`
    width: 24px;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;
