import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import moment from 'moment';
import StateErrorWrapper from './StateErrorWrapper';
import CommonChip from '../../CommonChip';
import { InfoOutlined } from '@mui/icons-material';
import { Text } from '../../Texts';
import CorrelativesModal from '../../modals/CorrelativesModal';

export function formatDate(date, format) {
    return moment.utc(date).format(format);
}
//diplomado
const StateSubjectContent = (props) => {
    const {
        enrollmentState,
        data,
        setOpenModalExpired,
        setOpenModalBloqued,
        setOpenModalToExpire,
        enrollmentDates,
        hasCorrelatives,
    } = props;

    const { expiring, expected, start } = enrollmentDates;

    const [openCorrelativesModal, setOpenCorrelativesModal] = useState(false);

    useEffect(() => {}, []);

    const handleCorrelativesModal = () => {
        setOpenCorrelativesModal(!openCorrelativesModal);
    };

    const chipContent = {
        //inicio y  de cursado
        'Por iniciar': (
            <Content>
                <Seccion>
                    <Icon icon="tdesign:calendar-2" />
                    <TextDiv>
                        <p>Inicio</p>
                        <span>{formatDate(start, 'DD/MM/YYYY')}</span>
                    </TextDiv>
                </Seccion>{' '}
                {expected && (
                    <Seccion>
                        <Icon icon="ci:calendar-close" />
                        <TextDiv>
                            <p>Vencimiento</p>
                            <span>
                                {formatDate(
                                    data?.enrollment_dates?.expected_end_date,
                                    'DD/MM/YYYY'
                                )}
                            </span>
                        </TextDiv>
                    </Seccion>
                )}
            </Content>
        ),

        'Próximo a vencer': (
            <StateErrorWrapper
                enrollmentState={enrollmentState}
                setOpenModalToExpire={setOpenModalToExpire}
            />
        ),

        'En curso': (
            <>
                <Content>
                    <Seccion>
                        <Icon icon="tdesign:calendar-2" />
                        <TextDiv>
                            <p>Inicio</p>
                            <span>{formatDate(start, 'DD/MM/YYYY')}</span>
                        </TextDiv>
                    </Seccion>{' '}
                    {expected && (
                        <Seccion>
                            <Icon icon="ci:calendar-close" />
                            <TextDiv>
                                <p>Vencimiento</p>
                                <span>
                                    {formatDate(
                                        data?.enrollment_dates
                                            ?.expected_end_date,
                                        'DD/MM/YYYY'
                                    )}
                                </span>
                            </TextDiv>
                        </Seccion>
                    )}
                </Content>
            </>
        ),
        Egresado: (
            <Content>
                <CommonChip
                    label="Aprobada"
                    variant="status"
                    strongColor="#1E8065"
                    lightColor="#EAFAF6"
                    Icon={() => <Icon icon="material-symbols:check" />}
                />
                <Seccion>
                    <Icon
                        icon="material-symbols:check-circle-outline"
                        width="16px"
                        height="16px"
                    />
                    <TextDiv>
                        <p>
                            {' '}
                            Nota: <b>{data?.nota_final}</b> / 100
                        </p>
                    </TextDiv>
                </Seccion>
            </Content>
        ),
        'En pausa': <StateErrorWrapper enrollmentState={enrollmentState} />,
        Bloqueado: (
            <StateErrorWrapper
                enrollmentState={enrollmentState}
                setOpenModalBloqued={setOpenModalBloqued}
            />
        ),
        Vencido: (
            <StateErrorWrapper
                enrollmentState={enrollmentState}
                setOpenModalExpired={setOpenModalExpired}
            />
        ),
        correlatives: (
            <InfoWrapper onClick={handleCorrelativesModal}>
                <InfoOutlined fontSize="small" />
                <Text
                    fontSize="13px"
                    color="#B31D15"
                    style={{ textDecoration: 'underline #b31d15' }}
                >
                    ¿Por qué aún no puedo matricularme?
                </Text>
                <CorrelativesModal
                    onClose={handleCorrelativesModal}
                    open={openCorrelativesModal}
                    subject={data}
                />
            </InfoWrapper>
        ),

        'Sin matricular': (
            <Content>
                <Seccion>
                    <Icon icon="carbon:time" />
                    <span>
                        {data?.duration_week === 1
                            ? data?.duration_week + ' semana'
                            : data?.duration_week + ' semanas'}
                    </span>
                    <p>de cursado </p>
                </Seccion>
            </Content>
        ),
        Finalizado: <Content></Content>,
    };

    return (
        <>{chipContent[hasCorrelatives ? 'correlatives' : enrollmentState]}</>
    );
};

export default StateSubjectContent;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 1rem);
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    align-content: center;
`;

const Seccion = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    align-items: center;
    p {
        color: #616161;
        font-weight: 500;
        font-size: 13px;
    }
    span {
        color: #616161;
        font-size: 14px;
        font-weight: 700;
    }
    svg {
        color: #616161;
        font-size: 20px;
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 0.8rem;
        }
        span {
            font-size: 0.9rem;
        }
    }
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const InfoWrapper = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    svg {
        color: #b31d15;
    }
`;
