import styled from 'styled-components';

// Components
import LazyImg from '../../../../modules/acropolisCommon/components/LazyImg';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';

const EventCardHeader = (props) => {
    const { value, eventDateBegin, currentDate } = props;

    return (
        <Header>
            <LazyImg
                src={
                    value.image_url
                        ? value.image_url
                        : '/assets/event-default.jpg'
                }
                width="100%"
                height="150px"
                borderRadius="20px 20px 0 0"
            />
            {eventDateBegin < currentDate ? (
                <ChipRegistered backgroundColor={'#E94C44'}>
                    <FontAwesomeIcon
                        icon="fa-regular fa-calendar-xmark"
                        size={16}
                        color="#FFF"
                    />
                    Evento finalizado
                </ChipRegistered>
            ) : (
                value.is_registered && (
                    <ChipRegistered
                        backgroundColor={
                            eventDateBegin === currentDate
                                ? '#b31d15'
                                : '#28A986'
                        }
                    >
                        <FontAwesomeIcon
                            icon="fa-regular fa-check"
                            size={14}
                            color="#FFF"
                        />
                        Inscripto
                    </ChipRegistered>
                )
            )}
            {value.category.name && (
                <ChipTypeEvent>
                    Evento {value.category.name.toLowerCase()}
                </ChipTypeEvent>
            )}
        </Header>
    );
};

export default EventCardHeader;

const Header = styled.div`
    position: relative;
`;

const ChipRegistered = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #fff;
    border-radius: 100px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    font-size: 0.7rem;
    font-weight: 700;
    color: #fff;
    padding: 8px 16px 8px 10px;
    margin-top: 1rem;
    margin-left: 1rem;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
`;

const ChipTypeEvent = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 20px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 900;
    color: #616161;
    padding: 8px 16px;
`;
