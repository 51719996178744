import styled from 'styled-components';
import QRGooglePlay from '../assets/ADEN-Acrópolis-QR-GooglePlay.png';
import QRAppStore from '../assets/ADEN-Acrópolis-QR-App-Store.png';
import FontAwesomeIcon from '../../../../../../components/common/FontAwesomeIcon';

const BodyStepSix = () => {
    return (
        <BodyContainer>
            <h4>
                Descarga la app escaneando estos códigos QR o búscala en la
                tienda de aplicaciones de tu dispositivo.
            </h4>
            <Info>
                <span>
                    <img src={QRGooglePlay} alt="QR Google Play" />
                    <label>
                        <FontAwesomeIcon
                            icon="fa-brands fa-google-play"
                            size={20}
                        />
                        Google Play Store
                    </label>
                </span>
                <span>
                    <img src={QRAppStore} alt="QR App Store" />
                    <label>
                        <FontAwesomeIcon icon="fa-brands fa-apple" size={24} />
                        App Store
                    </label>
                </span>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepSix;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: 100%;
    z-index: 9;

    h4 {
        color: #1d1d18;
        font-weight: 700;
        font-size: 16px;
        z-index: 9;
    }
`;

const Info = styled.div`
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    z-index: 2;
    color: #222;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
            width: 60%;
            height: 60%;
        }

        label {
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }
    }
`;
