import styled from 'styled-components';
import MobileMockup from '../../../../../assets/media/aden/mobile-mockup.png';
import AdenTeAcompaña from '../../../../../assets/media/aden/aden-te-acompaña-white.png';
import FontAwesomeIcon from '../../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../../components/common/CommonButton';

const ModalHome = (props) => {
    const { onClose, nextStep, downloadStep } = props;

    return (
        <ModalContainer>
            <Mockup src={MobileMockup} alt="Mobile Mockup" />
            <AdenTeAcompañaLogo src={AdenTeAcompaña} alt="Aden Te Acompaña" />
            <CloseButton onClick={onClose}>
                <FontAwesomeIcon
                    icon="fa-light fa-xmark"
                    size={15}
                    color="#fff"
                />
            </CloseButton>
            <div>
                <h1>APP ADEN ACRÓPOLIS</h1>
                <h2>
                    tu <i>nueva forma</i> de estudiar
                </h2>
            </div>
            <Body>
                <h3>¿Qué podrás realizar en la nueva app?</h3>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Acceder a <b>todo el contenido de ADEN</b> de manera{' '}
                        <b>rápida y sencilla</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Consultar el <b>progreso de tus actividades</b> en
                        tiempo <b>real</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Continuar tu formación <b>desde cualquier lugar</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        <b>Participar en eventos exclusivos</b> y mucho más.
                    </span>
                </p>
            </Body>
            <ButtonsContainer>
                <CommonButton
                    label="Descargar app"
                    variant="text"
                    FontAwesomeIconName="fa-light fa-arrow-down-to-line"
                    onClick={downloadStep}
                />
                <CommonButton
                    label="Conocer más"
                    variant="filled"
                    onClick={nextStep}
                />
            </ButtonsContainer>
        </ModalContainer>
    );
};

export default ModalHome;

const ModalContainer = styled.div`
    position: relative;
    height: calc(80vh - 4rem);
    width: calc(120vh - 4rem);
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    border-radius: 50px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;

    &:focus-visible {
        outline: none;
    }
    @media (width < 1540px) {
        height: calc(80vh);
        width: calc(120vh);
        padding: 2rem;
    }
`;

const AdenTeAcompañaLogo = styled.img`
    height: 50px;
    margin-left: -2rem;
`;

const Mockup = styled.img`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 55vh;
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 4rem;
    right: 4rem;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;

    button {
        border: 1px solid #fff;
    }
`;
