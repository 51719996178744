import styled from 'styled-components';
import BannerAppMobile from '../../../../assets/media/banners/resource-banner-app-mobile.png';
import CommonButton from '../../../../components/common/CommonButton';
import { useSelector } from 'react-redux';
import ModalInfoBanner from './ModalInfoBanner';
import { useState } from 'react';

const PrincipalInfoBanner = () => {
    const { partner_id, name } = useSelector((state) => state.auth.user);

    const [openModal, setOpenModal] = useState(false);

    const comportamientoInfo = {
        partner_id: partner_id,
        partner_name: name,
    };

    return (
        <>
            <ModalInfoBanner
                open={openModal}
                onClose={() => setOpenModal(false)}
            />
            <Container backgroundImage={BannerAppMobile}>
                <h1>
                    <b>¡NUEVA APP ADEN ACRÓPOLIS!</b> <br />
                    Descubre y potencia tu experiencia de aprendizaje.
                </h1>
                <CommonButton
                    variant="text"
                    label="¡Explora la nueva app!"
                    onClick={() => setOpenModal(true)}
                    data-behaviour-action={'banner_app_mobile'}
                    // Event detail
                    data-behaviour-detail={JSON.stringify(comportamientoInfo)}
                />
            </Container>
        </>
    );
};

export default PrincipalInfoBanner;

const Container = styled.div`
    position: relative;
    width: calc(100% - 2rem);
    height: 171px;
    border-radius: 30px;
    background-color: #b31d15;
    background-image: url(${(p) => p.backgroundImage});
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: right; /* Posiciona la imagen al final */
    background-size: contain; /* Ajusta la imagen para cubrir todo el contenedor */
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4rem;
    padding-left: 2rem;

    h1 {
        color: #fff;
        font-size: 30px;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }

    button {
        position: absolute;
        top: 50%;
        right: 35%;
        transform: translateY(-50%);

        :hover {
            box-shadow:
                0px 3px 6px 0px #0000003b,
                0px 3px 6px 0px #00000029;
        }
    }

    @media (width < 1500px) {
        align-items: start;
        padding-top: 2rem;
        height: 139px;

        h1 {
            font-size: 24px;
        }

        button {
            height: fit-content;
            left: 2rem;
            transform: translateY(30%);
        }
    }

    @media (width < 768px) {
        background-image: none;
        justify-content: center;
        background-color: #b31d15;

        h1 {
            font-size: 16px;
            text-align: center;

            b {
                font-weight: 900;
            }
        }

        button {
            transform: translate(35%, 30%);
        }
    }
`;
