import axios from 'axios';

export const changeEnrollmentStatus = async (body) => {
    //vencido "true" para solicitar primer prorroga, "false" para retomar cursado.
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/change_enrollment_status?partner_id=${body.partnerId}&estado=${body.state}&asignatura_id=${body.assignment}&vencido=${body.vencido}`;
    try {
        const response = await axios.post(URL);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};
