import { useEffect, useState } from 'react';
import {
    getAnnouncementsByAssignmentId,
    patchAnnouncements,
    postAnnouncements,
} from '../../redux/api/announcements';
import useFetch from '../useFetch';
import { announcementsBySubjectAdapter } from '../../components/ui/organisms/subject/announcements/adapter/announcementsAdapter';

const useSubjectAnnouncements = (id, rol, repo_id) => {
    const [announcementsData, setAnnouncementsData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [succesAction, setSuccesAction] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [loadingAction, setLoadingAction] = useState(false);
    const [errorAction, setErrorAction] = useState(false);

    //paginación
    const [page, setPage] = useState(1);
    const pageMax = Math.ceil(announcementsData.length / 4);

    const initialValues = {
        title: '',
        message: '',
        publishedDate: '',
        expirationDate: '',
        subjectId: id,
    };

    //Lista Inicial de anuncios por Asignatura
    const { data, loading, error, isError, refetch } = useFetch({
        asyncFn: () => getAnnouncementsByAssignmentId(id),
        adapter: announcementsBySubjectAdapter,
    });

    useEffect(() => {
        if (data) {
            const flattenedData = mapNewsletters(data.announcementsList);
            const todayDate = new Date();

            const toStudentData = flattenedData.filter((item) => 
                (item) => new Date(item?.publishedDate) <= todayDate);

            if(rol !== 'docente'){
                setAnnouncementsData(toStudentData);
                return;
            }
            setAnnouncementsData(flattenedData);
        }
    }, [data]);

    const mapNewsletters = (newslettersData) => {
        return newslettersData?.flatMap((newsletter) => ({
            publishedDate: newsletter?.publishedDate,
            general: false,
            card: newsletter,
            rol: rol,
            key: newsletter?.id,
        }));
    };

    const validateDates = (published_date, expiration_date) => {
        const today = new Date();
        const publishedDate = new Date(published_date + ' 23:59:00');
        publishedDate.setDate(publishedDate.getDate());
        const expirationDate = new Date(expiration_date + ' 23:59:00');
        expirationDate.setDate(expirationDate.getDate());
        if (today > publishedDate) {
            return {
                isValid: false,
                error: 'La fecha de publicación debe ser igual o posterior a la fecha actual',
            };
        }
        if (publishedDate > expirationDate) {
            return {
                isValid: false,
                error: 'La fecha de caducidad debe ser posterior a la fecha de publicación',
            };
        }
        return { isValid: true };
    };

    const handleSubmitAnnouncement = async (values) => {
        const { isValid, error } = validateDates(
            values.publishedDate,
            values.expirationDate
        );

        const data = {
            asignatura_id: values.subjectId,
            active: true,
            title: values.title,
            message: values.message,
            expiration_date: values.expirationDate,
            published_date: values.publishedDate,
        };

        if (isValid && !loadingAction) {
            setLoadingAction(true);
            try {
                await postAnnouncements(repo_id, data);
                setLoadingAction(false);
                setOpenModal(false);
                setSuccesAction('publicó');
                setOpenModalSuccess(true);
                setErrorAction(false);
            } catch (error) {
                setLoadingAction(false);
                setErrorAction('Error, por favor intente de nuevo');
            }
        } else {
            setErrorAction(error);
        }
    };

    const handleSubmitAnnouncementEdited = async (values) => {
        const { isValid, error } = validateDates(
            values.publishedDate,
            values.expirationDate
        );
        const newsletter_id = values.id;
        const data = {
            active: true,
            title: values.title,
            message: values.message,
            expiration_date: values.expirationDate,
            published_date: values.publishedDate,
        };
        if (!isValid) {
            setErrorAction(error);
            return;
        }
        try {
            await patchAnnouncements(newsletter_id, data);
            setOpenEditModal(false);
            setSuccesAction('editó');
            setOpenModalSuccess(true);
        } catch (error) {
            setErrorAction('Error, por favor intente de nuevo');
        }
    };

    //unicamente se despublica, luego de ello el EP solo envia anuncios publicados por el profesor para su edición.
    const handleAnnouncementUnpublishing = async () => {
        const newsletter_id = Number(openEditModal.id);
        const data = {
            active: false,
        };
        setLoadingAction(true);
        try {
            await patchAnnouncements(newsletter_id, data);
            setLoadingAction(false);
            setOpenEditModal(false);
            setSuccesAction('despublicó');
            setOpenModalSuccess(true);
        } catch (error) {
            setLoadingAction(false);
            setErrorAction('Error, por favor intente de nuevo');
        }
    };

    const resetData = () => {
        setAnnouncementsData([]);
        refetch();
    };

    return {
        announcementsData,
        setAnnouncementsData,
        loading,
        error,
        handleSubmitAnnouncement,
        handleSubmitAnnouncementEdited,
        handleAnnouncementUnpublishing,
        loadingAction,
        errorAction,
        page,
        setPage,
        pageMax,
        setOpenModal,
        openModal,
        initialValues,
        openEditModal,
        setOpenEditModal,
        openModalSuccess,
        setOpenModalSuccess,
        succesAction,
        resetData,
        setErrorAction,
    };
};

export default useSubjectAnnouncements;
