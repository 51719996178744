import FilterListIcon from "@mui/icons-material/FilterList";
import { forwardRef } from "react";
import styled from "styled-components";

const MoreFilterButton = forwardRef((props, ref) => {
    const { handleMoreFilter } = props;

    return (
        <Button ref={ref} onClick={handleMoreFilter}>
            <FilterListIcon />
            Añadir filtro
        </Button>
    );
});

export default MoreFilterButton;

const Button = styled.button`
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    box-sizing: border-box;
    padding: 10px 24px;
    border-radius: 10px;
    background-color: #b31d15;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;

    svg {
        font-size: 1.4rem;
    }
`;
