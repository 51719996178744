import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';

const ModalCreateAnnouncement = (props) => {
    const {
        initialValues,
        handleSubmitAnnouncement,
        handleSubmitAnnouncementEdited,
        handleAnnouncementUnpublishing,
        announcementSchema,
        setOpenModal,
        loading,
        error,
        type,
        setErrorAction,
    } = props;

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return [year, month, day].join('-');
    };

    const handleSubmit = (values) => {
        if (type === 'create') {
            handleSubmitAnnouncement(values);
        }
        if (type === 'edit') {
            handleSubmitAnnouncementEdited(values);
        }
    };

    return (
        <Modal>
            <Header>
                {type !== 'edit' ? 'Nuevo anuncio' : 'Edición de anuncio'}
                {type === 'edit' && (
                    <UbpublishButton onClick={handleAnnouncementUnpublishing}>
                        Despublicar
                    </UbpublishButton>
                )}
            </Header>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={announcementSchema}
            >
                {({ values, setFieldValue }) => (
                    <FormData>
                        <label>Asunto</label>
                        <Input
                            name="title"
                            type="text"
                            label="Asunto"
                            placeholder="Introduce un título"
                            value={values.title}
                            setFieldValue={setFieldValue}
                            required
                        />
                        <label>Anuncio</label>
                        <Field
                            name="message"
                            as={InputTextArea}
                            label="Anuncio"
                            placeholder="Redacta tu anuncio"
                            value={values.message}
                            setFieldValue={setFieldValue}
                            required
                        />
                        <label>Fecha de publicación y caducidad</label>
                        <p>
                            Si deseas puedes programar cuándo quieres que se
                            publique y despublique el anuncio.
                        </p>
                        <section>
                            <div>
                                <label>Fecha de publicación:</label>
                                <Input
                                    name="publishedDate"
                                    type="date"
                                    value={formatDate(values.publishedDate)}
                                    setFieldValue={setFieldValue}
                                    required
                                />
                            </div>
                            <div>
                                <label>Fecha de caducidad:</label>
                                <Input
                                    name="expirationDate"
                                    type="date"
                                    value={formatDate(values.expirationDate)}
                                    setFieldValue={setFieldValue}
                                    required
                                />
                            </div>
                        </section>
                        <ButtonsWrapper>
                            <ModalButton
                                variant="secondary"
                                onClick={() => {
                                    setOpenModal(false);
                                    setErrorAction(false);
                                }}
                            >
                                Cancelar
                            </ModalButton>
                            <ModalButton
                                type="submit"
                                onClick={() => handleSubmit(values)}
                            >
                                {loading
                                    ? 'Cargando...'
                                    : type === 'create'
                                    ? 'Publicar anuncio'
                                    : 'Editar anuncio'}
                            </ModalButton>
                        </ButtonsWrapper>
                    </FormData>
                )}
            </Formik>
            {!!error && <ErrorMsg>{error}</ErrorMsg>}
        </Modal>
    );
};

export default ModalCreateAnnouncement;

const Modal = styled.div`
    position: fixed;
    width: calc(70vw - 4rem);
    min-height: calc(70vh - 4rem);
    height: fit-content;
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50.1%, -50.1%);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    label {
        font-size: 18px;
        font-weight: 700;
        color: #222;
        margin-top: 0.85rem;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #616161;
    }

    section {
        width: calc(100% - 2rem);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 0.5rem;

        div {
            display: flex;
            flex-direction: column;
            width: 100%;

            label {
                font-family: Lato;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #616161;
            }

            input {
                width: 100%;
                color: #616161;
                font-weight: 600;
                font-size: 14px;
                :focus-visible {
                    outline: 2px solid #616161;
                }
                @media (max-width: 1024px) {
                    height: 20px;
                }
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 700;
    color: #b31d15;
    margin-left: 0;
`;

const FormData = styled(Form)`
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
    overflow-y: auto;
    padding-left: 0.2rem;
`;

const InputTextArea = styled.textarea`
    width: calc(100% - 2rem);
    line-height: 1.5;
    height: 96px !important;
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    text-rendering: optimizeLegibility;
    :focus-visible {
        outline: 2px solid #616161;
    }
`;

const Input = styled(Field)`
    width: calc(100% - 2rem);
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem;

    font-size: 16px;
    font-weight: 400;
    color: #616161;
    line-height: 24px;
    letter-spacing: 0.5%;

    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    :focus-visible {
        outline: 2px solid #616161;
    }
`;

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-top: 0.5rem;
`;

const ModalButton = styled.button`
    cursor: pointer;
    border-radius: 8px;
    background-color: ${(props) =>
        props.variant === 'secondary' ? '#fff' : '#b31d15'};
    color: ${(props) => (props.variant === 'secondary' ? '#b31d15' : '#fff')};
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: ${(props) => props.variant === 'secondary' && '1px solid #b31d15'};

    :hover {
        background-color: ${(props) =>
            props.variant !== 'secondary' && '#CD2118'};
    }
`;

const ErrorMsg = styled.div`
    color: #cd2118;
    font-weight: 700;

    @media (width <= 1540px) {
        font-size: 14px;
    }
`;

const UbpublishButton = styled.button`
    cursor: pointer;
    border-radius: 8px;
    background-color: #b31d15;
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid #b31d15;
    :hover {
        background-color: #cd2118;
    }
`;
