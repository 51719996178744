import React from 'react';
import styled from 'styled-components';

const BarProgress = (props) => {
    const { value = 0 } = props;

    return (
        <ProgressWrapper>
            <LinearProgressWrapper>
                <LinearProgress porcentaje={value} />
            </LinearProgressWrapper>
            <Percentage>{value}%</Percentage>
        </ProgressWrapper>
    );
};

export default BarProgress;

const ProgressWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(p) => `${p.porcentaje}%`};
    height: 7px;
    background-color: #3bd39c;
`;

const Percentage = styled.span`
    font-size: 0.9rem;
    color: #222;
    flex-wrap: wrap;
`;
