import { useContext, useState } from 'react';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../../components/common/CommonButton';
import { Icon } from '@iconify/react/dist/iconify.js';

// Context
import { ProfileContext } from '../../../../../contexts/profile/ProfileProvider';

const ItemPicker = (props) => {
    const { items, property } = props;

    const {
        user,
        userLoading,
        handleUpdateProfile,
        currentProperty,
        setCurrentProperty,
    } = useContext(ProfileContext);

    const [editingItems, setEditingItems] = useState(false);
    const [checkedOptions, setCheckedOptions] = useState([]);

    const loadingCurrentProperty =
        currentProperty === property.formatted && userLoading;

    const removeItem = (id) => {
        if (!userLoading) {
            let newvalues = user[property?.formatted].filter((x) => x !== id);
            setCurrentProperty(property.formatted);
            handleUpdateProfile({ [property?.notFormatted]: newvalues });
        }
    };

    const userItems = items.filter((item) =>
        user[property?.formatted].some(
            (userItemID) => userItemID?.id === item?.id
        )
    );

    const otherItems = items.filter(
        (item) =>
            !user[property?.formatted].some(
                (userItemID) => userItemID?.id === item?.id
            )
    );

    const onlyIDs = user[property?.formatted].map((item) => item?.id);

    const addMultipleItems = () => {
        setEditingItems(false);
        setCurrentProperty(property.formatted);
        handleUpdateProfile({
            [property?.notFormatted]: [...onlyIDs, ...checkedOptions],
        });
    };

    const handleCheckboxChange = (option) => {
        setCheckedOptions((prev) =>
            prev.includes(option)
                ? prev.filter((item) => item !== option)
                : [...prev, option]
        );
    };

    return (
        <>
            <ChipsContainer>
                {userItems?.map((item) => {
                    return (
                        <Chip>
                            <label>{item?.name}</label>
                            <button onClick={() => removeItem(item?.id)}>
                                <FontAwesomeIcon icon="fa-light fa-circle-xmark" />
                            </button>
                        </Chip>
                    );
                })}
            </ChipsContainer>
            {editingItems ? (
                <ItemSelector>
                    {otherItems?.length === 0 ? (
                        <p>No hay más {property?.formatted} para agregar</p>
                    ) : (
                        <ItemsGrid>
                            {otherItems.map((item) => (
                                <label>
                                    <RelativeDiv>
                                        <HiddenCheckbox
                                            type="checkbox"
                                            value={item?.id}
                                            checked={checkedOptions.includes(
                                                item?.id
                                            )}
                                            onChange={() =>
                                                handleCheckboxChange(item?.id)
                                            }
                                        />
                                        <StyledCheckbox
                                            checked={checkedOptions.includes(
                                                item?.id
                                            )}
                                        >
                                            {checkedOptions.includes(
                                                item?.id
                                            ) && (
                                                <CheckIcon
                                                    icon="material-symbols:check"
                                                    width="18px"
                                                    height="18px"
                                                />
                                            )}
                                        </StyledCheckbox>
                                    </RelativeDiv>
                                    {item?.name}
                                </label>
                            ))}
                        </ItemsGrid>
                    )}
                    <ButtonsContainer>
                        <CommonButton
                            variant="outlined"
                            label="Cancelar"
                            onClick={() => setEditingItems(false)}
                        />
                        {otherItems?.length !== 0 && (
                            <CommonButton
                                variant="filled"
                                label="Guardar"
                                onClick={() => addMultipleItems()}
                                disabled={userLoading}
                            />
                        )}
                    </ButtonsContainer>
                </ItemSelector>
            ) : (
                <CommonButton
                    variant="filled"
                    label={
                        loadingCurrentProperty
                            ? 'Guardando...'
                            : `Agregar ${property?.notFormatted}`
                    }
                    Icon={() => (
                        <Icon
                            icon="bi:plus-circle"
                            width="24px"
                            height="24px"
                        />
                    )}
                    onClick={() => setEditingItems(true)}
                    disabled={loadingCurrentProperty}
                />
            )}
        </>
    );
};

export default ItemPicker;

const ChipsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

const Chip = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 8px 8px 8px 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #616161;
    border-radius: 100px;

    label {
        font-size: 16px;
    }

    button {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
`;

const ItemSelector = styled.div`
    width: 550px;
    height: fit-content;
    max-height: 300px;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1rem;
    background-color: #fff;

    p {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }

    @media (width < 768px) {
        width: calc(100% - 4rem);
    }
`;

const ItemsGrid = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    overflow-y: auto;

    @media (width < 768px) {
        grid-template-columns: 1fr;
    }

    label {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
    }
`;

const RelativeDiv = styled.div`
    position: relative;
    padding: 8px;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

const StyledCheckbox = styled.div`
    cursor: pointer;
    width: 14px;
    height: 14px;
    border: 2px solid ${({ checked }) => (checked ? '#B31D15' : '#616161')};
    border-radius: 3px;
    background-color: ${(props) => (props.checked ? '#B31D15' : '#ffffff')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
`;

const CheckIcon = styled(Icon)`
    color: white;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (width < 768px) {
        flex-direction: column;
    }
`;
