

export const announcementsBySubjectAdapter = (announcements) => {
    const mappedAnnouncements =
        announcements?.map((announcement) => ({
            id: announcement?.id,
            title: announcement?.title,
            active: announcement?.active,
            message: announcement?.message,
            createDate: announcement?.create_date,
            expirationDate: announcement?.expiration_date,
            publishedDate: announcement?.published_date,
            authorName: announcement?.newsletter_creator?.name,
            authorId: announcement?.newsletter_creator?.id,
            authorRole: announcement?.newsletter_creator?.newsletter_creator_role,
            authorImage: announcement?.newsletter_creator?.image,
            category: announcement?.newsletter_category,
            isTeacherNewsletter: announcement?.is_teacher_newsletter,
        })) || [];
    return {
        announcementsList: mappedAnnouncements,
    };
};

export const announcementsGenearalAdapter = (listAnnouncements) => {
    const restructuredList = listAnnouncements?.map(
        (list) =>
            ({
                subjectId: list?.subjectId,
                subjectName: list?.subjectName,
                newsletters:
                    list?.newsletters?.map((announcement) => ({
                        id: announcement?.id,
                        title: announcement?.title,
                        active: announcement?.active,
                        message: announcement?.message,
                        createDate: announcement?.create_date,
                        expirationDate: announcement?.expiration_date,
                        publishedDate: announcement?.published_date,
                        authorName: announcement?.newsletter_creator?.name,
                        authorId: announcement?.newsletter_creator?.id,
                        authorRole: announcement?.newsletter_creator?.newsletter_creator_role,
                        authorImage: announcement?.newsletter_creator?.image,
                        category: announcement?.newsletter_category,
                        isTeacherNewsletter: announcement?.is_teacher_newsletter,
                    })) || [],
            } || [])
    );
    return { restructuredList };
};
