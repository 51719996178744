import React from 'react';
import CommonChip from '../../../../components/common/CommonChip';

const StateProgramChip = (props) => {
    const { enrollmentState } = props;

    const baseChipProps = {
        variant: 'subjectState',
    };

    const getChipProps = (state) => {
        if (!!state) {
            switch (state?.toLowerCase()) {
                case 'preinscripto':
                    return {
                        label: 'Preinscripto',
                        strongColor: '#B75E26',
                        lightColor: '#FBF0EA',
                    };
                case 'confirmado':
                    return {
                        label: 'Habilitado',
                        strongColor: '#B75E26',
                        lightColor: '#FBF0EA',
                    };
                case 'alta_bienvenida':
                    return {
                        label: 'Habilitado',
                        strongColor: '#B75E26',
                        lightColor: '#FBF0EA',
                    };
                case 'por_iniciar':
                    return {
                        label: 'Por iniciar',
                        strongColor: '#C29F43',
                        lightColor: '#F9F5EC',
                    };
                case 'cursando':
                    return {
                        label: 'En curso',
                        strongColor: '#5E80DB',
                        lightColor: '#F7F8FD',
                    };
                case 'cursado_completo':
                    return {
                        label: 'Cursado completo',
                        strongColor: '#1E8065',
                        lightColor: '#F7FDFB',
                    };
                case 'investigacion_doctoral':
                    return {
                        label: 'Investigación doctoral',
                        strongColor: '#5E80DB',
                        lightColor: '#F7F8FD',
                    };
                case 'gestion_titulo':
                    return {
                        label: 'Gestionando título',
                        strongColor: '#1E8065',
                        lightColor: '#F7FDFB',
                    };
                case 'finalizado':
                    return {
                        label: 'Finalizado',
                        strongColor: '#1E8065',
                        lightColor: '#F7FDFB',
                    };
                case 'egresado':
                    return {
                        label: 'Egresado',
                        strongColor: '#1E8065',
                        lightColor: '#F7FDFB',
                    };
                case 'cursado_suspendido':
                    return {
                        label: 'Cursado suspendido',
                        strongColor: '#B31D15',
                        lightColor: '#FFF5F5',
                    };
                case 'en_pausa':
                    return {
                        label: 'En pausa',
                        strongColor: '#B31D15',
                        lightColor: '#FFF5F5',
                    };
                case 'inactivo':
                    return {
                        label: 'Inactivo',
                        strongColor: '#B31D15',
                        lightColor: '#FFF5F5',
                    };
                case 'descartar':
                    return {
                        label: 'Descartado',
                        strongColor: '#B31D15',
                        lightColor: '#FFF5F5',
                    };
                case 'ic':
                    return {
                        label: 'In company',
                        strongColor: '#B31D15',
                        lightColor: '#FFF5F5',
                    };
                case 'DEMO':
                    return {
                        label: 'DEMO',
                        strongColor: '#8D51E6',
                        lightColor: '#F7F0FF',
                    };
                default:
                    return {
                        label: state,
                        strongColor: '#000',
                        lightColor: '#FFF',
                    };
            }
        }
    };
    const chipProps = getChipProps(enrollmentState);
    return (
        <div>
            <CommonChip {...baseChipProps} {...chipProps} />
        </div>
    );
};
export default StateProgramChip;
