import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { Text } from '../../modules/acropolisCommon/components/Texts';
import Carousel, { consts } from 'react-elastic-carousel';
import FontAwesomeIcon from './FontAwesomeIcon';
import NoResultsFound from './NoResultsFound';
import SharedResource from './SharedResource';

// Material UI
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

const breakPoints = [
    { width: 768, itemsToShow: 1 },
    { width: 1024, itemsToShow: 2 },
    { width: 1280, itemsToShow: 3 },
    { width: 1440, itemsToShow: 4 },
];

const GenericSlider = (props) => {
    const {
        array = false,
        card: Card,
        to,
        title,
        buttonTitle = 'Ver más',
        type = 'common',
        type_card,
    } = props;

    // REDUX
    /* const primaryColor = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    ); */

    // STATES
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    // HOOKS
    /* const { primary } = useTheme(); */
    const carouselRef = useRef(null);

    // FUNCTIONS
    const handleNextArrow = () => {
        carouselRef.current.slideNext();
    };

    const handlePrevArrow = () => {
        carouselRef.current.slidePrev();
    };

    if (array === false) {
        return (
            <NoResultsFound
                message={'No hay resultados en: ' + title.toUpperCase()}
            />
        );
    }

    function handleOpenModal(id, nombre_publicacion) {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: type_card,
            type: 'recurso',
            id,
            nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    return (
        <SliderCampusContainer>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            {/* Header */}
            <OptionsWrapper>
                {/* <Link to={to}>
                    <ChipTitle primaryColor={primaryColor}>{title}</ChipTitle>
                </Link> */}
                <SliderTitle fontSize="20px" fontWeight="700" color="#B31D15">
                    {title}
                </SliderTitle>
                <ArrowsWrapper>
                    <FakeLink to={to}>
                        <RedirectButton
                            variant="text"
                            color="tertiary"
                            size="small"
                        >
                            {buttonTitle}
                        </RedirectButton>
                    </FakeLink>
                    <ButtonsWrapper>
                        <FakeIconButton
                            variant="text"
                            size="small"
                            color="tertiary"
                            onClick={handlePrevArrow}
                        >
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-left"
                                size={16}
                            />
                        </FakeIconButton>
                        <FakeIconButton
                            variant="text"
                            size="small"
                            color="tertiary"
                            onClick={handleNextArrow}
                        >
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-right"
                                size={16}
                            />
                        </FakeIconButton>
                    </ButtonsWrapper>
                </ArrowsWrapper>
            </OptionsWrapper>
            {/* Body */}
            {!!array ? (
                <FakeCarousel
                    length={array.length}
                    breakPoints={type === 'programs' ? 0 : breakPoints}
                    ref={carouselRef}
                    itemPadding={[8]}
                    itemPosition={consts.START}
                >
                    {/* @TODO - Revisar para hacer mas generico esto */}
                    {array.random().map((item, index) => {
                        return !!item?.type ? (
                            item.type === 'asignatura' ? null : (
                                <Card
                                    handleOpenModal={handleOpenModal}
                                    value={item}
                                    values={item}
                                    key={index}
                                    index={index}
                                />
                            )
                        ) : (
                            <>
                                {!!type_card ? (
                                    <WrapperCards>
                                        <Card
                                            handleOpenModal={handleOpenModal}
                                            value={item}
                                            values={item}
                                            key={index}
                                            index={index}
                                        />
                                    </WrapperCards>
                                ) : (
                                    // <WrapperCards>
                                    <Card
                                        handleOpenModal={handleOpenModal}
                                        value={item}
                                        values={item}
                                        key={index}
                                        index={index}
                                    />
                                    // </WrapperCards>
                                )}
                            </>
                        );
                    })}
                </FakeCarousel>
            ) : (
                <FakeCarousel
                    length={4}
                    breakPoints={type === 'programs' ? 0 : breakPoints}
                    ref={carouselRef}
                    itemPadding={[8]}
                >
                    {[1, 1, 1, 1].map(() => (
                        <SkeletonFake
                            sx={{ bgcolor: 'grey.900' }}
                            variant="rectangular"
                            width={'100%'}
                            height={350}
                        />
                    ))}
                </FakeCarousel>
            )}
        </SliderCampusContainer>
    );
};

export default GenericSlider;

const SliderCampusContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1260px) {
        row-gap: 0rem;
    }
`;

const OptionsWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const SliderTitle = styled(Text)`
    position: absolute;
    left: 1rem;
    top: 0;
`;

const ArrowsWrapper = styled.div`
    display: flex;
    column-gap: 1.2rem;

    @media (max-width: 1260px) {
        align-items: center;
        column-gap: 0.5rem;
        margin-right: 1rem;
        margin-top: 1rem;
    }

    @media (max-width: 1336px) {
        display: none;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const FakeLink = styled(Link)`
    display: inline-flex;
    @media (max-width: 768px) {
        display: none;
    }
`;

const FakeIconButton = styled(IconButton)`
    background-color: ${(p) => p.color};
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    :hover {
        background-color: ${(p) => p.color};
        filter: brightness(1.2);
    }

    svg {
        width: 20px;
        height: 20px;
        color: #616161;
    }

    @media (max-width: 1260px) {
        width: 16px;
        height: 16px;
    }
`;

const FakeCarousel = styled(Carousel)`
    .rec.rec-arrow {
        display: none;
        visibility: hidden;
    }

    .rec.rec-pagination {
        display: none;
        visibility: hidden;
    }
`;

const SkeletonFake = styled(Skeleton)`
    border-radius: 20px;
`;

const WrapperCards = styled.div`
    width: 480px;
    max-width: 100%;
`;

const RedirectButton = styled(Button)`
    width: 120px;
    font-size: 17px;
    border-radius: var(--border-radius_rounded);

    @media (max-width: 1260px) {
        font-size: 14px;
    }
`;
