import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Components
import LazyImg from '../../../../components/common/LazyImg';
import { Text } from '../../../../components/common/Texts';
import Slider from '../../../../components/common/Slider';
import CourseCard from './CourseCard';

// Material UI
import { Button } from '@mui/material';

// Assets
import NoCoursesFound from '../../../../assets/media/campus/no-course_found.png';

// Hooks
import useFetch from '../../../../hooks/useFetch';

// Adapters
import { coursesAdapter } from '../../adapters';

// Redux
import { getSubjectsP100 } from '../../../../redux/api/subjects';
import { useSelector } from 'react-redux';

const MyCoursesSlider = ({ title = 'Cursos' }) => {
    const repoId = useSelector((state) => state?.auth?.user?.repo_id);

    const { data, loading } = useFetch({
        asyncFn: () => getSubjectsP100(repoId),
        adapter: coursesAdapter,
    });

    const [coursesP100, setCoursesP100] = useState(null);

    useEffect(() => {
        !!data && setCoursesP100(data);

        return () => {};
    }, [data]);

    if (loading || !coursesP100) {
        return (
            <Wrapper>
                <SliderTitle fontSize="20px" fontWeight="700" color="#B31D15">
                    {title}
                </SliderTitle>
                <Skeleton h="440px" w="100%" />
            </Wrapper>
        );
    }

    if (!coursesP100?.enrollments?.length) {
        return (
            <Wrapper>
                <CardFake>
                    <NotFoundWrapper>
                        <Text color="#616161" fontSize="15px" fontWeight="500">
                            Aún no te encuentras matriculado en ningún curso.
                        </Text>
                        <LazyImg
                            src={NoCoursesFound}
                            width="300px"
                            height="fit-content"
                            backgroundColor="#ffffff"
                        />
                    </NotFoundWrapper>
                </CardFake>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <CardFake>
                {coursesP100?.enrollments?.length <= 4 && (
                    <NotSliderWrapper>
                        <SliderTitle
                            fontSize="20px"
                            fontWeight="700"
                            color="#B31D15"
                        >
                            {title}
                        </SliderTitle>
                        <ButtonWrapper>
                            <Link to={'/mi-progreso?tab=mis-cursos'}>
                                <RedirectButton variant="text" color="tertiary">
                                    Ver más
                                </RedirectButton>
                            </Link>
                        </ButtonWrapper>
                        <Content>
                            <CardsWrapper>
                                {coursesP100?.enrollments.map((course) => (
                                    <CourseCard
                                        key={course?.id}
                                        value={course}
                                    />
                                ))}
                            </CardsWrapper>
                            <FillImageWrapper>
                                <LazyImg
                                    src={NoCoursesFound}
                                    width="320px"
                                    height="fit-content"
                                    backgroundColor="#ffffff"
                                />
                            </FillImageWrapper>
                        </Content>
                    </NotSliderWrapper>
                )}

                {coursesP100?.enrollments?.length > 4 && (
                    <Slider
                        array={coursesP100?.enrollments}
                        card={CourseCard}
                        to={'/mi-progreso?tab=mis-cursos'}
                        title={title}
                    />
                )}
            </CardFake>
        </Wrapper>
    );
};

export default MyCoursesSlider;

const Wrapper = styled.div``;

const CardFake = styled.div`
    position: relative;
    width: calc(100% - 48px);
    box-shadow: var(--shadow-strong);
    border-radius: var(--border-radius_sharp);
    background-color: #fff;
    padding: 32px 24px;
`;

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

const SliderTitle = styled(Text)`
    padding: 1rem 1.5rem 1.4rem 1.5rem;
    position: absolute;
    left: 0;
    top: 0;
`;

const NotFoundWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    p {
        text-align: center;
        width: 250px;
    }
`;

const RedirectButton = styled(Button)`
    width: 120px;
    border-radius: var(--border-radius_rounded);
`;

const NotSliderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CardsWrapper = styled.div`
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const FillImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        display: none;
    }
`;
