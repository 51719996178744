import { CustomChip } from '../../../../styled-components';
import styled from 'styled-components';
import React from 'react';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import { Text } from '../../../../components/common/Texts';

const SubjectInfoCard = ({ lastOpenedSubjectData }) => {
    const {
        subjectState: { toStart, inProgress, finished },
        name,
    } = lastOpenedSubjectData;

    return (
        <Wrapper>
            <Header>
                <Text color="#ffffff" fontSize="16px" fontWeight="700">
                    ASIGNATURA <br /> <span>{name}</span>
                </Text>
                <Text color="#ffffff" fontSize="16px" fontWeight="500">
                    Avance de los módulos:
                </Text>
            </Header>
            <Body>
                <CustomChip
                    icon={
                        <FontAwesomeIcon icon="fa-regular fa-check" size={24} />
                    }
                    color="tertiary"
                    bgColor="#F7F8FD"
                    svgColor="#5E80DB"
                    label={
                        <>
                            <BoldNumber>{inProgress ?? 0}</BoldNumber> En curso
                        </>
                    }
                    width="190px"
                    variant="outlined"
                />
                <CustomChip
                    icon={
                        <FontAwesomeIcon icon="fa-light fa-clock" size={24} />
                    }
                    color="tertiary"
                    bgColor="#F9F5EC"
                    svgColor="#C29F43"
                    label={
                        <>
                            <BoldNumber>{toStart ?? 0}</BoldNumber> Por iniciar
                        </>
                    }
                    width="190px"
                    variant="outlined"
                />
                <CustomChip
                    icon={
                        <FontAwesomeIcon
                            icon="fa-light fa-graduation-cap"
                            size={20}
                        />
                    }
                    color="tertiary"
                    bgColor="#F7FDFB"
                    svgColor="#1E8065"
                    label={
                        <>
                            <BoldNumber>{finished ?? 0}</BoldNumber> Finalizados
                        </>
                    }
                    width="190px"
                    variant="outlined"
                />
            </Body>
        </Wrapper>
    );
};

export default SubjectInfoCard;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 1px solid #ffffff;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 1440px) {
        width: 100%;
        height: 305px;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        border: none;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
`;

const Header = styled.section`
    box-sizing: border-box;
    margin-top: 0.3rem;
    padding: 0 0.8rem;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
        font-weight: 900;
        line-height: 20px;
    }

    p {
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0;
    }

    @media (max-width: 1440px) {
        margin-top: 0.3rem;
        padding: 0 0.4rem;
        height: 60px;
        font-size: 14px !important;

        span {
            font-weight: 900;
            line-height: 20px;
            font-size: 14px !important;
        }

        p {
            font-size: 14px !important;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin: 0;
        }
    }
`;

const Body = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 220px;
`;

const BoldNumber = styled.span`
    font-weight: bold;
`;
