import axios from 'axios';

export const postExamService = async (exam) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/corregir_test`;

    try {
        const response = await axios.post(URL, exam);

        return response.data;
    } catch (error) {
        return error;
    }
};

export const postWatchReview = async (reviewData) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/watch_results`;

    try {
        const response = await axios.post(URL, reviewData);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const patchNewAttempt = async (examId, data) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/${examId}`;
    try {
        const response = await axios.patch(URL, data);
        return response.data;
    } catch (error) {
        return error;
    }
};
