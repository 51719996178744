import styled from 'styled-components';
import EllipseImg from '../../../../../assets/media/aden/ellipse.png';
import PericlesImg from '../../../../../assets/media/aden/pericles-outlined-red.png';
import CommonButton from '../../../../../components/common/CommonButton';

const ModalStep = (props) => {
    const { title, prevStep, nextStep, step, onClose, BodyComponent } = props;

    return (
        <ModalContainer>
            <Ellipse src={EllipseImg} alt="Ellipse" height={50} />
            <Header>
                <h2>{!!title && title}</h2>
                <div>
                    <span>
                        <b>0{step}</b>/06
                    </span>
                    <img src={PericlesImg} alt="Pericles" />
                </div>
            </Header>
            <Body>
                <BodyComponent />
            </Body>
            <Footer>
                {step !== 1 ? (
                    <CommonButton
                        FontAwesomeIconName="fa-light fa-arrow-left"
                        variant="text"
                        onClick={prevStep}
                    />
                ) : (
                    <div style={{ width: 44 }}></div>
                )}
                <CommonButton
                    label="Cerrar ventana"
                    variant="text"
                    onClick={onClose}
                />
                <CommonButton
                    FontAwesomeIconName="fa-light fa-arrow-right"
                    variant="text"
                    onClick={nextStep}
                />
            </Footer>
        </ModalContainer>
    );
};

export default ModalStep;

const ModalContainer = styled.div`
    position: relative;
    height: calc(80vh);
    width: calc(120vh - 4rem);
    background: #fff;
    border-radius: 50px;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    overflow: hidden;

    &:focus-visible {
        outline: none;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    color: #222;

    h2 {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1rem;

        span {
            font-size: 24px;
            color: #616161;

            b {
                color: #b31d15;
                font-weight: 900;
            }
        }
    }
`;

const Body = styled.div`
    width: 100%;
    height: 80%;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    button {
        border-radius: 100px;
        color: #a8a8a8;
        background-color: transparent;
        padding: 12px;

        &:hover {
            outline: 1px solid #616161;
            color: #616161;
            background-color: #e5e5e5;
        }
    }
`;

const Ellipse = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
`;
