import React, { useContext } from 'react';
import {
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Text } from '../../../../components/common/Texts';

const RecommendedProgramsModal = (props) => {
    const { onClose, open, programs, programName } = props;

    return (
        <CustomModal onClose={onClose} open={open}>
            <Container>
                <CustomIconButton onClick={onClose}>
                    <Close />
                </CustomIconButton>
                <Header>
                    <Text fontSize="15px" fontWeight="600">
                        ORDEN DE CURSADO SUGERIDO
                    </Text>
                    <Text fontSize="24px" fontWeight="700" color="#B31D15">
                        {programName}
                    </Text>
                </Header>
                <Body>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>Orden de cursado</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Programa</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Programas correlativos</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {programs?.map((program, i) => (
                                    <TableRow key={program?.id}>
                                        <CustomTableCell>
                                            <b
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '13.5px',
                                                }}
                                            >
                                                {i + 1}°
                                            </b>
                                        </CustomTableCell>
                                        <TableCell>
                                            <p
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '13.5px',
                                                }}
                                            >
                                                {program?.name}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            {!program?.correlativeSubprograms
                                                ?.length && (
                                                <p
                                                    style={{
                                                        fontWeight: '600',
                                                        fontSize: '13.5px',
                                                    }}
                                                >
                                                    No posee correlativas
                                                </p>
                                            )}

                                            {!!program?.correlativeSubprograms
                                                ?.length && (
                                                <CorrelativesContainer>
                                                    {program?.correlativeSubprograms.map(
                                                        (correlative) => (
                                                            <CorrelativeWrapper>
                                                                {correlative?.completed ? (
                                                                    <Icon
                                                                        fontSize={
                                                                            24
                                                                        }
                                                                        color="#28A986"
                                                                        fontWeight={
                                                                            600
                                                                        }
                                                                        icon="simple-line-icons:check"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        icon="mdi-light:clock"
                                                                        fontSize={
                                                                            24
                                                                        }
                                                                        color="#A8A8A8"
                                                                        fontWeight={
                                                                            600
                                                                        }
                                                                    />
                                                                )}
                                                                <Text
                                                                    fontSize="13px"
                                                                    fon14pxtWeight="600"
                                                                    color={
                                                                        correlative?.completed
                                                                            ? '#28A986'
                                                                            : '#A8A8A8'
                                                                    }
                                                                >
                                                                    {
                                                                        correlative?.name
                                                                    }
                                                                </Text>
                                                            </CorrelativeWrapper>
                                                        )
                                                    )}
                                                </CorrelativesContainer>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Body>
            </Container>
        </CustomModal>
    );
};

export default RecommendedProgramsModal;

const CustomModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    width: 1200px;
    min-height: 450px;
    padding: 40px 32px 32px 32px;
    border-radius: 20px;
    background-color: #ffffff;
    outline: none;
    max-height: 90vh;
    overflow-y: auto;
`;

const CustomIconButton = styled(IconButton)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
`;

const Body = styled.div`
    width: 100%;
`;

const CustomTableCell = styled(TableCell)`
    padding: 0 0 0 4.5rem;
`;

const CorrelativesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CorrelativeWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    @media screen and (max-width: 425px) {
        p {
            font-size: 13px;
        }
    }
`;
