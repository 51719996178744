import { useState, useEffect } from "react";
import {
    getSubjectDirectories,
    /* getSubjectPaginate, */
} from "../../redux/api/subjects";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";
import { studentAdapter } from "../../adapters/student.adapter";

const useStudentsCourse = (id) => {

    const [openSnackbar] = useSnackbar(optionsStyle);

    const [payload, setPayload] = useState(null);
    const [students, setStudents] = useState(null);
    const [allStudents, setAllStudents] = useState(null);
    const [offset, setOffset] = useState(0);
    const [viewLoading, setViewLoading] = useState(false);
    /* const [noMore, setNoMore] = useState(false); */
    const [tag, setTag] = useState("todos");

    useEffect(() => {
        setOffset(0);
        !payload &&
            setPayload({
                subject: id,
                query: {
                    page_size: 50,
                    page_offset: offset,
                    filters: {
                        name: "",
                        country_id: 0,
                        programa_id: 0,
                        contacto_empresa: "",
                        contacto_cargo_actual: "",
                        intereses_ids: [""],
                        id: 0,
                        estado_matriculacion: ""
                    },
                },
            });
        !!payload && getStudents(payload, "first");
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload, tag]);

    const getStudents = async (payload, mode) => {
        if (mode === "first") {
            setStudents(null);
            setOffset(0);
        }
        const request = await getSubjectDirectories({ ...payload, idp_filter: tag });
        setViewLoading(false);

        if (request.error) {
            openSnackbar("¡No se pudieron cargar los estudiantes!");
        } else {
            const data = request.data.response_data.map((student) => {
                return studentAdapter(student);
            })
            if (students === null) {
                setStudents(data);
            } else {
                /* data.length === 0
                    ? setNoMore(true)
                    : setNoMore(false); */
                
                let moreStudents = [...students];
                if (mode === "first") {
                    moreStudents = [];
                }
                data?.forEach((element) => {
                    moreStudents.push(element);
                });
                setStudents(moreStudents);
            }
            setAllStudents(data);
            setOffset((prevOffset) => prevOffset + 50);
        }
    };

    // funcion para cambiar los estudiantes
    const handleStudents = (students) => setStudents(students);

    const handleMoreStudents = () => {
        setViewLoading(true);
        getStudents({
            subject: id,
            query: {
                page_size: 50,
                page_offset: offset,
                filters: payload.query.filters,
            },
        }, "more");
    };

    return {
        allStudents,
        students,
        viewLoading,
        /* noMore, */
        payload,
        setPayload,
        handleStudents,
        handleMoreStudents,
        tag,
        setTag
    };
};

export default useStudentsCourse;
