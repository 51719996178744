import { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Skeleton } from '@mui/material';
import AnnouncementsSection from './AnnouncementsSection';
import { useEffect } from 'react';

const AnnouncementsGrid = (props) => {
    const { initialValues, setOpenEditModal, rol, loading } = props;

    const [newsletterFiltered, setNewsletterFiltered] = useState([]);

    useEffect(() => {
        if (initialValues.length !== 0) {
            setNewsletterFiltered(initialValues);
        }
    }, [initialValues]);

    const handleChange = (e) => {
        e.preventDefault();

        if (!e.target.value !== '') {
            setNewsletterFiltered(
                initialValues.filter((item) =>
                    item?.card?.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            return;
        }

        if (e.target.value.length === 0) {
            setNewsletterFiltered(initialValues);
            return;
        }
    };

    //RETURN
    if (loading) {
        <Wrapper>
            <Skeleton variant="rectangular" width={300} height={200} />
        </Wrapper>;
    }

    return (
        <Wrapper>
            <SearchWrapper>
                <SearchInput
                    onChange={handleChange}
                    placeholder="Buscar anuncio..."
                />
                <SearchIcon
                    icon="ic:twotone-search"
                    width="1.2em"
                    height="1.2em"
                />
            </SearchWrapper>
            <Container>
                {newsletterFiltered.length !== 0 ? (
                    newsletterFiltered
                        .sort(
                            (a, b) =>
                                new Date(b?.publishedDate) -
                                new Date(a?.publishedDate)
                        )
                        .map((item) => (
                            <AnnouncementsSection
                                subjectName={
                                    item?.general ? item?.subjectName : null
                                }
                                general={item?.general}
                                card={item?.card}
                                setOpenEditModal={setOpenEditModal}
                                rol={rol}
                                key={item?.key}
                            />
                        ))
                ) : (
                    <h1>No hay anuncios con este nombre</h1>
                )}
            </Container>
        </Wrapper>
    );
};

export default AnnouncementsGrid;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    height: fit-content;
    min-height: 856px;
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const SearchWrapper = styled.div`
    position: relative;
    width: 250px;
    height: 48px;
    background-color: #f9f9f9;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 1rem;
`;

const SearchInput = styled.input`
    width: 100%;
    height: 24px;
    color: black;
    background-color: transparent;
    border: 0px;
    padding: 5px;
    border-radius: 5px;
    width: 200px;
    outline: none;

    &::placeholder {
        color: #a8a8a8;
    }
`;

const SearchIcon = styled(Icon)`
    color: #a8a8a8;
    position: absolute;
    left: 12px;
`;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
