import React from 'react';
import styled from 'styled-components';

// Components
import NoResultsFound from '../../../components/common/NoResultsFound';

const EmptyGrid = (props) => {
    const { Card, propsValues } = props;
    // RETURN
    return (
        <>
            {propsValues.length === 0 ? (
                <NoResultsFound />
            ) : (
                <GridValues>
                    {propsValues.map((value, index) => (
                        <Card key={index} />
                    ))}
                </GridValues>
            )}
        </>
    );
};

export default EmptyGrid;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;

    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;
