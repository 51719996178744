import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import { Text } from '../../../../components/common/Texts';
import LastResourceSeen from './LastResourceSeen.js';
import SubjectInfoCard from './SubjectInfoCard.js';

// Hooks
import useFetch from '../../../../hooks/useFetch';

// Redux
import { getLastResourceSeen } from '../../../../redux/api/subjects';

// Adapters
import { lastResourceAdapter } from '../../adapters';

const SubjectResume = () => {
    const { repo_id: repoId } = useSelector((state) => state.auth.user);

    const { data: lastResource, loading } = useFetch({
        asyncFn: () => getLastResourceSeen(repoId),
        adapter: lastResourceAdapter,
    });

    if (loading || !lastResource) {
        return (
            <Wrapper>
                <CardWrapper>
                    <SkeletonLoading height={26} variant="rounded" />
                    <SkeletonLoading height={26} variant="rounded" />
                    <CardsContainer>
                        <SkeletonLoading height={295} variant="rounded" />
                        <SkeletonLoading height={295} variant="rounded" />
                    </CardsContainer>
                </CardWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <CardWrapper>
                <Text color="#FFF" fontSize="20px" fontWeight="700">
                    Mi progreso
                </Text>
                <Text color="#ffffff" fontSize="16px" fontWeight="700">
                    Continúa tu aprendizaje desde donde lo dejaste:
                </Text>
                <CardsContainer>
                    <LastResourceSeen
                        lastOpenedOAData={lastResource?.lastOpenedOAData}
                        subjectId={
                            lastResource?.lastOpenedSubjectData?.subjectId
                        }
                        resourceId={
                            lastResource?.lastOpenedOAData?.lastOpenedResource
                                ?.id
                        }
                        programId={
                            lastResource?.last_open_subject_data?.programId
                        }
                    />
                    <SubjectInfoCard
                        lastOpenedSubjectData={
                            lastResource?.lastOpenedSubjectData
                        }
                    />
                </CardsContainer>
            </CardWrapper>
        </Wrapper>
    );
};

export default SubjectResume;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 25px;

    /* @media (max-width: 1440px) {
        width: 100%;
    } */

    @media (max-width: 1024px) {
        margin-bottom: 40px;
    }
`;

const CardWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 30px;
    height: 500px;
    padding: 24px;
    z-index: 10;
    background: linear-gradient(#e51a1a, #b31d15);
    box-shadow:
        0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);

    p {
        margin-left: 10px;
    }

    @media (max-width: 1440px) {
        gap: 8px;
        max-width: 100%;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        height: fit-content;
    }
`;

const CardsContainer = styled.div`
    box-sizing: content-box;
    display: flex;
    gap: 16px;
    width: 100%;
    height: calc(100% - 45px);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;
