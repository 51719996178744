import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import MaterialThemeProvider from './assets/themes/MaterialThemeProvider';
import ScrollToTop from './utils/ScrollToTop';
import { LayoutSplashScreen } from './components/common/SplashScreen';
import { StyledEngineProvider } from '@mui/material';
import ThemeProvider from './modules/acropolisCommon/provider/ThemeProvider';
import SnackbarProvider from 'react-simple-snackbar';
import Routes from './Routes';
// import { SummitProvider } from './contexts/index'
import SetGlobalEmail from './SetGlobalEmail';
// import second from 'first'

export default function App({ store, persistor, basename }) {
    // comento esto por manqueada del fer
    // Desactiva el dom cuando no se esta visualizando
    // useDocVisible()

    return (
        /* Provide Redux store */
        <SnackbarProvider>
            <Provider store={store}>
                {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
                <PersistGate
                    persistor={persistor}
                    loading={<LayoutSplashScreen />}
                >
                    {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                    <Suspense fallback={<LayoutSplashScreen />}>
                        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                        <BrowserRouter basename={basename}>
                            <ScrollToTop />
                            {/* Tema por In Company */}
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider>
                                    {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                                    <MaterialThemeProvider>
                                        {/* Render routes with provided `Layout`. */}
                                        {/* <SummitProvider> */}
                                        <SetGlobalEmail />
                                        <Routes />
                                        {/* </SummitProvider> */}
                                    </MaterialThemeProvider>
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </BrowserRouter>
                    </Suspense>
                </PersistGate>
            </Provider>
        </SnackbarProvider>
    );
}
