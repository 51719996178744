import { Modal } from '@mui/material';
import styled from 'styled-components';
import ModalHome from './modalSteps/ModalHome';
import { useState } from 'react';
import ModalStep from './modalSteps/ModalStep';
import BodyStepOne from './modalSteps/body/BodyStepOne';
import BodyStepTwo from './modalSteps/body/BodyStepTwo';
import BodyStepThree from './modalSteps/body/BodyStepThree';
import BodyStepFour from './modalSteps/body/BodyStepFour';
import BodyStepFive from './modalSteps/body/BodyStepFive';
import BodyStepSix from './modalSteps/body/BodyStepSix';
import ModalFinal from './modalSteps/ModalFinal';
import ModalMobile from './ModalMobile';

const ModalInfoBanner = (props) => {
    const { open, onClose: onCloseModal } = props;

    const [step, setStep] = useState(0);

    const onClose = () => {
        setStep(0);
        onCloseModal();
    };

    const nextStep = () => {
        if (step === 7) return;
        setStep(step + 1);
    };

    const prevStep = () => {
        if (step === 0) return;
        setStep(step - 1);
    };

    const handleShowStep = () => {
        switch (step) {
            case 0:
                return (
                    <ModalHome
                        onClose={onClose}
                        nextStep={nextStep}
                        downloadStep={() => setStep(6)}
                    />
                );
            case 1:
                return (
                    <ModalStep
                        title="¡Comencemos!"
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepOne}
                    />
                );
            case 2:
                return (
                    <ModalStep
                        title="Home / Inicio"
                        IconTitle="fa-solid fa-house-blank"
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepTwo}
                    />
                );
            case 3:
                return (
                    <ModalStep
                        title="Cursado"
                        IconTitle="fa-solid fa-book-open"
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepThree}
                    />
                );
            case 4:
                return (
                    <ModalStep
                        title="Chat"
                        IconTitle="fa-solid fa-comment-dots"
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepFour}
                    />
                );
            case 5:
                return (
                    <ModalStep
                        title="Perfil"
                        IconTitle="fa-solid fa-user"
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepFive}
                    />
                );
            case 6:
                return (
                    <ModalStep
                        step={step}
                        onClose={onClose}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        BodyComponent={BodyStepSix}
                    />
                );
            case 7:
                return <ModalFinal onClose={onClose} prevStep={prevStep} />;
            default:
                return <ModalHome onClose={onClose} />;
        }
    };

    return (
        <EditedModal onClose={onClose} open={open}>
            <>
                <ShowMobile>
                    <ModalMobile onClose={onClose} />
                </ShowMobile>
                <ShowDesktop>{handleShowStep()}</ShowDesktop>
            </>
        </EditedModal>
    );
};

export default ModalInfoBanner;

const EditedModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ShowDesktop = styled.div`
    display: block;

    @media (width < 1068px) {
        display: none;
    }
`;

const ShowMobile = styled.div`
    display: none;

    @media (width < 1068px) {
        display: block;
    }
`;
