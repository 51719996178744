import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import EventCardTime from './eventCardBody/EventCardTime';
import Text from '../../../../components/common/Text';

const EventCardBody = (props) => {
    const {
        value,
        currentDate,
        eventDateEnd,
        formatEventDateBegin,
        formatEventDateEnd,
        formatCurrentDate,
        GetValidDate,
    } = props;

    return (
        <Body>
            <Text fontSize="1rem" fontWeight="700" component="h1">
                {value.publication_name}
            </Text>
            <EventInfoContainer>
                <DateWrapper>
                    <FontAwesomeIcon
                        icon="fa-light fa-calendar-day"
                        size={20}
                        color={'#b31d15'}
                    />
                    <Text
                        fontSize="0.9rem"
                        textColor="#616161"
                        component="span"
                    >
                        <GetValidDate />
                    </Text>
                </DateWrapper>
                <EventCardTime
                    value={value}
                    currentDate={currentDate}
                    eventDateEnd={eventDateEnd}
                    formatEventDateBegin={formatEventDateBegin}
                    formatEventDateEnd={formatEventDateEnd}
                    formatCurrentDate={formatCurrentDate}
                />
                {/* PARA CARD DE EVENTO PRESENCIAL */}
                {value.category.name !== 'Online' && value?.location.city && (
                    <LocationWrapper>
                        <LocationIcon>
                            <FontAwesomeIcon
                                icon="fa-light fa-location-dot"
                                size={20}
                                color={'#b31d15'}
                            />
                        </LocationIcon>
                        {value?.location ? (
                            <>
                                <LocationInfo>
                                    <Text
                                        fontSize="14px"
                                        fontWeight="500"
                                        textColor="#222"
                                    >
                                        {value?.location?.city}
                                    </Text>
                                    <Text
                                        fontSize="12px"
                                        fontWeight="400"
                                        textColor="#222"
                                    >
                                        {value?.location?.street}
                                    </Text>
                                </LocationInfo>
                            </>
                        ) : (
                            '-'
                        )}
                    </LocationWrapper>
                )}
            </EventInfoContainer>
        </Body>
    );
};

export default EventCardBody;

const Body = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    padding: 1rem;
`;

const EventInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    row-gap: 0.5rem;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #616161;
`;

const LocationWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const LocationIcon = styled.div``;

const LocationInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
`;
