import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { RouteOutlined } from '@mui/icons-material';

import FilterStudyPlan from '../ui/molecules/studyPlan/FilterStudyPlan';
import SubjectsCard from '../common/cards/SubjectsCard';
import NoResultsFound from './NoResultsFound';
import SimpleLoading from './SimpleLoading';
import useDocumentState from '../../hooks/students/useDocumentState';
import useStudentStatus from '../../pages/campus/hooks/useStundetStatus';
import DefaultSearch from './DefaultSearch';
import RecomendedPathModal from './modals/RecomendedPathModal';
import ElectiveSubjectsCard from './cards/ElectiveSubjectsCards';
import SubjectFooterButton from './cards/buttons/SubjectFooterButton';

const GridStudyPlan = (props) => {
    const {
        course,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        isChange,
        setIsFlag,
        isFlag,
        handleEnrollment,
    } = props;

    // STATES
    const [values, setValues] = useState(course);
    const [currentState, setCurrentState] = useState('Todos');
    const [search, setSearch] = useState('');
    const [openRecommendationModal, setOpenRecommendationModal] =
        useState(false);
    const { titulationState } = useDocumentState();
    const { statusStudentBlock } = useStudentStatus();

    //EFFECT
    useEffect(() => {
        setValues(course);
    }, [course]);

    useEffect(() => {
        setValues(
            course.filter((subjects) => {
                return subjects?.name
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase());
            })
        );
        setCurrentState('Todos');
    }, [search]);

    useEffect(() => {
        if (currentState === 'Todos') {
            setValues(course);
        }
        if (currentState === 'En curso') {
            setValues(
                course.filter(
                    (value) =>
                        value.progress < 100 &&
                        value.progress > 0 &&
                        value.state !== 'egresado'
                )
            );
        }
        if (currentState === 'Finalizado') {
            setValues(
                course.filter(
                    (value) =>
                        value.state === 'egresado' ||
                        (value.state === 'confirmado' &&
                            value.progress >= 100 &&
                            !(
                                value?.rework_activities ||
                                value?.pending_correction
                            ))
                )
            );
        }
        if (currentState === 'Por iniciar') {
            setValues(
                course.filter(
                    (value) =>
                        value.state === 'pendiente' || value.progress === 0
                )
            );
        }
    }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setIsChange(false);
        }, 300);
    }, [dataAssigment]);

    // FUNCTION
    const handleRecommendationModal = () => {
        setOpenRecommendationModal(!openRecommendationModal);
    };

    function handleCurrentState(newValue) {
        setCurrentState(newValue);
    }

    function handleChange(e) {
        setSearch(e.target.value);
    }

    // RETURN
    if (values === undefined) return <SimpleLoading />;
    return (
        <Container>
            <Header>
                <FilterWrapper>
                    <DefaultSearch
                        search={search}
                        handleChange={handleChange}
                        label="Buscar asignatura..."
                    />
                    <FilterStudyPlan
                        currentState={currentState}
                        handleCurrentState={handleCurrentState}
                    />
                </FilterWrapper>
                <SubjectFooterButton
                    variant="contained"
                    onClick={handleRecommendationModal}
                    title="Cursado sugerido"
                    startIcon={<RouteOutlined fontSize="large" />}
                />
            </Header>
            <SubjectsWrapper isChange={isChange} value={values?.length}>
                {values?.length === 0 ? (
                    <>
                        <NoResultWrapper>
                            <NoResultsFound />
                        </NoResultWrapper>
                    </>
                ) : (
                    <>
                        {isChange ? (
                            <>
                                <SimpleLoading />
                            </>
                        ) : (
                            <>
                                {values?.map((courses, index) =>
                                    !courses?.elective_subject ? (
                                        <SubjectsCard
                                            key={index}
                                            data={courses}
                                            statusStudentBlock={
                                                statusStudentBlock
                                            }
                                            setIsFlag={setIsFlag}
                                            isFlag={isFlag}
                                            setDataAssigment={setDataAssigment}
                                            dataAssigment={dataAssigment}
                                            setIsChange={setIsChange}
                                            titulationState={titulationState}
                                        />
                                    ) : (
                                        <ElectiveSubjectsCard
                                            key={index}
                                            data={courses}
                                            handleEnrollment={handleEnrollment}
                                        />
                                    )
                                )}
                            </>
                        )}
                    </>
                )}
            </SubjectsWrapper>
            <RecomendedPathModal
                open={openRecommendationModal}
                onClose={handleRecommendationModal}
                subjects={values}
            />
        </Container>
    );
};

export default GridStudyPlan;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 700px) {
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
    }
`;

const SubjectsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 0 8px;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`;

const NoResultWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;
    border-radius: 1rem;
`;

const FilterWrapper = styled.div`
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
`;
