import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const SubjectFooterButton = (props) => {
    const { variant, disabled, onClick, title, startIcon } = props;

    return (
        <SubjectButton
            variant={variant}
            disabled={disabled}
            onClick={onClick}
            startIcon={startIcon}
        >
            {title}
        </SubjectButton>
    );
};

export default SubjectFooterButton;

const SubjectButton = styled(Button)`
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    background: #cd2118;
`;
