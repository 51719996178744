import styled from 'styled-components';
import ProfileImage from '../../../../../assets/media/aden/profile-img-test.jpg';
import { validateAnnouncementDates } from '../../../../../helpers/newsletters.helper';
import { toTitleCase } from '../../../../../helpers/titleCase.helper';
import { Skeleton } from '@mui/material';

const AnnouncementCard = (props) => {
    const {
        subjectName,
        card,
        setOpenModal,
        isOpen,
        isGeneral = false,
        general,
        setOpenEditModal,
        type,
        rol,
    } = props;

    const announcementIsNew = validateAnnouncementDates(
        card?.publishedDate,
        card?.expirationDate,
        rol
    );

    const formattedMessage = card?.message.replace(/\n/g, '<br>');

    const editAnnouncementCard = () => {
        setOpenEditModal(card);
        setOpenModal(false);
    };

    if (!rol) {
        <Skeleton variant="rectangular" width={300} height={200} />;
    }
    return (
        <Container seen={announcementIsNew} isOpen={isOpen}>
            <Header>
                <AvatarImage alt="" src={card?.authorImage ?? ProfileImage} />
                <HeaderInfo seen={announcementIsNew} isGeneral={isGeneral}>
                    {general && (
                        <>
                            <Title>
                                ASIGNATURA: {subjectName?.toUpperCase()}
                            </Title>
                            <Line />
                        </>
                    )}
                    {type !== 'list' && rol === 'docente' && (
                        <EditButton onClick={editAnnouncementCard}>
                            Editar
                        </EditButton>
                    )}
                    <HeaderSubInfo rol={rol}>
                        <label>{card?.title}</label>
                        <span>{card?.publishedDate}</span>
                    </HeaderSubInfo>
                    <ProfessorInfo>
                        <span>{card?.authorName}</span>
                        <span>|</span>
                        <span>{toTitleCase(card?.authorRole)}</span>
                    </ProfessorInfo>
                </HeaderInfo>
            </Header>
            {isOpen ? (
                <p>
                    <div
                        dangerouslySetInnerHTML={{ __html: formattedMessage }}
                    />
                </p>
            ) : (
                <p>{card?.message}</p>
            )}

            {!isOpen && (
                <button onClick={() => setOpenModal(true)}>
                    Ver anuncio completo.
                </button>
            )}
        </Container>
    );
};

export default AnnouncementCard;

const Container = styled.div`
    width: 100%;
    height: ${(props) => (props.isOpen ? 'fit-content' : '190px')};
    box-shadow: 0px 8px 8px -4px #18274b14, 0px 4px 6px -4px #18274b1f;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    border-left: 8px solid ${(props) => (!props.seen ? '#F1F1F1' : '#b31d15')};
    background-color: #fff;

    p {
        padding: 0 ${(props) => (props.isOpen ? '0.5rem' : '1rem')};
        ${(props) =>
            !props.isOpen
                ? `display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;`
                : `margin-bottom: 1rem;`}
        font-weight: 400;
        font-size: 14px;
        color: #616161;
    }

    button {
        cursor: pointer;
        color: ${(props) => (!props.seen ? '#616161' : '#b31d15')};
        font-size: 14px;
        font-weight: 600;
        margin-left: 1rem;
    }

    @media screen {
        max-height: ${(props) => (props.isOpen ? '85vh' : '190px')};
        overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
    }
`;

const Header = styled.div`
    display: flex;
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 0;
    align-items: center;
`;

const AvatarImage = styled.div`
    background-image: url(${(props) => props.src});
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center center;
    border-radius: 100%;
`;

// ARREGLAR ACÁ

const HeaderInfo = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) =>
        !props.isGeneral
            ? 'center'
            : props.seen
            ? 'space-around'
            : 'space-between'};
    color: ${(props) => (props.seen ? '#a8a8a8' : '#222222')};
    gap: 4px;
    position: relative;
`;

const Title = styled.h2`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.15px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #222;
    margin-top: 5px;
`;

const HeaderSubInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        font-size: 15px;
        font-weight: 700;
    }

    span {
        font-size: 12px;
        font-weight: 700;
    }
`;

const ProfessorInfo = styled.div`
    display: flex;
    gap: 0.5rem;

    span {
        font-size: 12px;
        font-weight: 700;
    }
`;

const EditButton = styled.div`
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b31d15;
    width: 70px;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    position: absolute;
    top: -5px;
    right: 0px;
    color: #fafafa;
`;
