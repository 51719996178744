export const numeroALetras = (num) => {
    const unidades = [
        'cero',
        'uno',
        'dos',
        'tres',
        'cuatro',
        'cinco',
        'seis',
        'siete',
        'ocho',
        'nueve',
    ];
    const decenas = [
        'diez',
        'veinte',
        'treinta',
        'cuarenta',
        'cincuenta',
        'sesenta',
        'setenta',
        'ochenta',
        'noventa',
    ];
    const especiales = [
        'once',
        'doce',
        'trece',
        'catorce',
        'quince',
        'dieciséis',
        'diecisiete',
        'dieciocho',
        'diecinueve',
    ];

    if (num < 10) return unidades[num];
    if (num >= 10 && num < 20) return especiales[num - 11];
    if (num >= 20 && num < 100) {
        const unidad = num % 10;
        const decena = Math.floor(num / 10);
        return unidad === 0
            ? decenas[decena - 1]
            : `${decenas[decena - 1]} y ${unidades[unidad]}`;
    }
    return num.toString();
};
