import { useState } from 'react';
import { changeEnrollmentStatus } from '../services/api';

const usePauseAssignment = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newEnrollment, setNewEnrollment] = useState(false);

    const postEnrollmentChange = async (body) => {
        setLoading(true);
        setError(null);
        try {
            const response = await changeEnrollmentStatus(body);
            setLoading(false);
            setError(false);
            setNewEnrollment(response?.response_data?.changed_data);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    return { loading, error, postEnrollmentChange, newEnrollment };
};

export default usePauseAssignment;
