import React from 'react';
import styled from 'styled-components';
import { MyProgressProvider } from './contexts/MyProgressProvider';
import SubjectsBoard from './SubjectsBoard';

const StudyPlan = () => {
    return (
        <MyProgressProvider>
            <Wrapper>
                <ContentWrapper>
                    <SubjectsBoard />
                </ContentWrapper>
            </Wrapper>
        </MyProgressProvider>
    );
};

export default StudyPlan;

const Wrapper = styled.div`
    background-color: #f1f1f1;
    padding: 1rem 2rem;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
`;

const ContentWrapper = styled.div`
    width: 100%;
`;
