import React, { useContext, useState } from 'react';
import {
    BodySubjectsCard,
    ElectiveIcon,
    ElectiveIndications,
    ElectiveWrapper,
    Footer,
    SubjectCardContainer,
    TextWrapper,
} from '../../../styled-components/subjects/subjectsCards';
import LazyImg from '../LazyImg';
import { Text } from '../Texts';
import SubjectFooterButton from './buttons/SubjectFooterButton';
import ElectiveSubjectModal from '../../../pages/progress/components/ElectiveSubjectModal';
import electiveIcon from '../../../assets/media/svg/elective-icon.svg';
import electiveImage from '../../../assets/media/aden/asignatura-electiva.png';
import { MyProgressContext } from '../../../pages/progress/contexts/MyProgressProvider';

const ElectiveSubjectsCard = (props) => {
    const { data } = props;
    const { handleEnrollment, repoId } = useContext(MyProgressContext);

    const [openElectiveModal, setOpenElectiveModal] = useState(false);

    const handleElectiveModal = () => {
        setOpenElectiveModal(!openElectiveModal);
    };

    return (
        <>
            <SubjectCardContainer
                notAvailable={data?.assignment_without_information}
                title={data?.name}
                demoUnlocker={data?.cursado_demo}
            >
                <LazyImg src={electiveImage} width="100%" height="130px" />
                <BodySubjectsCard>
                    <ElectiveWrapper>
                        <Text fontSize="1rem" fontWeight="700" color="#B31D15">
                            {data?.name}
                        </Text>

                        <ElectiveIndications>
                            <ElectiveIcon src={electiveIcon} />
                            <TextWrapper>
                                {data?.subjects?.length ? (
                                    <>
                                        <Text
                                            fontSize="1rem"
                                            fontWeight="500"
                                            color="#B31D15"
                                        >
                                            Selección de electiva
                                        </Text>
                                        <Text
                                            fontSize="0.8rem"
                                            fontWeight="500"
                                            color="#616161"
                                        >
                                            Escoge la asignatura que deseas
                                            cursar.
                                        </Text>
                                    </>
                                ) : (
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="#616161"
                                    >
                                        Esperando la confirmación de asignaturas
                                        vigentes
                                    </Text>
                                )}
                            </TextWrapper>
                        </ElectiveIndications>
                    </ElectiveWrapper>
                </BodySubjectsCard>

                <Footer>
                    <SubjectFooterButton
                        variant="contained"
                        onClick={handleElectiveModal}
                        disabled={!data?.subjects?.length}
                        title="Seleccionar"
                    />
                </Footer>
            </SubjectCardContainer>
            {openElectiveModal && (
                <ElectiveSubjectModal
                    open={openElectiveModal}
                    onClose={handleElectiveModal}
                    subjects={data?.subjects}
                    handleEnrollment={handleEnrollment}
                    repo_id={repoId}
                />
            )}
        </>
    );
};

export default ElectiveSubjectsCard;
