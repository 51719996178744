import styled from 'styled-components';
import AdenTeAcompaña from '../../../../assets/media/aden/aden-te-acompaña-white.png';
import MobileMockup from '../../../../assets/media/aden/mobile-mockup.png';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';
import MockupStepOne from './modalSteps/assets/mockup-step-1.png';
import MockupStepTwo from './modalSteps/assets/mockup-step-2.png';
import MockupStepThree from './modalSteps/assets/mockup-step-3-1.png';
import MockupStepFour from './modalSteps/assets/mockup-step-4.png';
import MockupStepFive from './modalSteps/assets/mockup-step-5.png';

const ModalMobile = () => {
    const openLinkAppStore = () => {
        window.open(
            'https://apps.apple.com/us/app/aden-acr%C3%B3polis/id6737446349',
            '_blank'
        );
    };

    const openLinkPlayStore = () => {
        window.open(
            'https://play.google.com/store/apps/details?id=com.aden.acropolis&hl=es_419&pli=1',
            '_blank'
        );
    };

    return (
        <Container>
            <FirstInfo>
                <img src={AdenTeAcompaña} alt="AdenTeAcompaña" />
                <section>
                    <h1>APP ADEN ACRÓPOLIS</h1>
                    <h2>
                        tu <i>nueva forma</i> de estudiar.
                    </h2>
                </section>
                <h2>¿Qué podrás realizar en la nueva app?</h2>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Acceder a <b>todo el contenido de ADEN</b> de manera{' '}
                        <b>rápida y sencilla</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Consultar el <b>progreso de tus actividades</b> en
                        tiempo <b>real</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        Continuar tu formación <b>desde cualquier lugar</b>.
                    </span>
                </p>
                <p>
                    <FontAwesomeIcon
                        icon="fa-light fa-check"
                        size={24}
                        color="#fff"
                    />
                    <span>
                        <b>Participar en eventos exclusivos</b> y mucho más.
                    </span>
                </p>
                <img src={MobileMockup} alt="Mobile Mockup" />
                <Footer>
                    <h3>¡Disponible ahora!</h3>
                    <p>
                        Descarga nuestra app en Google Play y <br /> Apple Store
                    </p>
                    <span>
                        <CommonButton
                            label="Google Play Store"
                            variant="black&white"
                            FontAwesomeIconName="fa-brands fa-google-play"
                            onClick={openLinkPlayStore}
                        />
                        <CommonButton
                            label="App Store"
                            variant="black&white"
                            FontAwesomeIconName="fa-brands fa-apple"
                            onClick={openLinkAppStore}
                        />
                    </span>
                </Footer>
            </FirstInfo>
            <SecondInfo>
                <label>¡Comencemos!</label>
                <span>
                    <img src={MockupStepOne} alt="Mockup Step One" />
                    <label>Inicio de sesión</label>
                </span>
                <span>
                    <label>
                        <FontAwesomeIcon
                            icon="fa-solid fa-house-blank"
                            size={24}
                            color="#b31d15"
                        />
                        Home / Inicio
                    </label>
                    <img src={MockupStepTwo} alt="Mockup Step Two" />
                </span>
                <span>
                    <img src={MockupStepThree} alt="Mockup Step Three" />
                    <label>
                        <FontAwesomeIcon
                            icon="fa-solid fa-book-open"
                            size={24}
                            color="#b31d15"
                        />
                        Cursado
                    </label>
                </span>
                <span>
                    <label>
                        <FontAwesomeIcon
                            icon="fa-solid fa-comment-dots"
                            size={24}
                            color="#b31d15"
                        />
                        Chat
                    </label>
                    <img src={MockupStepFour} alt="Mockup Step Four" />
                </span>
                <span>
                    <img src={MockupStepFive} alt="Mockup Step Five" />
                    <label>
                        <FontAwesomeIcon
                            icon="fa-solid fa-user"
                            size={24}
                            color="#b31d15"
                        />
                        Perfil
                    </label>
                </span>
            </SecondInfo>
            <FinalFooter>
                <img src={AdenTeAcompaña} alt="AdenTeAcompaña" />
            </FinalFooter>
        </Container>
    );
};

export default ModalMobile;

const Container = styled.div`
    height: 90vh;
    width: 80vw;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
`;

const FirstInfo = styled.div`
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    color: #fff;
    border-radius: 0 0 50px 50px;

    h1 {
        font-size: 24px;
        font-weight: 700;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;

        i {
            font-size: 20px;
        }
    }

    p {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    h3 {
        font-size: 20px;
        font-weight: 700;
    }

    p {
        text-align: center;
    }

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
`;

const SecondInfo = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    padding: 3rem 1rem;
    color: #222;

    label {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    span {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
            width: 40%;
        }
    }
`;

const FinalFooter = styled.div`
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    min-height: 200px;

    img {
        width: 110%;
    }
`;
