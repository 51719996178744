import { useState } from 'react';
import {
    getRelatedPrograms,
    postAssignmentPrograms,
} from '../../../redux/api/studyPlan';
import { useSelector } from 'react-redux';
import {
    subprogramAdapter,
    subprogramPlusAdapter,
} from '../adapters/subprogram.adapter';

const useRelatedPrograms = () => {
    const [programPlusInfo, setProgramPlusInfo] = useState(null);
    const [errorRelatedPrograms, setErrorRelatedPrograms] = useState(null);
    const [loadingRelatedPrograms, setLoadingRelatedPrograms] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [programToEnroll, setProgramToEnroll] = useState(false);
    const [loadingFetch, setLoadingFetch] = useState(false);

    const { repo_id } = useSelector((state) => state?.auth?.user);

    const fetchRelatedPrograms = async (program_id, repo_id) => {
        setErrorRelatedPrograms(null);
        setLoadingRelatedPrograms(true);
        try {
            const response = await getRelatedPrograms(program_id, repo_id);
            setProgramPlusInfo(subprogramPlusAdapter(response));
            setErrorRelatedPrograms(false);
            setLoadingRelatedPrograms(false);
        } catch (error) {
            setErrorRelatedPrograms(true);
            setLoadingRelatedPrograms(false);
        }
    };

    const enrollRelatedProgram = async () => {
        try {
            setLoadingFetch(true);
            const response = await postAssignmentPrograms(repo_id, {
                type: 'programa',
                assignment: programToEnroll?.id,
            });
            setLoadingFetch(false);

            if (response?.error) {
                setOpenModalError(true);
                setOpenModal(false);
                return;
            }

            setOpenModalSuccess(true);
            setOpenModal(false);
        } catch (error) {
            setOpenModalError(true);
            setLoadingFetch(false);
        }
    };

    return {
        loadingRelatedPrograms,
        programPlusInfo,
        errorRelatedPrograms,
        programToEnroll,
        openModal,
        openModalSuccess,
        openModalError,
        loadingFetch,
        fetchRelatedPrograms,
        enrollRelatedProgram,
        setProgramToEnroll,
        setOpenModal,
        setOpenModalSuccess,
        setOpenModalError,
    };
};

export default useRelatedPrograms;
