import styled, { keyframes } from 'styled-components';
import { useContext, useState } from 'react';

// Components
import FontAwesomeIcon from '../../../../../components/common/FontAwesomeIcon';
import TextToEdit from '../../mainInfo/formationAndExperience/TextToEdit';

// Context
import { ProfileContext } from '../../../../../contexts/profile/ProfileProvider';

// Redux
import { authActions } from '../../../../../redux/actions';
import { useDispatch } from 'react-redux';

const AboutYou = () => {
    const dispatch = useDispatch();
    const { user, userLoading } = useContext(ProfileContext);

    const [profilePhoto, setProfilePhoto] = useState(user?.photo);
    const [form, setForm] = useState(null);

    function handleSubmit() {
        dispatch(
            authActions.updatePhotoRequest({
                repo_id: user.repoId,
                form: form,
                photo: profilePhoto,
            })
        );
        setForm(null);
    }

    // Manejador de cambio de archivo
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfilePhoto(e.target.result); // Actualiza la imagen de perfil
            };
            reader.readAsDataURL(file); // Lee el archivo como base64
            const form = new FormData();
            form.append('avatar', file);
            setForm(form);
        }
    };

    return (
        <AboutYouContainer formSaved={!!form}>
            <span>
                <img src={profilePhoto} alt="profile" />
                <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    disabled={userLoading || !!form}
                />
                <label
                    onClick={() => !!form && handleSubmit()}
                    htmlFor="file-input"
                    className="camera-icon"
                >
                    <h5>Guardar</h5>
                    <FontAwesomeIcon icon="fa-light fa-camera" />
                </label>
                {userLoading && <Loader />}
            </span>
            {/* Funciona mal sin el botón, ver de encontrar una solución o acomodar la UI */}
            {/* <button onClick={handleSubmit}>Guardar</button> */}
            <section>
                <span>Escribe una breve biografía</span>
                <TextToEdit
                    property={{
                        formatted: 'biography',
                        notFormatted: 'biography',
                    }}
                />
            </section>
        </AboutYouContainer>
    );
};

export default AboutYou;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #f3f3f3; /* Color de fondo del loader */
    border-top: 1px solid #b31d15; /* Color de la animación */
    border-radius: 50%; /* Hacerlo circular */
    width: 120px;
    height: 120px;
    animation: ${spin} 1s linear infinite; /* Animación continua */
    z-index: 90;
`;

const AboutYouContainer = styled.div`
    width: 100%;
    display: flex;
    gap: ${({ formSaved }) => (formSaved ? '2rem' : '1rem')};

    span {
        position: relative;
        width: fit-content;
    }

    img {
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        width: 120px;
        height: 120px;
        object-fit: cover;
    }

    label {
        cursor: pointer;
        width: ${({ formSaved }) => (formSaved ? 'fit-content' : '30px')};
        height: 30px;
        border-radius: 100px;
        border: 1px solid #b31d15;
        background-color: #fff;
        position: absolute;
        bottom: 1rem;
        right: ${({ formSaved }) => (!formSaved ? '.3rem' : '-1rem')};
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b31d15;
        box-shadow:
            0px 8px 8px -4px #18274b0a,
            0px 4px 6px -4px #18274b0a;
        z-index: 99;
        padding: ${({ formSaved }) => (formSaved ? '0 8px' : '0')};

        :hover {
            color: #fff;
            background-color: #b31d15;
            transition: all 0.3s;
        }
    }

    section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 8px;
        color: #000;

        p {
            max-height: 50px;
            overflow-y: auto;
        }
    }

    h5 {
        display: ${({ formSaved }) => (!formSaved ? 'none' : 'block')};
    }

    svg {
        display: ${({ formSaved }) =>
            formSaved ? 'none !important' : 'block'};
    }

    @media (width < 768px) {
        flex-direction: column;
    }
`;
