import styled from 'styled-components';
import MockupStepFour from '../assets/mockup-step-4.png';

const BodyStepFour = () => {
    return (
        <BodyContainer>
            <img src={MockupStepFour} alt="Mockup Step Four" />
            <Info>
                <span>
                    <h2>Chat</h2>
                    <p>
                        Tendrás acceso a la
                        <b> "Comunidad ADEN"</b>, un
                        <b> directorio con todos los contactos </b>
                        que se encuentran cursando, que tendrás filtros
                        incluidos, para facilitar tu búsqueda. Por otro lado,
                        estará el
                        <b> "Chat" </b>
                        que mostrará el
                        <b> historial completo de tus conversaciones</b>,
                        sincronizados con la web.
                    </p>
                </span>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepFour;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    height: 100%;

    img {
        margin-top: -1rem;
        height: 90%;
    }
`;

const Info = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    z-index: 2;
    color: #222;

    h2 {
        color: #b31d15;
    }

    span {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
