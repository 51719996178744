import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import Pagination from './announcements/Pagination';
import AnnouncementSkeleton from './announcements/AnnouncementSkeleton';
import NotFoundImg from '../../../../assets/media/aden/not_found.png';
import { announcementSchema } from '../../../../utils/schemas';
import PopUpSuccess from './announcements/PopUpSuccess';
import ModalCreateAnnouncement from './announcements/ModalCreateAnnouncement';
import useSubjectAnnouncements from '../../../../hooks/subject/useSubjectAnnouncements';
import AnnouncementsGrid from './announcements/AnnouncementsGrid';
import ErrorResponse from '../../../common/ErrorResponse';

const SubjectAnnouncements = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { id, professor } = props;

    const { repo_id } = useSelector((state) => state.auth.user);
    const { activeRol } = useSelector((state) => state.auth);

    const {
        announcementsData,
        loading,
        error,
        handleSubmitAnnouncement,
        handleSubmitAnnouncementEdited,
        handleAnnouncementUnpublishing,
        loadingAction,
        errorAction,
        page,
        setPage,
        pageMax,
        setOpenModal,
        openModal,
        initialValues,
        openEditModal,
        setOpenEditModal,
        openModalSuccess,
        setOpenModalSuccess,
        succesAction,
        resetData,
        setErrorAction
    } = useSubjectAnnouncements(id, activeRol, repo_id);

     //RETURN
     if (error) {
        return (
            <Wrapper>
                <ErrorResponse message="No se pudo acceder a la información de anuncios, intenta nuevamente en unos minutos" />
            </Wrapper>
        );
    }

    if (loading) {
        return (
            <Wrapper>
                <AnnouncementSkeleton />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {activeRol === 'docente' && (
                <WrapperTeacher>
                    <AnnouncementsTitle>
                        Crea un anuncio y mantén a tus alumnos informados.
                    </AnnouncementsTitle>
                    <Button onClick={() => setOpenModal(true)}>
                        <Icon
                            icon="foundation:megaphone"
                            width="1.5em"
                            height="1.5em"
                            style={{ color: '#fff' }}
                        />
                        Crear nuevo anuncio
                    </Button>
                </WrapperTeacher>
            )}

            {announcementsData && announcementsData.length > 0 ? (
                //existe al menos un anuncio
                <>
                    <AnnouncementsTitle>
                        Anuncios correspondientes a esta asignatura:
                    </AnnouncementsTitle>
                    <AnnouncementsGrid
                        initialValues={announcementsData}
                        setOpenEditModal={setOpenEditModal}
                        rol={activeRol}
                        loading={loading}
                    />
                    <Pagination
                        page={page}
                        setPage={setPage}
                        pageMax={pageMax}
                    />
                </>
            ) : (
                //No hay anuncios
                <WrapperNotFound>
                    <img src={NotFoundImg} alt="not-found" />
                    <p>Aún no tienes anuncios en <br /> esta asignatura.</p>
                </WrapperNotFound>
            )}

            {/* Acciones rol profesor  */}
            {/* Modal de éxito */}
            {openModalSuccess && !!succesAction && (
                <PopUpSuccess
                    setOpenModal={setOpenModalSuccess}
                    actionSucces={succesAction}
                    resetData={resetData}
                />
            )}
            {/* Creación de anuncio */}
            {openModal && (
                <ModalCreateAnnouncement
                    initialValues={initialValues}
                    handleSubmitAnnouncement={handleSubmitAnnouncement}
                    announcementSchema={announcementSchema}
                    setOpenModal={setOpenModal}
                    loading={loadingAction} 
                    error={errorAction}
                    type="create"
                    setErrorAction={setErrorAction}
                />
            )}
            {/* Edición de anuncio */}
            {openEditModal && (
                <ModalCreateAnnouncement
                    initialValues={openEditModal}
                    handleSubmitAnnouncementEdited={handleSubmitAnnouncementEdited}
                    handleAnnouncementUnpublishing={handleAnnouncementUnpublishing}
                    announcementSchema={announcementSchema}
                    setOpenModal={setOpenEditModal}
                    loading={loadingAction} 
                    error={errorAction}
                    type="edit"
                    setErrorAction={setErrorAction}
                />
            )}
        </Wrapper>
    );
};

export default SubjectAnnouncements;

const Wrapper = styled.div`
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 0.2rem;

    h1 {
        color: #616161;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }
`;

const WrapperTeacher = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
`;

const WrapperNotFound = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: calc(100% - 6rem);
    padding: 3rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }
    img {
        width: 300px;
    }
`;

const Button = styled.button`
    cursor: pointer;
    border-radius: 100px;
    background-color: #b31d15;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    :hover {
        background-color: #cd2118;
    }
`;

const AnnouncementsTitle = styled.h1`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #616161;
`;
