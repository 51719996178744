import styled from 'styled-components';

import FontAwesomeIcon from '../../../../../components/common/FontAwesomeIcon';
import Text from '../../../../../components/common/Text';
const EventCardTime = (props) => {
    const {
        value,
        currentDate,
        eventDateEnd,
        formatEventDateBegin,
        formatEventDateEnd,
        formatCurrentDate,
    } = props;

    const getHourNumber = (hour) => {
        return parseInt(hour.substring(0, 2));
    };

    const getMinuteNumber = (hour) => {
        return hour.substring(3, 5);
    };

    if (
        eventDateEnd >= currentDate ||
        formatEventDateBegin === formatCurrentDate ||
        formatEventDateEnd === formatCurrentDate
    ) {
        return (
            <DateWrapper>
                {!!value.category.name && (
                    <>
                        <FontAwesomeIcon
                            icon="fa-light fa-clock"
                            size={20}
                            color={'#b31d15'}
                        />
                        <Text
                            fontSize="0.9rem"
                            textColor="#616161"
                            component="span"
                        >
                            {value.category.name === 'Online'
                                ? `${value.hour_begin} (AR) | ${
                                      getHourNumber(value.hour_begin) - 2
                                  }:${getMinuteNumber(
                                      value.hour_begin
                                  )} (PA) | ${
                                      getHourNumber(value.hour_begin) - 3
                                  }:${getMinuteNumber(value.hour_begin)} (CR)`
                                : `${value?.hour_begin} - ${value?.hour_end} (Hora local)`}
                        </Text>
                    </>
                )}
            </DateWrapper>
        );
    }

    return null;
};

export default EventCardTime;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #616161;
`;
