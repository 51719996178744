import styled from "styled-components";

const CommonStateChip = (props) => {
	const { text, state, color, bgColor } = props;

	return (
		<ChipContainer state={state} color={color} bgColor={bgColor}>
			<ChipText state={state} color={color}>
				{text}
			</ChipText>
		</ChipContainer>
	);
};

export default CommonStateChip;

const getColor = (state, color) => {
	if (color) return color;
	switch (state) {
		case 1:
			return '#C29F43';
		case 2:
			return '#B75E26';
		case 3:
			return '#5E80DB';
		case 4:
			return '#1e8065';
		case 5:
			return '#B31D15';
		default:
			return '#8D51E6';
	}
};

const getBgColor = (state, bgColor) => {
	if (bgColor) return bgColor;
	switch (state) {
		case 1:
			return '#F9F5EC';
		case 2:
			return '#FBF0EA';
		case 3:
			return '#F7F8FD';
		case 4:
			return '#F7FDFB';
		case 5:
			return '#FFF5F5';
		default:
			return '#F7F0FF';
	}
};

const ChipContainer = styled.span`
	display: flex;  
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ state, color }) => getColor(state, color)};
    background-color: ${({ state, bgColor }) => getBgColor(state, bgColor)};
`;

const ChipText = styled.span`
    color: ${({ state, color }) => getColor(state, color)};
    font-size: 12px;
`;