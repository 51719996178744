import React from 'react';
import { Button, Modal } from '@mui/material';
import styled from 'styled-components';
import { Text } from '../Texts';
import { Icon } from '@iconify/react/dist/iconify.js';

const CorrelativesModal = (props) => {
    const { onClose, open, subject } = props;

    return (
        <CustomModal onClose={onClose} open={open}>
            <Container>
                <Text fontSize="18px" fontWeight="500">
                    Para poder matricularte a la asignatura{' '}
                    <mark>{subject?.name}</mark>, es necesario que hayas{' '}
                    <mark style={{ color: '#222222' }}>
                        finalizado las siguientes asignaturas correlativas:
                    </mark>
                </Text>
                <CorrelativesContainer>
                    {subject?.correlative_subjects?.map((correlative) => (
                        <CorrelativeWrapper>
                            {correlative?.graduate ? (
                                <Icon
                                    fontSize={24}
                                    color="#28A986"
                                    fontWeight={600}
                                    icon="simple-line-icons:check"
                                />
                            ) : (
                                <Icon
                                    icon="mdi-light:clock"
                                    fontSize={24}
                                    color="#A8A8A8"
                                    fontWeight={600}
                                />
                            )}
                            <Text
                                fontSize="14px"
                                fon14pxtWeight="600"
                                color={
                                    correlative?.graduate
                                        ? '#28A986'
                                        : '#A8A8A8'
                                }
                            >
                                {correlative?.name}
                            </Text>
                        </CorrelativeWrapper>
                    ))}
                </CorrelativesContainer>
                <ExitButton onClick={onClose} variant="contained">
                    Entendido
                </ExitButton>
            </Container>
        </CustomModal>
    );
};

export default CorrelativesModal;

const CustomModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    max-width: 600px;
    min-height: 350px;
    padding: 40px 32px 32px 32px;
    border-radius: 20px;
    background-color: #ffffff;
    outline: none;

    mark {
        background-color: #ffffff;
        color: #b31d15;
        font-weight: 700;
        font-size: 17px;
    }

    @media screen and (max-width: 425px) {
        max-width: 320px;

        svg {
            font-size: 24px;
        }

        mark,
        b,
        p {
            font-size: 15px;
        }
    }
`;

const CorrelativesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
`;

const CorrelativeWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    @media screen and (max-width: 425px) {
        p {
            font-size: 13px;
        }
    }
`;

const ExitButton = styled(Button)`
    width: fit-content;
    padding: 0.5rem 1.5rem;
`;
