import React, { useEffect, useState } from "react";
import * as encrypt from 'nodejs-base64-encode';
import styled from "styled-components";

// Components
import FontAwesomeIcon from "../../../components/common/FontAwesomeIcon";
import { Text } from "../../../components/common/Texts";

// Redux
import { getOtherSubmissions } from "../../../redux/api/teacher";

const BannerTask = (props) => {
    const { student, displayMode, submissionId, idAssignment } = props;

    const [open, setOpen] = useState(false);
    const [otherSubmissions, setOtherSubmissions] = useState([]);

    const getAnothersSubmissions = async () => {
        const response = await getOtherSubmissions(student.id, submissionId)
        setOtherSubmissions(response)
    }

    useEffect(() => {
        if (student.id && submissionId) getAnothersSubmissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createURL = (taskId) => {
        const data = {
            id_task: taskId || 0,
            id_assignment: idAssignment || 0,
        };

        const hash = encrypt.encode(JSON.stringify(data), 'base64');

        return `../calificador/${hash}`;
    }

    return (
        <BannerTaskWrapper displayMode={displayMode}>
            <Section open={open}>
                {
                    otherSubmissions?.map(submission => (
                        <a href={createURL(submission?.submission_id)}>{submission?.student_name}</a>
                    ))
                }
            </Section>
            <Body open={open}>
                {
                    displayMode === "desktop" && <Avatar src={student.image_url} />
                }
                <Text fontWeight="bold" fontSize="20px">
                    {student.name || "Undefined"}
                </Text>
                {
                    otherSubmissions?.length > 0 &&
                    <button onClick={() => setOpen(!open)}>
                        <FontAwesomeIcon icon="fa-regular fa-chevron-down" color="#222" size={24}/>
                    </button>
                }
            </Body>
        </BannerTaskWrapper>
    );
};

export default BannerTask;

const BannerTaskWrapper = styled.div`
    position: relative;
    display: ${(p) => p.displayMode === "mobile" ? "none" : "flex"};
    flex-direction: column;
    height: auto;
    width: ${(p) => p.displayMode === "mobile" ? "100vw" : "auto"};
    margin: ${(p) => p.displayMode === "mobile" && "-26px 0px 16px -13px"};
    box-shadow: none;
    padding: 5.5px 0;
    border-bottom: ${(p) => p.displayMode === "desktop" && "solid 1px #c4c4c4"};
    border-left: solid 1px #c4c4c4;
    background-color: #f1f1f1;
    box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    -webkit-box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    -moz-box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    border-radius: 0px;

    @media (max-width: 1200px) {
        border-left: 0px;
        border-radius: ${(p) => p.displayMode === "desktop" && "20px 20px 0px 0px"};
    }

    @media (max-width: 758px) {
        display: ${(p) => p.displayMode === "mobile" ? "flex" : "none"};
    }
`;

const Body = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    button {
        cursor: pointer;

        label {
            cursor: pointer;
        }

        svg {
            transform: ${(p) => p.open ? "rotate(180deg)" : "rotate(0deg)"};
            transition: all 0.3s;
        }
    }
`;

const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url(${(p) => p.src});
    background-size: 100%;
    background-position: center;
    border: 1px solid #f1f1f1;
`;

const Section = styled.div`
    position: absolute;
    top: 60px;
    width: 100%;
    height: ${(p) => p.open ? "fit-content" : "0px"};
    max-height: 30vh;
    background-color: #f1f1f1;
    z-index: 9999;
    overflow-y: auto;
    transition: all .3s;
    border: 1px solid #c4c4c4;
    display: flex;
    flex-direction: column;

    a {
        cursor: pointer;
        color: #222;
        padding: 10px 1rem;

        :hover {
            background-color: #fff;
        }
    }
`