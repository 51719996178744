import React, { useEffect, useState } from 'react';
import Twemoji from 'react-emoji-render';
import styled from 'styled-components';
import FileComponent from './FileComponent';
import * as base64 from 'nodejs-base64-encode';
import LazyImg from '../../../common/LazyImg';

import './chat.css';

export const ChatMensaje = ({
    color,
    user,
    msg,
    esEnviado = true,
    date,
    adjunto,
    fileName,
    mimeType,
    fileSize,
}) => {
    const isUser = esEnviado ? true : false;
    const justify = esEnviado ? 'flex-end' : 'flex-start';

    const MIMETYPE_CASES = ['image/jpeg', 'image/jpg', 'image/png'];

    let objectToBase64File =
        !!adjunto && typeof adjunto === 'object'
            ? URL.createObjectURL(adjunto)
            : null;

    const [stateDate, setStateDate] = useState(false);

    useEffect(() => {
        let date1 = new Date(date);
        let dateNow =
            date1.getDate() +
            '/' +
            (date1.getMonth() + 1) +
            '/' +
            date1.getFullYear() +
            ' | ' +
            date1.getHours() +
            ':' +
            date1.getMinutes();
        setStateDate(dateNow);
    }, []);

    return (
        <MessageWrapper>
            <PositionMsg jc={justify}>
                <MessageInfo jc={justify}>
                    {!!msg.length && (
                        <MessageContainer user={isUser}>
                            <Message user={isUser}>
                                {typeof msg === 'string' ? (
                                    <p>
                                        <Twemoji text={msg} />
                                    </p>
                                ) : (
                                    <p>{msg}</p>
                                )}
                                <Hour>
                                    <b>{stateDate ? stateDate : '...'}</b>
                                </Hour>
                            </Message>
                        </MessageContainer>
                    )}
                    {MIMETYPE_CASES.includes(mimeType) && (
                        <ImageWrapper>
                            {typeof adjunto === 'object' && (
                                <Image src={objectToBase64File} />
                            )}

                            {typeof adjunto === 'string' && (
                                <Image
                                    src={`data:${mimeType};base64, ${adjunto}`}
                                />
                            )}
                        </ImageWrapper>
                    )}
                    {!!adjunto && !MIMETYPE_CASES.includes(mimeType) && (
                        <FileComponent
                            name={fileName}
                            size={fileSize}
                            href={`data:${mimeType};base64, ${adjunto}`}
                        />
                    )}
                </MessageInfo>
            </PositionMsg>
        </MessageWrapper>
    );
};

const MessageWrapper = styled.div`
    width: 100%;
    height: auto;
`;

const PositionMsg = styled.div`
    display: flex;
    justify-content: ${(p) => p.jc};
    margin: 1.5rem 0;
`;

const MessageInfo = styled.div`
    width: 100%;
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    align-items: ${(p) => p.jc};
`;

const Message = styled.div`
    position: relative;
    padding: 0.1rem 2rem 1rem 0.5rem;
    margin: 0.1rem 0;
    color: #535353;
    min-width: 80px;
    background-color: ${(p) => (p.user ? '#f5f3f2' : '#f5deda')};
    ${(p) =>
        p.user
            ? 'border-radius: 10px 0 10px 10px;'
            : 'border-radius: 0 10px 10px 10px;'}

    p {
        margin: 0.5rem 0.25rem;
    }
`;

const Hour = styled.div`
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 10px;
    color: #888888;
    white-space: nowrap;
    padding: 0 2px 2px 0;
`;

const MessageContainer = styled.div`
    position: relative;
    max-width: 300px;

    &::after {
        position: absolute;
        content: '';
        height: 15px;
        width: 15px;
        margin: auto;
        top: 1.5px;
        background-color: ${(p) => (p.user ? '#f5f3f2' : '#f5deda')};

        ${(p) => (p.user ? 'right: -14px;' : 'left: -14px;')}
        ${(p) =>
            p.user
                ? 'clip-path: polygon(0 0, 0% 100%, 100% 0);'
                : 'clip-path: polygon(0 0, 100% 100%, 100% 0);'}
        ${(p) =>
            p.user
                ? ' border-radius: 0 5px 0 0;'
                : ' border-radius: 5px 0 0 0;'}
    }
`;

const ImageWrapper = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 10px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
`;
