import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import styled from "styled-components";
import GenericProgress from "../../../../common/GenericProgress";
import ChipItems from "./components/ChipItems";
import CommonStateChip from "../../../../common/CommonStateChip";

const UsersCard = (props) => {
    const { user } = props;

    const {
        name,
        profession,
        city,
        photo,
        dataIDP,
        progress,
        partnerId
    } = user;

    const [expanded, setExpanded] = useState(false);

    const handleExpanded = () => {
        setExpanded(!expanded);
    };

    const getIDPState = () => {
        if (dataIDP?.performance === "bajo") {
            return 5;
        }
        if (dataIDP?.performance === "alto") {
            return 4;
        }
        if (dataIDP?.performance === "medio-bajo") {
            return 2;
        }
        if (dataIDP?.performance === "medio-alto") {
            return 1;
        }
        return 0;
    }

    return (
        <Card className={expanded ? "expanded" : ""}>
            <Body href={`../alumno/${partnerId}`} target="_blank">
                <Images>
                    <Img top10={dataIDP?.top10}>
                        <img src={photo} alt="foto estudiante" />
                        {
                            dataIDP?.top10
                                ? <span>Ranking 10</span>
                                : <span />
                        }
                    </Img>
                    {/*despues cambiar este chip por el de la futura librería */}
                    <CommonStateChip
                        text={`IDP ${dataIDP?.performance ?? "Indefinido"}`}
                        state={getIDPState()}
                    />
                </Images>
                <InfoStudent>
                    <Name>{name}</Name>
                    <Occupation>{profession.name || "-"}</Occupation>
                    <Address>
                        <FmdGoodIcon />
                        <span>{city || "-"}</span>
                    </Address>
                </InfoStudent>
                <CircleProgress>
                    <GenericProgress
                        variant="determinate"
                        fontSize={14}
                        progress={progress ?? 0}
                        size={60}
                    />
                </CircleProgress>
            </Body>
            <Footer expanded={expanded}>
                {
                    dataIDP ?
                        <Button expanded={expanded} onClick={handleExpanded}>
                            Ver índice de participación detallado
                            <KeyboardArrowDownIcon />
                        </Button>
                        :
                        <p>Detalles de índice de participación no disponible</p>
                }
            </Footer>
            {dataIDP && (
                <Details expanded={expanded}>
                    <ChipItems
                        value={Math.round(dataIDP?.IDPValue ?? 0)}
                        name="Puntos obtenidos"
                        type="resume"
                        ranking={dataIDP?.top10}
                    />
                    <ChipItems
                        value={Math.round(dataIDP?.courseSpeed * 100)}
                        name="Velocidad de cursado"
                        type="item"
                        tooltipText="Mide la rapidez y puntualidad con la que un estudiante avanza en el curso."
                    />
                    <ChipItems
                        value={Math.round(dataIDP?.activitiesPresented * 100)}
                        name="Cumplimiento de entregas"
                        type="item"
                        tooltipText="Evalúa la frecuencia con la que los estudiantes completan y entregan las actividades asignadas."
                    />
                    <ChipItems
                        value={Math.round(dataIDP?.displayResources * 100)}
                        name="Visualización de material"
                        type="item"
                        tooltipText="Indica cuántos recursos proporcionados en el curso son visualizados por el estudiante."
                    />
                    <ChipItems
                        value={Math.round(dataIDP?.inactiveDays * 100)}
                        name="Actividad en plataforma"
                        type="item"
                        tooltipText="Este indicador mide el tiempo que un estudiante pasa sin actividad en la plataforma."
                    />
                </Details>
            )}
        </Card>
    );
};

export default UsersCard;

const Card = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #fff;
    border: solid 2px #616161;
    box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;

    &.expanded {
        grid-row: span 2;
    }
`;

const Body = styled.a`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border-bottom: solid 1px #c4c4c4;
    box-sizing: border-box;
    padding-bottom: 0.5rem;
`;

const Images = styled.div`
    flex-basis: 37%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 0.5rem;
`;

const Img = styled.div`
    width: 57%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;

    img {
        width: 100%;
        border-radius: 50%;
        outline: solid 2px ${({ top10 }) => top10 ? "#ffbc00" : "transparent"};
        display: flex;
        object-fit: cover;
    }

    span {
        color: #ffbc00;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        height: 14px;
    }
`;

const InfoStudent = styled.div`
    flex-basis: 46%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: space-between;
    gap: 0.5rem;
`;

const Name = styled.h4`
    font-size: 14px;
    font-weight: 700;
    color: #222;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
`;

const Occupation = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: #616161;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
`;

const Address = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: flex-start;

    svg {
        color: #616161;
        font-size: 16px;
    }
    span {
        color: #616161;
        font-size: 11px;
    }
`;

const CircleProgress = styled.div`
    flex-basis: 17%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Footer = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease;
    margin-bottom: ${(p) => (p.expanded ? ".3rem" : 0)};

    p {
        color: #616161;
        font-size: 13px;
    }
`;

const Button = styled.button`
    outline: none;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    color: #616161;
    font-size: 13px;
    align-items: center;
    cursor: pointer;

    svg {
        transition: ease all 0.3s;
        transform: rotate(${(p) => (p.expanded ? "180deg" : "0deg")});
        color: #616161;
        font-size: 1.6rem;
    }
`;

const Details = styled.div`
    max-height: ${(p) => (p.expanded ? "1000px" : "0")};
    overflow: hidden;
    transition: max-height 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;
