import styled from 'styled-components';
import Tabs from '../../../components/common/Tabs';
import { useState } from 'react';
import PersonalInfo from './modalInfo/PersonalInfo';
import ContactInfo from './modalInfo/ContactInfo';
import PasswordManagement from './modalInfo/PasswordManagement';
import CommonButton from '../../../components/common/CommonButton';
import { useContext } from 'react';
import { ProfileContext } from '../../../contexts/profile/ProfileProvider';
import { useRef } from 'react';
import { Form, Formik } from 'formik';
import { cloneElement } from 'react';

const ModalInfo = (props) => {
    const { onClose } = props;

    const [tabValue, setTabValue] = useState(1);

    const { user, userLoading, handleUpdateProfile } =
        useContext(ProfileContext);

    const formRef = useRef();

    const handleSubmitClick = () => {
        formRef.current.submitForm(); // Llama a submitForm en el componente hijo
    };

    const tabOptions = [
        {
            id: 1,
            label: 'Información personal',
            component: <PersonalInfo />,
        },
        {
            id: 2,
            label: 'Información de contacto',
            component: <ContactInfo />,
        },
        {
            id: 3,
            label: 'Gestión de contraseña',
            component: <PasswordManagement />,
        },
    ];

    const handleTabValue = (value) => {
        setTabValue(value);
    };

    const initialValues = {
        // AboutYou
        photo: user?.photo,
        biography: user?.biography,
        // PersonalInfo - PersonalData
        name: user?.name,
        birthday: user?.birthdayDate,
        gender: user?.gender,
        document_type: user?.documentType,
        document_number: user?.documentNumber,
        country: user?.countryObject?.id,
        city: user?.city,
        zip: user?.zip,
        street: user?.street,
        profession: user?.professionObject,
        current_job: user?.currentJobObject,
        company: user?.company,
        // ContactInfo - ContactData
        personal_email: user?.email,
        phone: user?.phoneNumber,
        work_phone: user?.workPhoneNumber,
        // ContactInfo - SocialNetworks
        linkedin: user?.linkedin,
        facebook: user?.facebook,
        instagram: user?.instagram,
        twitter: user?.twitter,
        // PasswordManagement
        new_password_modal: '',
        confirm_new_password: '',
        //
        // More
        // nationality: user?.nationality,
        // profile_is_public: user?.profileIsPublic,
        // OptionTwo
        // whatsapp: user?.whatsapp,
        // skype: user?.skype,
        // Option three
        // OptionFour
        // job_area: user?.jobArea,
        // work_email: user?.workEmail,
        // work_experience: user?.workExperience,
        // academic_training: user?.academicTraining,
        // OptionFive
        // motivaciones: user?.motivaciones,
        // intereses: user?.intereses
    };

    const handleSubmit = (values) => {
        let formattedDate = null;
        if (values.birthday) {
            const [day, month, year] = values?.birthday.split('/');
            const parsedDate = new Date(`${year}-${month}-${day}`);
            formattedDate = new Intl.DateTimeFormat('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).format(parsedDate);
        }

        const valuesToSubmit = {
            photo: values?.photo,
            biography: values?.biography,
            // PersonalInfo - PersonalData
            name: values?.name,
            birthday: formattedDate,
            gender: values?.gender,
            document_type: values?.documentType,
            document_number: values?.documentNumber,
            country: values?.countryObject?.id,
            city: values?.city,
            zip: values?.zip,
            street: values?.street,
            profession: values?.professionObject,
            current_job: values?.currentJobObject,
            company: values?.company,
            // ContactInfo - ContactData
            personal_email: values?.email,
            phone: values?.phoneNumber,
            work_phone: values?.workPhoneNumber,
            // ContactInfo - SocialNetworks
            social_networks: [
                {
                    name: 'linkedin',
                    url_red_social: values?.linkedin ?? '',
                },
                {
                    name: 'facebook',
                    url_red_social: values?.facebook ?? '',
                },
                {
                    name: 'instagram',
                    url_red_social: values?.instagram ?? '',
                },
                {
                    name: 'twitter',
                    url_red_social: values?.twitter ?? '',
                },
            ],
        };

        handleUpdateProfile(valuesToSubmit);
    };

    return (
        <ModalInfoContainer>
            <Tabs
                tabValue={tabValue}
                handleTabValue={handleTabValue}
                tabOptions={tabOptions}
            />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {({ values, setFieldValue }) => (
                    <FormContainer>
                        {cloneElement(tabOptions[tabValue - 1].component, {
                            values,
                            setFieldValue,
                        })}
                    </FormContainer>
                )}
            </Formik>
            {/* {tabOptions[tabValue - 1].component} */}
            <ButtonsContainer>
                <CommonButton
                    variant="outlined"
                    label="Cancelar"
                    onClick={onClose}
                    disabled={userLoading}
                />
                <CommonButton
                    variant="filled"
                    label="Guardar cambios"
                    onClick={handleSubmitClick}
                    disabled={userLoading}
                />
            </ButtonsContainer>
        </ModalInfoContainer>
    );
};

export default ModalInfo;

const ModalInfoContainer = styled.div`
    width: 70vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: start;
    padding: 24px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
`;

const ButtonsContainer = styled.div`
    width: calc(100% + 1rem);
    display: flex;
    gap: 1rem;
    justify-content: end;
    align-items: center;
    background-color: #f9f9f9;
    margin: -24px;
    padding: 1rem;
    border-radius: 0 0 30px 30px;
`;

const FormContainer = styled(Form)`
    width: 100%;
    height: 100%;

    @media (width < 768px) {
        overflow-y: scroll;
    }
`;
