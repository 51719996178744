import styled from 'styled-components';

// Components
import AddEventToCalendar from '../../../../components/ui/shared/AddEventToCalendar';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';

// Material UI
import CommonButton from '../../../../components/common/CommonButton';

const EventCardFooter = (props) => {
    const {
        value,
        eventDateBegin,
        currentDate,
        handleOpenModal,
        eventDate,
        handleRegister,
        formatEventDateBegin,
        formatCurrentDate,
        eventDateEnd,
    } = props;

    const isPastEvent = eventDateBegin < currentDate;

    const isEventAvailableAndNotRegistered =
        !value.is_registered && eventDate >= currentDate;

    // !!value.is_registered && (eventDateBegin >= currentDate || formatEventDateBegin === formatCurrentDate)
    const isEventAvailableOrTodayAndRegistered =
        !!value.is_registered && formatEventDateBegin === formatCurrentDate;

    // !!value.is_registered && value.registration_url === '' && value.webinar_url !== '' && (eventDateBegin >= currentDate || formatEventDateBegin === formatCurrentDate) && !value.is_registered
    const isEventAvailableAndRegistered =
        !!value.is_registered &&
        value.registration_url === '' &&
        value.webinar_url !== '' &&
        (eventDateBegin >= currentDate ||
            formatEventDateBegin === formatCurrentDate);

    // value.webinar_url !== '' && ((eventDateBegin < currentDate && eventDateEnd < currentDate) || formatEventDateBegin === formatCurrentDate) ? (value.webinar_url.toLowerCase().includes('www.aden.org')
    const isEventURLAvailable =
        value.webinar_url !== '' &&
        ((eventDateBegin < currentDate && eventDateEnd < currentDate) ||
            formatEventDateBegin === formatCurrentDate) &&
        value.webinar_url.toLowerCase().includes('www.aden.org');

    const handleOpenWebinaURL = () => {
        if (value.webinar_url.toLowerCase().includes('http')) {
            window.open(value.webinar_url, '_blank');
            return;
        } else {
            window.open(`https://${value.webinar_url}`, '_blank');
        }
    };
    return (
        <Footer>
            {!!value.webinar_url && (
                <>
                    {isPastEvent ? (
                        <ActionButton
                            onClick={() =>
                                handleOpenModal(
                                    value.webinar_url,
                                    value.crm_id,
                                    value.publication_name,
                                    'evento-pasado'
                                )
                            }
                        >
                            <FontAwesomeIcon
                                icon="fa-light fa-share-nodes"
                                size={24}
                                color={'#b31d15'}
                            />
                        </ActionButton>
                    ) : (
                        <ActionsContainer>
                            <ActionButton
                                onClick={() =>
                                    handleOpenModal(
                                        value.webinar_url,
                                        value.crm_id,
                                        value.publication_name,
                                        'evento-actual'
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon="fa-light fa-share-nodes"
                                    size={24}
                                    color={'#b31d15'}
                                />
                            </ActionButton>
                            <AddEventToCalendar oneEvent={value} />
                        </ActionsContainer>
                    )}
                </>
            )}
            {isEventAvailableAndNotRegistered && (
                <CommonButton
                    onClick={handleRegister}
                    variant="filled"
                    label="Inscribirme"
                />
            )}
            {isEventAvailableOrTodayAndRegistered ? (
                <CommonButton
                    onClick={handleRegister}
                    variant="filled"
                    label="Ingresar al evento"
                />
            ) : isEventAvailableAndRegistered ? (
                <CommonButton
                    onClick={handleRegister}
                    variant="filled"
                    label="Ver evento"
                />
            ) : isEventURLAvailable ? (
                <CommonButton
                    onClick={handleOpenWebinaURL}
                    variant="filled"
                    label="Volver a ver"
                />
            ) : null}
        </Footer>
    );
};

export default EventCardFooter;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    background-color: #f1f1f1;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
`;

const ActionButton = styled.button`
    cursor: pointer;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    border: 1px solid #b31d15;

    &:hover {
        background-color: #b31d15;

        svg {
            color: #fff !important;
        }
    }
`;
