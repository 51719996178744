import styled from 'styled-components';
import MockupStepThree from '../assets/mockup-step-3-1.png';
import MockupStepThree2 from '../assets/mockup-step-3-2.png';

const BodyStepThree = () => {
    return (
        <BodyContainer>
            <img src={MockupStepThree} alt="Mockup Step Three" />
            <Info>
                <span>
                    <h2>Filtros</h2>
                    <p>Maestrías - Major - Especializaciones - Diplomados</p>
                </span>
                <SecondInfo>
                    <img src={MockupStepThree2} alt="Mockup Step Three 2" />
                    <span>
                        <h2>Cursado</h2>
                        <p>
                            En esa sección podrás <b>consultar los programas</b>{' '}
                            que forman parte de tu cursado, así como{' '}
                            <b>visualizar tu progreso</b> o{' '}
                            <b>verificar el estado</b> de cada uno.
                        </p>
                    </span>
                </SecondInfo>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepThree;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    img {
        margin-top: -1rem;
        margin-right: 2rem;
        height: 90%;
    }
`;

const Info = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 4rem;
    z-index: 2;
    color: #222;

    h2 {
        color: #b31d15;
    }

    span {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

const SecondInfo = styled.div`
    display: flex;
    align-items: start;

    img {
        width: 70%;
    }
`;
