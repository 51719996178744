const parseDateString = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
};

const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const todayDateString = getTodayDateString();
const todayDate = parseDateString(todayDateString);

export const validateAnnouncementDates = (pubDate, expDate, rol) => {
    const publishedDate = parseDateString(pubDate);
    const expirationDate = parseDateString(expDate);
    const announcementIsNew =
        rol === 'docente'
            ? todayDate.getTime() < publishedDate.getTime() &&
              expirationDate.getTime() >= todayDate.getTime()
            : todayDate.getTime() === publishedDate.getTime();
    return announcementIsNew;
};
