import styled from "styled-components";
import StarIcon from "@mui/icons-material/Star";
import FontAwesomeIcon from "../../../../../common/FontAwesomeIcon";
import { useState } from "react";

const ChipItems = (props) => {
    const { value, name, type, ranking = false, tooltipText } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Chip type={type} ranking={ranking}>
            <div>
                {type === "resume" && <StarIcon />}
                <span>
                    {value}
                    {type === "item" && "%"}
                </span>
                <p>{name}</p>
            </div>
            {
                !!tooltipText &&
                <TooltipContainer
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <FontAwesomeIcon icon="fa-light fa-circle-info" size={20} color="#000" />
                    {showTooltip && <Tooltip>{tooltipText}</Tooltip>}
                </TooltipContainer>
            }
        </Chip>
    );
};

export default ChipItems;

const Chip = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1rem;
    gap: 0.5rem;
    border-radius: 20px;
    border: solid 1px
        ${(p) => (p.type === "resume" && p.ranking ? "##FFBC00" : "#616161")};

    background: ${(p) =>
        p.type === "resume" ? (p.ranking ? "#FFBC00" : "#616161") : "#fff"};

    color: ${(p) => (p.type === "resume" ? "#fff" : "#616161")};

    div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    span {
        font-weight: bold;
        font-size: 1.2rem;
    }

    p {
        font-size: 1.1rem;
    }
`;

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const Tooltip = styled.div`
    visibility: visible;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiciona el tooltip arriba del icono */
    right: 0%;
    margin-left: -60px;
    width: 100%;
    min-width: 300px;
    opacity: 0.9;
    font-size: 0.9rem;

    /* Flecha del tooltip */
    &::after {
        content: "";
        position: absolute;
        top: 100%; /* En la parte inferior del tooltip */
        right: 2%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;