import { Button, Radio, FormControlLabel, FormGroup } from "@mui/material";
import styled from "styled-components";

const ModalMoreFilter = (props) => {
    const {
        buttonRef,
        handleFilterSelected,
        filterSelected,
        handleConfirmFilter,
    } = props;

    return (
        <Container buttonRef={buttonRef}>
            <Wrapper>
                <Header>Filtrar</Header>
                <SubHeader>Estado de cursado de alumnos</SubHeader>
                <Body>
                    <FormGroup>
                        <CustomFormControlLabel
                            control={<Radio />}
                            label="Todos"
                            labelPlacement="start"
                            onClick={() => {
                                handleFilterSelected("");
                            }}
                            checked={filterSelected === ""}
                        />
                        <CustomFormControlLabel
                            control={<Radio />}
                            label="Preinscriptos"
                            labelPlacement="start"
                            onClick={() => {
                                handleFilterSelected("preinscripto");
                            }}
                            checked={filterSelected === "preinscripto"}
                        />
                        <CustomFormControlLabel
                            control={<Radio />}
                            label="En curso"
                            labelPlacement="start"
                            onClick={() => {
                                handleFilterSelected("confirmado");
                            }}
                            checked={filterSelected === "confirmado"}
                        />
                        <CustomFormControlLabel
                            control={<Radio />}
                            label="Egresados"
                            labelPlacement="start"
                            onClick={() => {
                                handleFilterSelected("egresado");
                            }}
                            checked={filterSelected === "egresado"}
                        />
                    </FormGroup>
                </Body>
                <Footer>
                    <CustomButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleConfirmFilter("confirm")}
                    >
                        Aplicar filtros
                    </CustomButton>
                </Footer>
            </Wrapper>
        </Container>
    );
};

export default ModalMoreFilter;

const Container = styled.div`
    position: absolute;
    width: 286px;
    top: ${(p) =>
        `${
            10 +
            p.buttonRef.current.offsetTop +
            p.buttonRef.current.offsetHeight
        }px`};
    left: ${(p) => `${p.buttonRef.current.offsetLeft}px`};
    z-index: 10;
    background-color: #fff;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
`;
const Header = styled.div`
    width: 100%;
    color: #616161;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: start;
`;
const SubHeader = styled.div`
    width: 100%;
    color: #616161;
    font-size: 1rem;
    font-weight: bold;
    text-align: start;
    border-bottom: solid 1px #f1f1f1;
    padding-bottom: 1rem;
`;

const Body = styled.div`
    width: 100%;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CustomButton = styled(Button)`
    border-radius: 10px;
`;
