import React, { useEffect } from "react";
import * as encode from "nodejs-base64-encode";
import { useSelector } from "react-redux";
import DefaultCard from "../../../acropolisCommon/components/DefaultCard";
import { viewResource } from "../../services/repositorio";
import { useHistory } from "react-router-dom";

const MainViewSurvey = (props) => {
    const { hash } = props;

    const { user } = useSelector((state) => state.auth);

    const { goBack } = useHistory();

    // Como puede llegar a venir un "*" en el hash, pero que principalmente tendría que ser un "/", para que no rompa la URL lo reemplazamos por un "*"
    // y lo volvemos a reemplazar por "/" acá
    const hashFixed = hash.replace(/\*/g, '/');
    const decodeData = encode.decode(hashFixed, "base64");
    const surveyObject = JSON.parse(decodeData);

    const handleProgress = async () => {
        if (!surveyObject.done) {
            const body = {
                user: user.repo_id,
                survey: surveyObject.id,
            };
            const request = await viewResource(body);
            if (request.error) {
                alert("Ocurrió un error al cargar el progreso");
            }
        }
        window.open(surveyObject?.link);
        goBack();
    };

    useEffect(() => {
        handleProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DefaultCard>
        </DefaultCard>
    );
};

export default MainViewSurvey;