import styled from 'styled-components';
import { Icon } from '@iconify/react/dist/iconify.js';

// Components
import MultipleChoiceCardSubmissionState from './MultipleChoiceCardSubmissionState';
import MultipleChoiceCardHeader from './MultipleChoiceCardHeader';
import { numeroALetras } from '../../../../../../helpers/numeroALetras';

const MultipleChoiceHeader = (props) => {
    const { publicationName, dateAttempt, attempts, time, submissions, note } =
        props;

    const examApproved = submissions?.find(
        (submission) => ((submission?.grade * 100) / note).toFixed(2) >= 80
    );

    return (
        <Header>
            <HeaderRender bg="#b31d15" textColor="#fff">
                {publicationName}
            </HeaderRender>
            <HeaderSectionContainer>
                <LeftContent>
                    <MultipleChoiceCardHeader
                        Icon={
                            <Icon
                                icon="ph:calendar-x-light"
                                width="20px"
                                height="1.2em"
                            />
                        }
                        title="Fecha límite"
                        subtitle={dateAttempt !== false ? dateAttempt : '-'}
                    />
                    <MultipleChoiceCardHeader
                        Icon={
                            <Icon
                                icon="grommet-icons:rotate-right"
                                width="20px"
                                height="1.2em"
                            />
                        }
                        title="Intentos"
                        subtitle={
                            attempts === 100
                                ? 'Ilimitado'
                                : attempts < 0
                                  ? 0
                                  : `${attempts} (${numeroALetras(attempts)})`
                        }
                    />
                    <MultipleChoiceCardHeader
                        Icon={
                            <Icon
                                icon="radix-icons:timer"
                                width="20px"
                                height="1.2em"
                            />
                        }
                        title="Tiempo"
                        subtitle={!time ? 'Sin límite' : `${time} min`}
                    />
                </LeftContent>
                <RightContent>
                    <MultipleChoiceCardSubmissionState
                        Icon={
                            <Icon
                                icon="lucide:file-pen-line"
                                width="1.2em"
                                height="1.2em"
                            />
                        }
                        strongColor={
                            submissions?.length <= 0
                                ? '#C29F43'
                                : examApproved
                                  ? '#1E8065'
                                  : '#B31D15'
                        }
                        lightColor={
                            submissions?.length <= 0
                                ? '#F9F5EC'
                                : examApproved
                                  ? '#F7FDFB'
                                  : '#FFF5F5'
                        }
                        state={
                            submissions?.length <= 0
                                ? 'No realizado'
                                : examApproved
                                  ? 'Aprobado'
                                  : 'Realizado'
                        }
                    />
                </RightContent>
            </HeaderSectionContainer>
        </Header>
    );
};

export default MultipleChoiceHeader;

const Header = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    border: 1px solid #a8a8a8;

    @media (max-width: 1300px) {
        display: flex;
        flex-direction: column;
    }
`;

const HeaderRender = styled.div`
    width: 100%;
    border-radius: 30px 30px 0 0;
    height: 40px;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => p.bg};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: ${(p) => (p.textColor ? p.textColor : '#000')};
`;

const HeaderSectionContainer = styled.div`
    height: auto;
    width: calc(100% - 4rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;

    @media (width < 1400px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

const LeftContent = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (width < 768px) {
        width: 100%;
        flex-direction: column;
    }
`;

const RightContent = styled.div`
    display: flex;
    align-items: center;

    @media (width < 768px) {
        width: 100%;
    }
`;
