import * as yup from 'yup';

export const loginSchema = yup.object().shape({
    user: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida')
});

export const registerSchema = yup.object().shape({
    first_name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre requerido'),
    last_name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Apellido requerido'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida'),
    changepassword: yup
        .string()
        .required('Contraseña requerida')
        .when('password', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup
                .string()
                .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
        })
});

export const registerNewSchema = yup.object().shape({
    full_name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre requerido'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida')
});

export const registerStudentSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida'),
    changepassword: yup
        .string()
        .required('Contraseña requerida')
        .when('password', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup
                .string()
                .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
        })
});

export const profileSchema = yup.object().shape({
    // OptionOne
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres'),
    biography: yup
        .string()
        .min(12, 'Mínimo 12 caracteres')
        .max(512, 'Máximo 512 caracteres'),
    document_number: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(24, 'Máximo 24 caracteres'),
    city: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(64, 'Máximo 64 caracteres'),
    // OptionThree
    company: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(64, 'Máximo 64 caracteres'),
    work_email: yup
        .string()
        .email('Formato incorrecto')
        .max(320, 'Máximo 320 caracteres'),
    work_experience: yup
        .string()
        .min(12, 'Mínimo 12 caracteres')
        .max(1024, 'Máximo 1024 caracteres'),
    academic_training: yup
        .string()
        .min(12, 'Mínimo 12 caracteres')
        .max(1024, 'Máximo 1024 caracteres'),
    // OptionThree
    personal_email: yup
        .string()
        .email('Formato incorrecto')
        .max(320, 'Máximo 320 caracteres'),
    facebook: yup.string().url('Formato de URL incorrecto'),
    instagram: yup.string().url('Formato de URL incorrecto'),
    twitter: yup.string().url('Formato de URL incorrecto'),
    linkedin: yup.string().url('Formato de URL incorrecto')
});

export const ticketSchema = yup.object().shape({
    issue: yup
        .string()
        .min(4, 'Mínimo 4 caracteres')
        .max(128, 'Máximo 128 caracteres')
        .required('Asunto requerido'),
    description: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(2048, 'Máximo 2048 caracteres')
        .required('Descripción requerida')
});

export const checkTicketSchema = yup.object().shape({
    ticket: yup
        .number()
        .positive()
        .moreThan(0, 'Número de ticket incorrecto')
        .lessThan(9999999, 'Número de ticket incorrecto')
        .required('Número de ticket requerido')
});


export const highlightSchema = yup.object().shape({
    highlight: yup
        .string()
        .min(10, 'Mínimo 10 caracteres')
        .max(200, 'Máximo 200 caracteres')
        .required('Motivo requerido')
});

export const referedSchema = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre requerida'),
    state: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Ciudad requerida'),
    lastName: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Apellido requerida'),
    phone: yup
        .string()
        .required('Teléfono requerida'),
    programs: yup
        .string()
        .required('Programa requerida'),
    country: yup
        .number()
        .required('País requerida')
});


export const searchReferedSchema = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
});


export const eventRegisterSchema = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre competo requerido'),
    phone: yup
        .string()
        .required('Teléfono requerido'),
    country: yup
        .number()
        .required('País requerido'),
    city: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre competo requerido'),
    terms: yup
        .boolean()
        .required('Terminos requeridos')
});

export const upsellingScheme = yup.object().shape({
    email: yup
        .string()
        // .email('Formato incorrecto')
        .required('Correo requerido'),
    phone: yup
        .string()
        .required('Teléfono requerido'),
    product: yup
        .string()
        .required('Programa requerido')
});


export const afterSalesScheme = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Nombre competo requerido'),
    phone: yup
        .number()
        .required('Teléfono requerido'),
    date: yup
        .date()
        .required('Fecha requerida'),
    description: yup
        .string()
        .min(4, 'Mínimo 4 caracteres')
        .max(128, 'Máximo 128 caracteres')
        .required('Información requerida'),
    referralPerson: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Información requerida')
});

export const recoverEmailSchema = yup.object().shape({
    email: yup
        .string()
        .email('formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres'),
})

export const recoverPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Contraseña requerida')
})

export const validationSchema = yup.object().shape({
    ticketName: yup
        .string()
        .nullable()
        .required("Asunto requerido")
        .max(320, "Máximo 320 caracteres")
});

export const announcementSchema = yup.object().shape({
    title: yup
        .string()
        .required("Asunto requerido"),
    message: yup
        .string()
        .required("Anuncio requerido"),
    publishedDate: yup
        .string()
        .required("Fecha de publicación requerida"),
    expirationDate: yup
        .string()
        .required("Fecha de caducidad requerida"),
    subjectId: yup
        .number()
        .required()
})

export const emailSchema = yup.object().shape({
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo requerido')
        .max(320, 'Máximo 320 caracteres')
});

export const personalDataSchema = yup.object().shape({
    name: yup.string().required('Nombre completo requerido').max(320, 'Máximo 320 caracteres'),
    birthday: yup.date().required('Fecha de nacimiento requerida'),
    gender: yup.string().required('Género requerido'),
    document_type: yup.string().required('Tipo de documento requerido'),
    document_number: yup.string().required('Número de documento requerido'),
    country: yup.string().required('País de residencia requerido'),
    city: yup.string().required('Ciudad de residencia requerida'),
    zip: yup.string().required('Código postal requerido'),
    profession: yup.string().required('Profesión requerida'),
    current_job: yup.string().required('Cargo requerido'),
    company: yup.string().required('Empresa requerida')
});