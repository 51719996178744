import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MyProgressContext } from './contexts/MyProgressProvider';
import SkeletonPlan from './SkeletonPlan';
import ProgramsCourses from '../../components/templates/studyPlan/ProgramsCourses';

const SubjectsBoard = () => {
    const history = useHistory();

    const { data, handleEnrollment, programRelated, programId, subjects } =
        useContext(MyProgressContext);

    if (data?.error) {
        history.push('../not-found');
    }

    if (!data) {
        return <SkeletonPlan />;
    }

    return (
        <ProgramsCourses
            subjects={subjects}
            id={programId}
            programRelated={programRelated}
            handleEnrollment={handleEnrollment}
        />
    );
};

export default SubjectsBoard;
