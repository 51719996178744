import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { ProfileContext } from '../../../../../contexts/profile/ProfileProvider';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';

const TextToEdit = (props) => {
    const { property } = props;

    const {
        user,
        userLoading,
        handleUpdateProfile,
        currentProperty,
        setCurrentProperty,
    } = useContext(ProfileContext);

    const [editingAcademicTraining, setEditingAcademicTraining] =
        useState(false);
    const [value, setValue] = useState(
        user[property?.formatted] || 'No hay información disponible'
    );

    const textareaRef = useRef(null);

    const loadingCurrentProperty =
        currentProperty === property.formatted && userLoading;

    const handleOnClick = () => {
        if (!userLoading) {
            setEditingAcademicTraining(true);
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            }, 10);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setCurrentProperty(property.formatted);
            handleUpdateProfile({ [property.notFormatted]: value });
            setEditingAcademicTraining(false);
        }
        if (event.key === 'Escape') {
            event.preventDefault();
            setValue(user[property?.formatted]);
            setEditingAcademicTraining(false);
        }
    };

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    const handleChange = (text) => {
        setValue(text);
    };

    return (
        <Container userLoading={userLoading} onClick={handleOnClick}>
            {!editingAcademicTraining ? (
                parse(sanitizerHtml(value))
            ) : (
                <ReactQuillContainer
                    style={{ height: '200px', width: '100%' }}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    modules={{
                        toolbar: {
                            container: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ['bold', 'italic', 'underline'],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ align: [] }],
                                ['link' /*, 'image'*/],
                                [{ color: [] }],
                            ],
                        },
                    }}
                />
            )}
            {loadingCurrentProperty && <p>Guardando...</p>}
        </Container>
    );
};

export default TextToEdit;

const Container = styled.p`
    width: 100%;
    padding: 1rem 0 0 0;
    cursor: ${({ userLoading }) => (userLoading ? 'not-allowed' : 'pointer')};
    color: #7f7f7f;

    :hover {
        color: ${({ userLoading }) => !userLoading && '#000'};
    }

    textarea {
        width: calc(100% - 2rem);
        resize: vertical;
        color: #7f7f7f;
        border: none;
        background-color: #f1f1f1;
        padding: 1rem;
    }

    p {
        color: #000;
        font-size: 16px;
    }
`;

const ReactQuillContainer = styled(ReactQuill)`
    border-radius: 20px;

    .ql-container {
        height: 75% !important;
    }
`;
