import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

const AlertModalSeccion = (props) => {
    const { title, date, isExtended, icon } = props;
    return (
        <AlertSeccion isExtended={isExtended}>
            <Icon icon={icon} />
            <TextDiv>
                <p isExtended={isExtended}>{title}</p>
                <span isExtended={isExtended}>{date}</span>
            </TextDiv>
        </AlertSeccion>
    );
};

export default AlertModalSeccion;

const AlertSeccion = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 0.5rem 1rem;
    gap: 4px;
    align-items: center;
    justify-content: center;
    background: ${(p) => (!p.isExtended ? '#fff5f5' : '#F7FDFB')};
    border: ${(p) =>
        !p.isExtended ? '1px solid #e94c44' : '1px solid #28A986'};
    border-radius: 10px;

    p {
        color: ${(p) => (!p.isExtended ? '#e94c44' : '#28A986')};
        font-weight: 700;
        font-size: 13px;
    }

    span {
        color: ${(p) => (!p.isExtended ? '#e94c44' : '#28A986')};
        font-weight: 900;
        font-size: 13px;
    }

    svg {
        color: ${(p) => (!p.isExtended ? '#e94c44' : '#28A986')};
        font-size: 20px;
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 0.8rem;
        }
    }
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
