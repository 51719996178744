import axios from 'axios';

export function getSubjects(id) {
    return axios.get(
        `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/${id}`
    );
}

export const getSubjectsP100 = async (id) => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/${id}?type=obj_aprendizaje`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export async function getSubjectPaginate(payload) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/paginate/${payload.subject}`;
    try {
        const pagination = await axios.post(URL, payload.query);
        return pagination;
    } catch (error) {
        return { error };
    }
}

export async function getSubjectDirectories(payload) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/directories/${payload.subject}?offset=${payload.query.page_offset}&limit=${payload.query.page_size}${payload.idp_filter !== "todos" ? `&idp_filter=${payload.idp_filter}` : ""}`;
    try {
        const directories = await axios.post(URL, payload.query);
        return directories;
    } catch (error) {
        return { error };
    }
}

export function getSubjectNotes(payload) {
    return axios.get(
        `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/notes_of_objects/${payload.repoId}/${payload.subjectId}`
    );
}

export async function getLastResourceSeen(repoId) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/last_open_subject_information/${repoId}?information_oa=true`;
    try {
        const request = await axios.get(URL);
        return request.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function getIDPTopTen(assignmentId, repoId) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/idp_top_ten/${assignmentId}${repoId ? `?repo_id=${repoId}` : ""}`;
    try {
        const request = await axios.get(URL);
        return request.data.response_data;
    } catch (error) {
        return { error };
    }
}