import styled from 'styled-components';
import FontAwesomeIcon from './FontAwesomeIcon';

const CommonButton = (props) => {
    const {
        label,
        Icon,
        variant,
        type,
        onClick,
        disabled,
        width = 'fit-content',
        FontAwesomeIconName,
        ...rest
    } = props;

    return (
        <Button
            variant={variant}
            type={type}
            disabled={disabled}
            onClick={onClick}
            hasIcon={!!Icon || !!FontAwesomeIconName}
            hasLabel={!!label}
            width={width}
            {...rest}
        >
            {!!Icon && <Icon onClick={(e) => e.stopPropagation()} />}
            {!!FontAwesomeIconName && (
                <FontAwesomeIcon icon={FontAwesomeIconName} size={20} />
            )}
            {label}
        </Button>
    );
};

export default CommonButton;

const getBackgroundColor = (variant, isDisabled) => {
    if (isDisabled) return variant === 'filled' ? '#DCDCDC' : '#FFF';
    switch (variant) {
        case 'filled':
            return '#CD2118';
        case 'text':
            return '#FFF';
        case 'black&white':
            return '#FFF';
        default:
            return 'transparent';
    }
};

const getBackgroundColorHover = (variant, isDisabled) => {
    if (isDisabled) return variant === 'filled' ? '#DCDCDC' : '#FFF';
    switch (variant) {
        case 'filled':
            return '#B31D15';
        case 'outlined':
            return '#B31D15';
        case 'text':
            return '#F9F9F9';
        case 'black&white':
            return '#FFF';
        default:
            return 'transparent';
    }
};

const getColor = (variant, isDisabled) => {
    if (isDisabled) return variant === 'filled' ? '#FFF' : '#DCDCDC';
    switch (variant) {
        case 'filled':
            return '#FFF';
        case 'outlined':
            return '#B31D15';
        case 'text':
            return '#B31D15';
        case 'black&white':
            return '#222';
        default:
            return '#B31D15';
    }
};

const getColorHover = (variant) => {
    switch (variant) {
        case 'filled':
            return '#FFF';
        case 'outlined':
            return '#FFF';
        case 'text':
            return '#B31D15';
        case 'black&white':
            return '#222';
        default:
            return '#B31D15';
    }
};

const getBorder = (variant) => {
    switch (variant) {
        case 'outlined':
            return '1px solid #B31D15';
        case 'black&white':
            return '1px solid #1D1D18';
        default:
            return 'none';
    }
};

const Button = styled.button`
    cursor: pointer;
    width: ${({ width }) => width};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s;
    border-radius: 100px;
    gap: ${({ hasIcon }) => hasIcon && '8px'};
    padding: ${({ hasIcon }) =>
        hasIcon ? '12px 24px 12px 16px' : '12px 24px'};
    background-color: ${({ variant, disabled }) =>
        getBackgroundColor(variant, disabled)};
    color: ${({ variant, disabled }) => getColor(variant, disabled)};
    border: ${({ variant }) => getBorder(variant)};

    &:hover {
        background-color: ${({ variant, disabled }) =>
            getBackgroundColorHover(variant, disabled)};
        color: ${({ variant }) => getColorHover(variant)};
        box-shadow:
            0px 4px 4px -2px #18274b14,
            0px 2px 4px -2px #18274b1f;
    }

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
        border: ${({ variant }) =>
            variant === 'outlined' ? '1px solid #DCDCDC' : 'none'};
    }

    svg {
        pointer-events: none;
    }
`;
