import styled from 'styled-components';
import MockupStepOne from '../assets/mockup-step-1.png';

const BodyStepOne = () => {
    return (
        <BodyContainer>
            <img src={MockupStepOne} alt="Mockup Step One" />
            <Info>
                <h2>Inicio de sesión</h2>
                <p>
                    <b>1. Podrás abrir la aplicación desde tu móvil.</b>
                </p>
                <p>
                    <b>
                        2. Ingresar los datos de correo electrónico y
                        contraseña.
                    </b>{' '}
                    Recuerda que estos datos deben ser los mismos con los que
                    ingresas en la plataforma Acrópolis.
                </p>
                <p>
                    <b>3.</b> Finalmente, selecciona el botón{' '}
                    <b>"Iniciar sesión"</b> para comenzar a cursar.
                </p>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepOne;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    img {
        margin-left: -2rem;
        height: 90%;
    }
`;

const Info = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 3rem;
    z-index: 2;
    color: #222;

    h2 {
        color: #b31d15;
    }
`;
