import styled from 'styled-components';
import MockupStepTwo from '../assets/mockup-step-2.png';

const BodyStepTwo = () => {
    return (
        <BodyContainer>
            <img src={MockupStepTwo} alt="Mockup Step Two" />
            <Info>
                <span>
                    <h2>Mi progreso</h2>
                    <p>
                        En este espacio podras <b>retomar tu cursado</b> desde
                        el <b>último recurso</b> que viste.
                    </p>
                </span>
                <span>
                    <h2>Inscripción a eventos</h2>
                    <p>
                        En este espacio visualizarás{' '}
                        <b>
                            información sobre los próximos eventos presenciales,
                            online y workshop
                        </b>
                        , con la posibilidad de <b>inscribirte</b> a ellos.
                    </p>
                </span>
                <span>
                    <h2>Mis referidos</h2>
                    <p>
                        Al hacer click serás redirigido a la{' '}
                        <b>landing de referidos en la web.</b>
                    </p>
                </span>
                <span>
                    <h2>Menú principal</h2>
                    <p>
                        Desde acá, podrás{' '}
                        <b>
                            explorar las distintas secciones de la aplicación.
                        </b>
                    </p>
                </span>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepTwo;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    img {
        margin-top: -1rem;
        margin-left: -2rem;
        height: 90%;
    }
`;

const Info = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    z-index: 2;
    color: #222;

    h2 {
        color: #b31d15;
    }

    span {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
