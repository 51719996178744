import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ProgramCard from './programs/ProgramCard';
import ProgramsSkt from '../../../components/skeletons/ProgramsSkt';
import { Card } from '@mui/material';
import ProgramFilter from '../../../components/ui/organisms/profile/ProgramFilter';
import NoResultsFound from '../../../components/common/NoResultsFound';
import MajorProgramAccordion from '../components/accordions/MajorProgramAccordion';
import NoProgramsFound from './NoProgramsFound';

const Programs = (props) => {
    const { report, programs } = props;

    // REDUX
    const loading = useSelector((state) => state.programs.listLoading);

    // STATES
    const [values, setValues] = useState(programs);
    const [currentState, setCurrentState] = useState('Maestrías');

    function normalizeString(str) {
        if (typeof str !== 'string') {
            return str;
        }

        if (typeof str.normalize !== 'function') {
            return str;
        }

        return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
    }

    // Este useEffect se usa para filtrar los programas según el tipo de programa que es
    useEffect(() => {
        if (programs !== null) {
            const typeProgramFilters = {
                Maestrías: ['Maestría', 'MBA'],
                Major: 'Major',
                Especializaciones: [
                    'Especialización',
                    'Especializacion',
                    'Especialización Online',
                ],
                Diplomados: 'Diplomado',
            };

            if (typeProgramFilters[currentState]) {
                const filterTypes = typeProgramFilters[currentState];

                const filteredPrograms = programs.filter((value) => {
                    const programType = value.type_program
                        ? value.type_program
                        : value.segment_program;
                    if (Array.isArray(filterTypes)) {
                        return filterTypes.some(
                            (filterType) =>
                                normalizeString(programType) ===
                                normalizeString(filterType)
                        );
                    }
                    return (
                        normalizeString(programType) ===
                        normalizeString(filterTypes)
                    );
                });

                setValues(filteredPrograms);
            }
        }
    }, [currentState, programs]);

    // Este useEffect se utiliza para posicionar la tab de izquierda a derecha donde primero encuentre una tab que no tenga info vacía
    useEffect(() => {
        const programPriority = {
            maestria: 1,
            mba: 2,
            major: 3,
            especializacion: 4,
            diplomado: 5,
        };

        const programTypesMap = {
            maestria: 'Maestrías',
            mba: 'Maestrías',
            major: 'Major',
            especializacion: 'Especializaciones',
            diplomado: 'Diplomados',
        };

        let highestPriority = Infinity;
        let currentState = null;

        if (programs) {
            programs.forEach((program) => {
                const normalizedType = normalizeString(program?.type_program);
                if (programPriority[normalizedType] < highestPriority) {
                    highestPriority = programPriority[normalizedType];
                    currentState = normalizedType;
                }
            });
        }

        if (currentState) {
            const stateForHandle = programTypesMap[currentState];
            handleCurrentState(stateForHandle);
            return;
        }
        handleCurrentState('Maestrías');
    }, [programs]);

    // FUNCTION
    function handleCurrentState(newValue) {
        setCurrentState(newValue);
    }

    // RETURN
    if (programs === null || loading) {
        return <ProgramsSkt />;
    }

    return (
        <Wrapper>
            <ProgramFilter
                currentState={currentState}
                handleCurrentState={handleCurrentState}
            />
            {/* TARJETAS DE PROGRAMAS */}
            <ProgramsContainer values={values?.length} report={report}>
                {values?.length === 0 || values === null ? (
                    <NoResultWrapper>
                        <NoProgramsFound
                            message={`¡Ups! No encontramos ${
                                currentState === 'Maestrías'
                                    ? 'ninguna maestría'
                                    : currentState === 'Major'
                                      ? 'ningún major'
                                      : currentState === 'Especializaciones'
                                        ? 'ninguna especialización'
                                        : 'ningún diplomado'
                            }`}
                        />
                    </NoResultWrapper>
                ) : values.length === 1 &&
                  values[0].has_study_plan === false ? (
                    <NoResultWrapper>
                        <NoResultsFound />
                    </NoResultWrapper>
                ) : (
                    values.map((program, index) => {
                        if (
                            program?.type_program === 'Maestría' ||
                            program?.type_program === 'MBA'
                        ) {
                            return (
                                <MajorProgramAccordion
                                    program={program}
                                    key={index}
                                />
                            );
                        }
                        return (
                            <ProgramCard
                                program={program}
                                key={index}
                                report={report}
                            />
                        );
                    })
                )}
            </ProgramsContainer>
        </Wrapper>
    );
};

export default Programs;

const Wrapper = styled(Card)`
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    background-color: #f9f9f9;
    box-shadow: none !important;

    &.jDuXnR {
        overflow: none !important;
    }
`;

const ProgramsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 2rem;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        gap: 4.5rem;
    }
`;

const NoResultWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;
`;
