import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ShareIcon from '@mui/icons-material/Share';
import useFetch from '../../../../hooks/useFetch';
import { updateComments } from './api';
import SimpleLoading from '../../../common/SimpleLoading';
import { commentsTaskAdapter } from './adapter/commentsTask.adapter';
import SharedResource from '../../../common/SharedResource';
import TaskRender from '../../../../pages/speedgrader/components/TaskRender';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    borderRadius: 7,
    boxShadow: 24,
};

const ModalTaskRender = (props) => {
    const { open, handleClose, values } = props;
    const shareEnabled = values?.resource?.allow_share;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const { partner_id, name } = useSelector((state) => state.auth.user);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const { data, loading, isError } = useFetch({
        asyncFn: () => updateComments(values?.id),
        adapter: commentsTaskAdapter,
    });

    const [comments, setComments] = useState(null);

    useEffect(() => {
        !!data && setComments(data?.comments);

        return () => { };
    }, [data]);

    const sharedItem = {
        lp_type: 'task',
        type: 'task',
        id: values.id,
        nombre_publicacion: values.activity.name,
    };

    const comportamientoInfo = {
        partner_id: partner_id,
        partner_name: name,
        subject_id: values.assignment.id,
        subject_name: values.assignment.name,
        OA_id: values.oa.id,
        OA_name: values.oa.name,
        resource_id: values.id,
        resource_name: values.activity.name,
    };

    return (
        <>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CustomBox sx={style}>
                    <Header>
                        <p>{values.activity.name}</p>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Header>
                    <Body>
                        <TaskRenderContainer>
                            <TaskRender
                                isText={!!values.response}
                                isFile={!!values.file}
                                name={values?.resource?.name}
                                text={values.response}
                                file={values.file || null}
                            />
                        </TaskRenderContainer>
                        <AsideComments>
                            <AsideTitle>
                                Comentarios sobre este proyecto:
                            </AsideTitle>
                            <CommentsWrapper>
                                {loading || comments === null ? (
                                    <SimpleLoading />
                                ) : isError || comments?.length === 0 ? (
                                    <p>No se encontraron comentarios</p>
                                ) : (
                                    <>
                                        {comments.map((comment) => (
                                            <CardComment>
                                                <Name>
                                                    {comment?.commentator}
                                                </Name>
                                                <Comment>
                                                    {comment?.text}
                                                </Comment>
                                                <FooterDiv>
                                                    <Date>
                                                        {comment?.create_date}
                                                    </Date>
                                                    {comment?.public_likes > 0 && (
                                                        <SocialDiv>
                                                            <FavoriteBorderOutlinedIcon />
                                                            <Count>
                                                                {comment?.public_likes ?? 0}
                                                            </Count>
                                                        </SocialDiv>
                                                    )}
                                                </FooterDiv>
                                            </CardComment>
                                        ))}
                                    </>
                                )}
                            </CommentsWrapper>
                            {shareEnabled && (
                                <SharedWrapper>
                                    <SharedButton
                                        onClick={() => {
                                            setModalIsOpen(true);
                                        }}
                                        // Event action name
                                        data-behaviour-action={"bco_proy_compartir_proyecto"}
                                        // Event detail
                                        data-behaviour-detail={JSON.stringify(comportamientoInfo)}
                                    >
                                        <ShareIcon />
                                        Compartir proyecto
                                    </SharedButton>
                                </SharedWrapper>
                            )}
                        </AsideComments>
                    </Body>
                </CustomBox>
            </Modal>
        </>
    );
};

export default ModalTaskRender;

const CustomBox = styled(Box)`
    height: 90vh;
    overflow: hidden;
    background-color: #fff;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b31d15;
    color: #fff;
    height: 50px;
    position: relative;
    font-size: 1rem;
    font-weight: 600;
`;

const IconButton = styled.button`
    cursor: pointer;
    width: fit-content;
    border: none;
    position: absolute;
    right: 30px;
    top: 30%;

    svg {
        color: #fff;
        font-size: 1.5rem;
    }
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const TaskRenderContainer = styled.div`
    height: 100%;
    flex-basis: 70%;
`;

const AsideComments = styled.div`
    flex-basis: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
`;

const AsideTitle = styled.p`
    font-size: 1.1rem;
    color: #b31d15;
    line-height: 28px;
    height: 5%;
    font-weight: 600;
    text-align: center;
`;

const CommentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    align-items: center;
    height: 75%;
    overflow-y: auto;
`;

const CardComment = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    justify-content: flex-start;
    border: solid 1px #c4c4c4;
    border-radius: 20px;
    gap: 1rem;
`;

const Name = styled.p`
    color: #616161;
    font-size: 0.9rem;
    font-weight: 600;
`;

const Comment = styled.p`
    font-size: 1rem;
    color: #222222;
    text-align: start;
`;

const Date = styled.span`
    color: #616161;
    font-size: 0.8rem;
    font-weight: 600;
`;

const SharedWrapper = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
`;

const SharedButton = styled.button`
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #b31d15;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    box-sizing: border-box;
    height: fit-content;
`;

const SocialDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0 1rem;
    align-items: center;
    svg {
        color: #b31d15;
    }
`;

const Count = styled.p`
    font-size: 1rem;
`;

const FooterDiv = styled.div`
    display: fle;
    flex-direction: row;
    justify-content: space-between;
`;
