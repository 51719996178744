import React from 'react';
import { useHistory } from 'react-router-dom';

const Index = () => {
    const history = useHistory();

    // Se redirige al login, por ende nunca se va a ver la landing
    history.push('/auth');

    return <div style={{ backgroundColor: '#ffffff' }}></div>;
};

export default Index;
