import styled from 'styled-components';
import { Chip } from '@mui/material';

export const CustomChip = styled(Chip)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    padding: 12px 24px 12px 12px;
    height: 44px;
    border-radius: 50px;
    width: ${({ width }) => !!width && width};
    border: 1px solid ${({ svgColor }) => !!svgColor && svgColor};

    &.MuiChip-root {
        background-color: ${({ bgColor }) => !!bgColor && bgColor} !important;
    }

    svg {
        color: ${({ svgColor }) => !!svgColor && svgColor};
    }
`;
