export const userAuthAdapter = (user) => {
    return {
        academic_training:
            user?.studies?.academic_training ?? user?.job?.experience, //
        biography: user?.personal?.biography, //
        birthday: user?.basic?.birthday, //
        city: user?.personal?.city,
        company: user?.job?.company, //
        contacto_email_alumni: '',
        country: user?.basic?.country, //
        current_job: user?.job?.current_job, //
        dracma_account: {
            balance: 0,
            crm_id: 0,
            total_earned: 0,
            total_spent: 0,
        },
        fa_id: user?.personal?.fa_id,
        facebook: '',
        foto: user?.basic?.image_small, //
        gender: user?.basic?.gender, //
        instagram: '',
        intereses: user?.interests, //
        is_student: user?.basic?.is_student, //
        job: user?.job?.current_job?.name,
        job_area: user?.job?.area,
        linkedin: '',
        mobile: '',
        motivaciones: user?.motivations, //
        name: user?.basic?.name, //
        nationality: user?.basic?.nationality, //
        od_participant_id: {
            estado: 'pendiente',
            id: 0,
        },
        ondemand_id: 0,
        partner_id: user?.personal?.crm_partner_id, //
        personal_email: user?.basic?.email, //
        phone: user?.personak?.phone, //
        profession: {
            id: 0,
            name: '',
        },
        profile_is_public: user?.basic?.public_profile,
        repo_id: user?.personal?.repo_id, //
        repositorio_data: {
            professor_sis_id: user?.basic?.professor_sis_id,
        }, //
        roles: user?.roles, //
        sis_id: user?.personal?.sis_id, //
        skype: '',
        street: user?.personal?.street, //
        twitter: '',
        whatsapp: '',
        work_email: user?.basic?.work_email, //
        work_experience: user?.job?.experience, //
        work_phone: '',
        zip: '',
    };
};
