import { CustomLinearProgress } from '../../../../styled-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';
import LazyImg from '../../../../components/common/LazyImg';
import { Text } from '../../../../components/common/Texts';

// Material UI
import { Button } from '@mui/material';

// Assets
import noResource from '../../../../assets/media/campus/no-last_resource_found.png';
import tutorial from '../../../../assets/media/aden/tutorial.png';

// Utils
import { base64Encode } from '../../../../utils/encrypt';

const LastResourceSeen = ({
    lastOpenedOAData,
    subjectId,
    resourceId,
    programId,
}) => {
    const { name, image, lastOpenedResource, totalProgress, id } =
        lastOpenedOAData;

    const history = useHistory();
    const courseHash = base64Encode(id);
    const subjectHash = base64Encode(subjectId);
    const resourceHash = base64Encode(resourceId);
    const programHash = base64Encode(programId);

    const handleRedirectCourse = () => {
        history.push(
            `/curso/${courseHash}?id=${subjectHash}&activity=${resourceHash}`
        );
    };

    const handleRedirectSubject = () => {
        history.push(`/asignatura/${subjectHash}`);
    };

    const handleRedirectProgram = () => {
        !programId
            ? history.push(`/mi-progreso`)
            : history.push(`/mi-progreso/${programHash}`);
    };

    const isDefaultImage = image?.includes('4328');

    const validatedImage = isDefaultImage ? tutorial : image;

    if (typeof id === 'string') {
        return (
            <Wrapper>
                <NoResourceWrapper>
                    <Text color="#222222" fontSize="14px" fontWeight="700">
                        Cuando comiences con tu cursado,{' '}
                        <b>podrás continuarlo desde aquí.</b>
                    </Text>
                    <LazyImg
                        src={noResource}
                        backgroundColor="#ffffff"
                        width="fit-content"
                        height="160px"
                    />
                </NoResourceWrapper>
            </Wrapper>
        );
    }

    return (
        <Container>
            <Wrapper>
                <Header>
                    <RedirectButton
                        onClick={handleRedirectCourse}
                        variant="text"
                        color="primary"
                    >
                        Seguir cursando
                    </RedirectButton>
                    <LazyImg
                        src={validatedImage}
                        height="100%"
                        width="100%"
                        borderRadius="20px 20px 0 0"
                        isDefault={image?.includes('4328')}
                    />
                </Header>
                <Body>
                    <CustomLinearProgress
                        variant="determinate"
                        value={totalProgress}
                    />
                    <CurrentResourceWrapper>
                        <FontAwesomeIcon icon="fa-regular fa-video" size={14} />
                        <Text color="#222222" fontSize="13px" fontWeight="400">
                            {lastOpenedResource.publicationName}
                        </Text>
                    </CurrentResourceWrapper>
                    <NameWrapper>
                        <Text color="#616161" fontSize="12px" fontWeight="700">
                            <span>MÓDULO: </span> {name.toUpperCase()}
                        </Text>
                    </NameWrapper>
                </Body>
            </Wrapper>
            <ButtonsWrapper>
                <CommonButton
                    label="Ir a la asignatura"
                    onClick={handleRedirectSubject}
                    variant="filled"
                    width="50%"
                />
                <CommonButton
                    label="Ir al programa"
                    onClick={handleRedirectProgram}
                    variant="filled"
                    width="50%"
                />
            </ButtonsWrapper>
        </Container>
    );
};

export default LastResourceSeen;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow:
        0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);

    @media (max-width: 1440px) {
        width: 100%;
        height: 305px;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

const Header = styled.section`
    position: relative;
    width: 100%;
    height: 158px;

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 158px;
        margin: auto;
        top: 0;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 20px 20px 0 0;
        transition: all 0.2s ease-in;
    }

    &:hover::after {
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.2s ease-in;
    }

    svg {
        background-color: #ffffff;
    }
`;

const Body = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 137px;
    padding: 1rem 1rem 0 1rem;
`;

const CurrentResourceWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        min-width: 16px;
        min-height: 16px;
        padding: 8px;
        border-radius: 50%;
        color: #ffffff;
        background: #000000;
    }
`;

const NameWrapper = styled.div`
    display: flex;
    justify-content: flex-start;

    p {
        margin-left: 0;
    }

    span {
        font-weight: 400;
    }
`;

const RedirectButton = styled(Button)`
    position: absolute;
    box-shadow: none;
    border-radius: 50px;
    z-index: 10;
    background-color: #ffffff;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;
    width: 160px;

    &:hover {
        background-color: #ffffff;
    }
`;

const NoResourceWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    p {
        padding: 0 1.5rem;
        line-height: 20px;
    }

    b {
        font-weight: 900;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    button {
        font-size: 14px;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 50px;
        padding: 12px 0;
        white-space: nowrap;
    }
`;
