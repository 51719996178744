import styled from 'styled-components';
import MockupStepFive from '../assets/mockup-step-5.png';

const BodyStepFive = () => {
    return (
        <BodyContainer>
            <img src={MockupStepFive} alt="Mockup Step Five" />
            <Info>
                <span>
                    <h2>Perfil</h2>
                    <p>
                        En esta sección podrás cerrar sesión, gestionar los
                        códigos QR como registro de asistencia para los eventos
                        presenciales, administrar tus referidos, configuración,
                        entre otras funciones.
                    </p>
                </span>
            </Info>
        </BodyContainer>
    );
};

export default BodyStepFive;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    height: 100%;

    img {
        margin-top: -1rem;
        height: 90%;
    }
`;

const Info = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    z-index: 2;
    color: #222;

    h2 {
        color: #b31d15;
    }

    span {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
