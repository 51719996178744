import styled from 'styled-components';

// Components
import ChallengeQuestions from './components/modals/ChallengeQuestions';
import CompleteProfile from './components/modals/CompleteProfile';
import DialogInfo from '../../modules/newInformation/DialogInfo';
import MainCampus from './components/fastAccess/MainCampus';

// Helpers
import { titleHTML } from '../../helpers/title.helper';

// Assets
import campusOrnament from '../../assets/media/campus-ornament-2.png';

const Campus = () => {
    titleHTML('Campus');

    return (
        <>
            {/* <CompleteProfile /> */}
            <ChallengeQuestions />
            <DialogInfo />
            <HomeWrapper>
                <Image src={campusOrnament} />
                <MainCampus />
                {/* <AsideCampus /> */}
            </HomeWrapper>
        </>
    );
};

export default Campus;

const HomeWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: grid;
    /* grid-template-columns: 3.5fr 1fr; */
    gap: 15px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Image = styled.img`
    position: absolute;
    margin: auto;
    top: -31px;
    right: -32px;
    /* left: -30px; */
    width: 850px;
    /* width: calc(100% + 62px); */
    height: 600px;
    object-fit: center;
    object-position: center;
    mask-image: linear-gradient(#f9f9f9 80%);
    z-index: 0;

    @media (max-width: 768px) {
        right: -13px;
    }
`;
