import { useState } from 'react';
import useFetch from '../useFetch';
import { getAnnouncementsByRepoId } from '../../redux/api/announcements';
import { useEffect } from 'react';
import { announcementsGenearalAdapter } from '../../components/ui/organisms/subject/announcements/adapter/announcementsAdapter';

const useGeneralSubjectAnnouncements = (repo_id, rol) => {
    const [announcementsGeneralData, setAnnouncementsGeneralData] = useState(
        []
    );

    //paginación
    const [page, setPage] = useState(1);
    const pageMax = Math.ceil(announcementsGeneralData.length / 4);

    //Lista Inicial de anuncios generales
    const { data, loading, error, isError, refetch } = useFetch({
        asyncFn: () => getAnnouncementsByRepoId(repo_id),
        adapter: announcementsGenearalAdapter,
    });

    useEffect(() => {
        if (!!data) {
            const flattenedData = mapNewsletters(data.restructuredList);

            const todayDate = new Date();
            const toStudentData = flattenedData.filter(
                (item) => new Date(item?.publishedDate) <= todayDate
            );

            if (rol !== 'docente') {
                setAnnouncementsGeneralData(toStudentData);
                return;
            }

            setAnnouncementsGeneralData(flattenedData);
        }
    }, [data]);

    const mapNewsletters = (newslettersData) => {
        return newslettersData?.flatMap((item) => {
            return item?.newsletters?.length !== 0
                ? item?.newsletters?.map((newsletter) => ({
                      publishedDate: newsletter?.publishedDate,
                      subjectName: item?.subjectName,
                      general: true,
                      card: newsletter,
                      key: newsletter?.id,
                  }))
                : [];
        });
    };

    const resetGeneralData = () => {
        setAnnouncementsGeneralData([]);
        refetch();
    };

    return {
        announcementsGeneralData,
        setAnnouncementsGeneralData,
        loading,
        error,
        page,
        setPage,
        pageMax,
        resetGeneralData,
    };
};

export default useGeneralSubjectAnnouncements;
